import axios, { AxiosRequestConfig } from 'axios';
import { translate } from 'components/Utility/i18n';
import dayjs from 'dayjs';
import {
  FETCH_TIME_LIMIT,
  FileUploadProps,
  HiddenFiles,
  ID,
  PREVIEW_MAIL_STATUS,
  Quorum,
  STRING_TOOLS,
  USER_SOURCE,
  VoteCount,
} from 'definitions/constants-fe';
import {
  TObjectWithPersonData,
  TPropertyWithObjectData,
} from 'definitions/types-fe';
import {
  and,
  collection,
  getDocs,
  onSnapshot,
  or,
  query,
  where,
} from 'firebase/firestore';
import jwtDecode from 'jwt-decode';
import Firebase from 'library/firebase';
import {
  extractDocumentFolderPattern,
  getMandateCodeOrFirstNumberInString,
  handleTimeStamps,
  showMessage,
} from 'library/functionHelper';
import { getToken } from 'library/localStorageHelper';
import QueryHelper from 'library/queryHelper';
import { Reporting } from 'library/sentry/reporting';
import { predictableIdAndTodayCode } from 'library/snippets/security';
import _ from 'lodash';
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import actionsApp from 'redux/global/app/actions';
import { RootState } from 'redux/root-saga';
import { CPerson, CVoteItem } from '../../../../shared/classes';
import {
  ACCOUNT_ROLE,
  CONTROL_FUNCTIONS,
  DB,
  DEFAULT_LANGUAGE_ID,
  ERROR_CODE,
  EXTERNAL_FORM_MANDATE_ID,
  FIRESTORE_ARRAY_CONTAINS_ANY_LIMIT,
  FOLDER_PATTERN,
  REMOTE_CONFIG,
  REPAIR_STATUS,
  SECRETS,
  SPACE_FUNCTIONS,
  STORAGE,
  URLS,
  VOTE_STEPS,
  VOTEITEM_LIVE_STATUS,
} from '../../../../shared/constants';
import { IComment, IRepairTimeline } from '../../../../shared/types';
import { store } from '../store';
import actions from './actions';

const {
  database,
  storageRef,
  rsfFirestore,
  refFireFunction,
  getAuthToken,
  snapshotAsArray,
  snapshotAsObject,
} = Firebase;

const { queryHelp, queryFirst } = QueryHelper;

const xYearsAgo = dayjs(new Date())
  .subtract(FETCH_TIME_LIMIT, 'months')
  .toDate();

/*
 * Selector. The query depends by the state shape
 */
export const getUserAccount = (state: RootState) =>
  state.GL_Auth.rx_userAccount;

/*
 * Selector. The query depends by the state shape
 */
export const getUsers = (state: RootState) => state.ImmoApp.rx_users;

// TODO: Settings should be global
// REALTIME
let listenerCommonSettings: any = null;
function* subscribeCommonSettings() {
  try {
    // This makes sure that the database listener is only active once
    if (listenerCommonSettings?.isRunning() === true) {
      return;
    }
    yield put(actions.subscribeCommonSettings.request());
    // @ts-ignore
    listenerCommonSettings = yield fork(
      rsfFirestore.syncCollection,
      database.collection(DB.settings).doc(DB.doc_common_settings),
      {
        successActionCreator: actions.subscribeCommonSettings.success,
        failureActionCreator: actions.subscribeCommonSettings.failure,
        transform: (payload) => payload.data(),
      },
    );
    // If logout is called, the code continues to execute
    yield take(actions.cancelCommonSettings.TRIGGER);
    // And we cancel the database listener
    yield cancel(listenerCommonSettings);
    listenerCommonSettings = null;
  } catch (error) {
    Reporting.Error(error);
    listenerCommonSettings = null;
    yield put(
      actions.subscribeCommonSettings.failure('global.generalErrorMessage'),
    );
  } finally {
  }
}

// REALTIME
let listenerUsers: any = null;
function* subscribeUsers() {
  try {
    // @ts-ignore
    const rx_userAccount = yield select(getUserAccount);
    if (rx_userAccount == null) {
      return;
    }

    // This makes sure that the database listener is only active once
    if (listenerUsers != null) {
      return;
    }
    yield put(actions.subscribeUsers.request());

    const mandate_active = Object.keys(rx_userAccount.mandate_active ?? {}).map(
      (k) => parseInt(k),
    );
    if (mandate_active.length === 0) {
      // This might happen if a user only has deactivated mandates
      // Add a filler so the query doesn't fail
      mandate_active.push(0);
    }

    const queryConstraints = [];
    // Only user who are not manager/admin and have less than 10 active mandates (to keep it simple), limit fetched mandates.
    if (
      rx_userAccount.role < ACCOUNT_ROLE.manager.value &&
      mandate_active.length <= FIRESTORE_ARRAY_CONTAINS_ANY_LIMIT
    ) {
      queryConstraints.push(
        or(
          where(ID.mandate_ids, 'array-contains-any', mandate_active),
          where(ID.email, '==', rx_userAccount.email),
        ),
      );
    }

    // @ts-ignore
    const qry = query(collection(database, DB.ia_users), ...queryConstraints);
    listenerUsers = onSnapshot(
      qry,
      (querySnapshot) => {
        // @ts-ignore
        const res = snapshotAsArray(querySnapshot, true)
          .map((ele) => ({
            ...ele,
            uid: ele.id,
          }))
          .sort((a, b) => {
            // First sort by role
            // @ts-ignore
            if (a.role > b.role) {
              return -1;
            }
            // @ts-ignore
            if (a.role < b.role) {
              return 1;
            }
            // Second sort by last name
            // @ts-ignore
            return a.last_name.localeCompare(
              // @ts-ignore
              b.last_name,
              process.env.REACT_APP_PLATFORM_LANG || DEFAULT_LANGUAGE_ID,
              { sensitivity: 'base' },
            );
          });
        store.dispatch(actions.subscribeUsers.success(res));
      },
      (error) => {
        Reporting.Error(error);
        store.dispatch(actions.subscribeUsers.failure());
      },
    );

    // If logout is called, the code continues to execute
    yield take(actions.cancelUsers.TRIGGER);

    // And we cancel the database listener
    yield listenerUsers();

    listenerUsers = null;
  } catch (error) {
    Reporting.Error(error);
    listenerUsers = null;
    yield put(actions.subscribeUsers.failure('global.generalErrorMessage'));
  } finally {
  }
}

// REALTIME
let listenerStaff: any = null;
function* subscribeStaff() {
  try {
    // This makes sure that the database listener is only active once
    if (listenerStaff?.isRunning() === true) {
      return;
    }
    yield put(actions.subscribeStaff.request());
    // @ts-ignore
    listenerStaff = yield fork(
      rsfFirestore.syncCollection,
      database
        .collection(DB.ia_users)
        .where(ID.role, '>', ACCOUNT_ROLE.owner.value),
      {
        successActionCreator: actions.subscribeStaff.success,
        failureActionCreator: actions.subscribeStaff.failure,
        transform: (payload) => {
          const res = snapshotAsArray(payload, true)
            .map((ele) => ({
              ...ele,
              uid: ele.id,
            }))
            .sort((a, b) => {
              // First sort by role
              // @ts-ignore
              if (a.role > b.role) {
                return -1;
              }
              // @ts-ignore
              if (a.role < b.role) {
                return 1;
              }
              // Second sort by last name
              // @ts-ignore
              return a.last_name.localeCompare(
                // @ts-ignore
                b.last_name,
                process.env.REACT_APP_PLATFORM_LANG || DEFAULT_LANGUAGE_ID,
                { sensitivity: 'base' },
              );
            });
          return res;
        },
      },
    );
    // If logout is called, the code continues to execute
    yield take(actions.cancelStaff.TRIGGER);
    // And we cancel the database listener
    yield cancel(listenerStaff);
    listenerStaff = null;
  } catch (error) {
    Reporting.Error(error);
    listenerStaff = null;
    yield put(actions.subscribeStaff.failure('global.generalErrorMessage'));
  } finally {
  }
}

// REALTIME
let listenerMandates: any = null;
function* subscribeMandates() {
  try {
    // @ts-ignore
    const rx_userAccount = yield select(getUserAccount);
    if (rx_userAccount == null) {
      return;
    }

    // This makes sure that the database listener is only active once
    if (listenerMandates?.isRunning() === true) {
      return;
    }
    yield put(actions.subscribeMandates.request());

    const mandate_active = Object.keys(rx_userAccount.mandate_active ?? {}).map(
      (k) => parseInt(k),
    );
    if (mandate_active.length === 0) {
      // This might happen if a user only has deactivated mandates
      // Add a filler so the query doesn't fail
      mandate_active.push(0);
    }

    let query = database.collection(DB.ia_mandates);
    // Only user who are not manager/admin and have less than 10 active mandates (to keep it simple), limit fetched mandates.
    if (
      rx_userAccount.role < ACCOUNT_ROLE.manager.value &&
      mandate_active.length <= FIRESTORE_ARRAY_CONTAINS_ANY_LIMIT
    ) {
      // @ts-ignore
      query = query.where(ID.mandate_id, 'in', mandate_active);
    }
    // @ts-ignore
    query = query.orderBy(ID.mandate_id, 'asc');

    // @ts-ignore
    listenerMandates = yield fork(rsfFirestore.syncCollection, query, {
      successActionCreator: actions.subscribeMandates.success,
      failureActionCreator: actions.subscribeMandates.failure,
      transform: (payload) => snapshotAsArray(payload, true),
    });
    // If cancel is called, the code continues to execute
    yield take(actions.cancelMandates.TRIGGER);
    // And we cancel the database listener
    yield cancel(listenerMandates);
    listenerMandates = null;
  } catch (error) {
    Reporting.Error(error);
    listenerMandates = null;
    yield put(actions.subscribeMandates.failure('global.generalErrorMessage'));
  } finally {
  }
}

// REALTIME
let listenerProperties: any = null;
function* subscribeProperties() {
  try {
    // This makes sure that the database listener is only active once
    if (listenerProperties?.isRunning() === true) {
      return;
    }
    yield put(actions.subscribeProperties.request());
    // @ts-ignore
    listenerProperties = yield fork(
      rsfFirestore.syncCollection,
      DB.ia_properties,
      {
        successActionCreator: actions.subscribeProperties.success,
        failureActionCreator: actions.subscribeProperties.failure,
        transform: (payload) => snapshotAsArray(payload, true),
      },
    );
    // If cancel is called, the code continues to execute
    yield take(actions.cancelProperties.TRIGGER);
    // And we cancel the database listener
    yield cancel(listenerProperties);
    listenerProperties = null;
  } catch (error) {
    Reporting.Error(error);
    listenerProperties = null;
    yield put(
      actions.subscribeProperties.failure('global.generalErrorMessage'),
    );
  } finally {
  }
}

// REALTIME
let listenerMessages: any = [];
function* subscribeMessages({ payload }: any) {
  const { timeLimit } = payload;
  try {
    // @ts-ignore
    const rx_userAccount = yield select(getUserAccount);
    if (rx_userAccount == null) {
      return;
    }
    // This makes sure that the database listener is only active once
    if (listenerMessages.length > 0) {
      for (const listener of listenerMessages) {
        if (listener.isRunning() === true) {
          // If one is running, they should all be running
          return;
        }
      }
      // If one wasn't running, cancel all and continue to subscribe new
      for (const listener of listenerMessages) {
        yield cancel(listener);
      }
      listenerMessages = [];
    }
    yield put(actions.subscribeMessages.request());
    const mandate_active = Object.keys(rx_userAccount.mandate_active ?? {})
      .map((k) => parseInt(k))
      .concat([0]);

    if (
      mandate_active.length > FIRESTORE_ARRAY_CONTAINS_ANY_LIMIT ||
      rx_userAccount.role >= ACCOUNT_ROLE.admin.value
    ) {
      // If user has > X mandate_active or is an admin, fetch all data
      let query = database.collection(DB.ia_messages);
      if (timeLimit === true) {
        // @ts-ignore
        query = query.where(ID.date_creation, '>', xYearsAgo);
      }
      // @ts-ignore
      query = query.orderBy(ID.date_creation, 'desc'); // needed because of index
      listenerMessages.push(
        // @ts-ignore
        yield fork(rsfFirestore.syncCollection, query, {
          successActionCreator: actions.subscribeMessages.success,
          failureActionCreator: actions.subscribeMessages.failure,
          transform: (payload) => ({
            data: snapshotAsArray(payload, true).map((ele) => ({
              ...ele,
              dbCollection: DB.ia_messages,
            })),
            type: ID.public,
          }),
        }),
      );
    } else {
      // If user has <= X mandate_active, fetch only them
      let query1 = database.collection(DB.ia_messages);
      if (timeLimit === true) {
        // @ts-ignore
        query1 = query1.where(ID.date_creation, '>', xYearsAgo);
      }
      // @ts-ignore
      query1 = query1.orderBy(ID.date_creation, 'desc'); // needed because of index
      // @ts-ignore
      query1 = query1.where(
        ID.mandate_ids,
        'array-contains-any',
        mandate_active,
      );
      listenerMessages.push(
        // @ts-ignore
        yield fork(rsfFirestore.syncCollection, query1, {
          successActionCreator: actions.subscribeMessages.success,
          failureActionCreator: actions.subscribeMessages.failure,
          transform: (payload) => ({
            data: snapshotAsArray(payload, true).map((ele) => ({
              ...ele,
              dbCollection: DB.ia_messages,
            })),
            type: ID.public,
          }),
        }),
      );
      let query2 = database.collection(DB.ia_messages);
      if (rx_userAccount.role < ACCOUNT_ROLE.manager.value) {
        // @ts-ignore
        query2 = query2.where(ID.date_creation, '<', new Date());
        // @ts-ignore
        query2 = query2.orderBy(ID.date_creation, 'desc'); // needed because of index
      }
      // Note: we use user_involved_ids here, because staff members who send a message to a single user should see the message in the webapp
      // @ts-ignore
      query2 = query2.where(ID.user_involved_ids, 'array-contains-any', [
        rx_userAccount.id,
      ]);
      listenerMessages.push(
        // @ts-ignore
        yield fork(rsfFirestore.syncCollection, query2, {
          successActionCreator: actions.subscribeMessages.success,
          failureActionCreator: actions.subscribeMessages.failure,
          transform: (payload) => ({
            data: snapshotAsArray(payload, true),
            type: ID.private,
          }),
        }),
      );
    }
    // If cancel is called, the code continues to execute
    yield take(actions.cancelMessages.TRIGGER);
    // And we cancel the database listener
    for (const listener of listenerMessages) {
      yield cancel(listener);
    }
    listenerMessages = [];
  } catch (error) {
    Reporting.Error(error);
    for (const listener of listenerMessages) {
      yield cancel(listener);
    }
    listenerMessages = [];
    yield put(actions.subscribeMessages.failure('global.generalErrorMessage'));
  } finally {
  }
}

// REALTIME
let listenerRepairs: any = [];
function* subscribeRepairs({ payload }: any) {
  const { timeLimit } = payload;
  try {
    // @ts-ignore
    const rx_userAccount = yield select(getUserAccount);
    if (rx_userAccount == null) {
      return;
    }
    // This makes sure that the database listener is only active once
    if (listenerRepairs.length > 0) {
      for (const listener of listenerRepairs) {
        if (listener.isRunning() === true) {
          // If one is running, they should all be running
          return;
        }
      }
      // If one wasn't running, cancel all and continue to subscribe new
      for (const listener of listenerRepairs) {
        yield cancel(listener);
      }
      listenerRepairs = [];
    }
    yield put(actions.subscribeRepairs.request());
    const mandate_active = Object.keys(rx_userAccount.mandate_active ?? {}).map(
      (k) => parseInt(k),
    );

    // For manager and admins, add the external ticket mandate id
    if (rx_userAccount.role >= ACCOUNT_ROLE.manager.value) {
      mandate_active.push(EXTERNAL_FORM_MANDATE_ID);
    }

    if (mandate_active.length === 0) {
      // This might happen if a user only has deactivated mandates
      // Add a filler so the query doesn't fail
      mandate_active.push(0);
    }

    if (
      mandate_active.length > FIRESTORE_ARRAY_CONTAINS_ANY_LIMIT ||
      rx_userAccount.role >= ACCOUNT_ROLE.admin.value
    ) {
      // If user has > X mandate_active or is an admin, fetch all data
      let query = database.collection(DB.ia_repairs);
      if (timeLimit === true) {
        // @ts-ignore
        query = query.where(ID.date_creation, '>', xYearsAgo);
      }
      // @ts-ignore
      query = query.orderBy(ID.date_creation, 'desc'); // needed because of index
      listenerRepairs.push(
        // @ts-ignore
        yield fork(rsfFirestore.syncCollection, query, {
          successActionCreator: actions.subscribeRepairs.success,
          failureActionCreator: actions.subscribeRepairs.failure,
          transform: (payload) => ({
            data: snapshotAsArray(payload, true).map((ele) => ({
              ...ele,
              dbCollection: DB.ia_repairs,
            })),
            type: ID.public,
          }),
        }),
      );
    } else {
      // If user has <= X mandate_active, fetch only them
      let query = database.collection(DB.ia_repairs);
      if (timeLimit === true) {
        // @ts-ignore
        query = query.where(ID.date_creation, '>', xYearsAgo);
      }
      // @ts-ignore
      query = query.where(ID.mandate_ids, 'array-contains-any', mandate_active);
      // @ts-ignore
      query = query.orderBy(ID.date_creation, 'desc'); // needed because of index
      listenerRepairs.push(
        // @ts-ignore
        yield fork(rsfFirestore.syncCollection, query, {
          successActionCreator: actions.subscribeRepairs.success,
          failureActionCreator: actions.subscribeRepairs.failure,
          transform: (payload) => ({
            data: snapshotAsArray(payload, true).map((ele) => ({
              ...ele,
              dbCollection: DB.ia_repairs,
            })),
            type: ID.public,
          }),
        }),
      );
      listenerRepairs.push(
        // @ts-ignore
        yield fork(
          rsfFirestore.syncCollection,
          database
            .collection(DB.ia_repairs)
            .where(ID.user_receiver_ids, 'array-contains-any', [
              rx_userAccount.id,
            ]),
          {
            successActionCreator: actions.subscribeRepairs.success,
            failureActionCreator: actions.subscribeRepairs.failure,
            transform: (payload) => ({
              data: snapshotAsArray(payload, true).map((ele) => ({
                ...ele,
                dbCollection: DB.ia_repairs,
              })),
              type: ID.private,
            }),
          },
        ),
      );
    }
    // If cancel is called, the code continues to execute
    yield take(actions.cancelRepairs.TRIGGER);
    // And we cancel the database listener
    for (const listener of listenerRepairs) {
      yield cancel(listener);
    }
    listenerRepairs = [];
  } catch (error) {
    Reporting.Error(error);
    for (const listener of listenerRepairs) {
      yield cancel(listener);
    }
    listenerRepairs = [];
    yield put(actions.subscribeRepairs.failure('global.generalErrorMessage'));
  } finally {
  }
}

// REALTIME
let listenerPinboards: any = [];
function* subscribePinboards({ payload }: any) {
  const { timeLimit } = payload;
  try {
    // @ts-ignore
    const rx_userAccount = yield select(getUserAccount);
    if (rx_userAccount == null) {
      return;
    }
    // This makes sure that the database listener is only active once
    if (listenerPinboards.length > 0) {
      for (const listener of listenerPinboards) {
        if (listener.isRunning() === true) {
          // If one is running, they should all be running
          return;
        }
      }
      // If one wasn't running, cancel all and continue to subscribe new
      for (const listener of listenerPinboards) {
        yield cancel(listener);
      }
      listenerPinboards = [];
    }
    yield put(actions.subscribePinboards.request());
    const mandate_active = Object.keys(rx_userAccount.mandate_active ?? {}).map(
      (k) => parseInt(k),
    );
    if (mandate_active.length === 0) {
      // This might happen if a user only has deactivated mandates
      // Add a filler so the query doesn't fail
      mandate_active.push(0);
    }
    if (
      mandate_active.length > FIRESTORE_ARRAY_CONTAINS_ANY_LIMIT ||
      rx_userAccount.role >= ACCOUNT_ROLE.admin.value
    ) {
      // If user has > 10 mandate_active or is an admin, fetch all data
      let query = database.collection(DB.ia_pinboard);
      if (timeLimit === true) {
        // @ts-ignore
        query = query.where(ID.date_creation, '>', xYearsAgo);
      }
      // @ts-ignore
      query = query.orderBy(ID.date_creation, 'desc'); // needed because of index
      listenerPinboards.push(
        // @ts-ignore
        yield fork(rsfFirestore.syncCollection, query, {
          successActionCreator: actions.subscribePinboards.success,
          failureActionCreator: actions.subscribePinboards.failure,
          transform: (payload) => ({
            data: snapshotAsArray(payload, true).map((ele) => ({
              ...ele,
              dbCollection: DB.ia_pinboard,
            })),
            type: ID.public,
          }),
        }),
      );
    } else {
      // If user has <= X mandate_active, fetch only them
      let query = database.collection(DB.ia_pinboard);
      if (timeLimit === true) {
        // @ts-ignore
        query = query.where(ID.date_creation, '>', xYearsAgo);
      }
      // @ts-ignore
      query = query.where(ID.mandate_ids, 'array-contains-any', mandate_active);
      // @ts-ignore
      query = query.orderBy(ID.date_creation, 'desc'); // needed because of index
      listenerPinboards.push(
        // @ts-ignore
        yield fork(rsfFirestore.syncCollection, query, {
          successActionCreator: actions.subscribePinboards.success,
          failureActionCreator: actions.subscribePinboards.failure,
          transform: (payload) => ({
            data: snapshotAsArray(payload, true).map((ele) => ({
              ...ele,
              dbCollection: DB.ia_pinboard,
            })),
            type: ID.public,
          }),
        }),
      );
      listenerPinboards.push(
        // @ts-ignore
        yield fork(
          rsfFirestore.syncCollection,
          database
            .collection(DB.ia_pinboard)
            .where(ID.user_receiver_ids, 'array-contains-any', [
              rx_userAccount.id,
            ]),
          {
            successActionCreator: actions.subscribePinboards.success,
            failureActionCreator: actions.subscribePinboards.failure,
            transform: (payload) => ({
              data: snapshotAsArray(payload, true).map((ele) => ({
                ...ele,
                dbCollection: DB.ia_pinboard,
              })),
              type: ID.private,
            }),
          },
        ),
      );
    }
    // If cancel is called, the code continues to execute
    yield take(actions.cancelPinboards.TRIGGER);
    // And we cancel the database listener
    for (const listener of listenerPinboards) {
      yield cancel(listener);
    }
    listenerPinboards = [];
  } catch (error) {
    Reporting.Error(error);
    for (const listener of listenerPinboards) {
      yield cancel(listener);
    }
    listenerPinboards = [];
    yield put(actions.subscribePinboards.failure('global.generalErrorMessage'));
  } finally {
  }
}

// REALTIME
let listenerVotes: any = null;
function* subscribeVotes({ payload }: any) {
  const { timeLimit } = payload;
  try {
    // @ts-ignore
    const rx_userAccount = yield select(getUserAccount);
    if (rx_userAccount == null) {
      return;
    }
    // This makes sure that the database listener is only active once
    if (listenerVotes?.isRunning() === true) {
      return;
    }
    yield put(actions.subscribeVotes.request());
    const mandate_active = Object.keys(rx_userAccount.mandate_active ?? {}).map(
      (k) => parseInt(k),
    );
    if (mandate_active.length === 0) {
      // This might happen if a user only has deactivated mandates
      // Add a filler so the query doesn't fail
      mandate_active.push(0);
    }
    if (
      mandate_active.length > FIRESTORE_ARRAY_CONTAINS_ANY_LIMIT ||
      rx_userAccount.role >= ACCOUNT_ROLE.admin.value
    ) {
      // If user has > X mandate_active or is an admin, fetch all data
      let query = database.collection(DB.ia_votes);
      if (timeLimit === true) {
        // @ts-ignore
        query = query.where(ID.date_end, '>', xYearsAgo);
      }
      // @ts-ignore
      query = query.orderBy(ID.date_end, 'desc'); // needed because of index

      // @ts-ignore
      listenerVotes = yield fork(rsfFirestore.syncCollection, query, {
        successActionCreator: actions.subscribeVotes.success,
        failureActionCreator: actions.subscribeVotes.failure,
        transform: (payload) =>
          snapshotAsArray(payload, true).map((ele) => ({
            ...ele,
            dbCollection: DB.ia_votes,
          })),
      });
    } else {
      // If user has <= X mandate_active, fetch only them
      let query = database.collection(DB.ia_votes);
      if (timeLimit === true) {
        // @ts-ignore
        query = query.where(ID.date_end, '>', xYearsAgo);
      }
      // @ts-ignore
      query = query.where(ID.mandate_id, 'in', mandate_active);
      // @ts-ignore
      query = query.orderBy(ID.date_end, 'desc'); // needed because of index
      // @ts-ignore
      listenerVotes = yield fork(rsfFirestore.syncCollection, query, {
        successActionCreator: actions.subscribeVotes.success,
        failureActionCreator: actions.subscribeVotes.failure,
        transform: (payload) =>
          snapshotAsArray(payload, true).map((ele) => ({
            ...ele,
            dbCollection: DB.ia_votes,
          })),
      });
    }
    // If cancel is called, the code continues to execute
    yield take(actions.cancelVotes.TRIGGER);
    // And we cancel the database listener
    yield cancel(listenerVotes);
    listenerVotes = null;
  } catch (error) {
    Reporting.Error(error);
    yield cancel(listenerVotes);
    listenerVotes = null;
    yield put(actions.subscribeVotes.failure('global.generalErrorMessage'));
  } finally {
  }
}

// REALTIME
let listenerVoteUsers: any = null;
function* subscribeVoteUsers({ payload }: any) {
  try {
    // This makes sure that the database listener is only active once
    if (listenerVoteUsers?.isRunning() === true) {
      return;
    }
    yield put(actions.subscribeVoteUsers.request());
    // @ts-ignore
    listenerVoteUsers = yield fork(
      rsfFirestore.syncCollection,
      database
        .collection(DB.ia_votes)
        .doc(payload)
        .collection(DB.sub_users)
        .orderBy(ID.vote_status, 'desc'),
      {
        successActionCreator: actions.subscribeVoteUsers.success,
        failureActionCreator: actions.subscribeVoteUsers.failure,
        transform: (payload) => {
          return snapshotAsArray(payload, true);
        },
      },
    );
    // If cancel is called, the code continues to execute
    yield take(actions.cancelVoteUsers.TRIGGER);
    // And we cancel the database listener
    yield cancel(listenerVoteUsers);
    listenerVoteUsers = null;
  } catch (error) {
    Reporting.Error(error);
    yield cancel(listenerVoteUsers);
    listenerVoteUsers = null;
    yield put(actions.subscribeVoteUsers.failure('global.generalErrorMessage'));
  } finally {
  }
}

// REALTIME
let listenerFaqs: any = null;
function* subscribeFaqs() {
  try {
    // This makes sure that the database listener is only active once
    if (listenerFaqs?.isRunning() === true) {
      return;
    }
    yield put(actions.subscribeFaqs.request());
    // @ts-ignore
    listenerFaqs = yield fork(
      rsfFirestore.syncCollection,
      database.collection(DB.infocenter).doc(DB.doc_faqs),
      {
        successActionCreator: actions.subscribeFaqs.success,
        failureActionCreator: actions.subscribeFaqs.failure,
        transform: (payload) => payload.data().data,
      },
    );
    // If logout is called, the code continues to execute
    yield take(actions.cancelFaqs.TRIGGER);
    // And we cancel the database listener
    yield cancel(listenerFaqs);
    listenerFaqs = null;
  } catch (error) {
    Reporting.Error(error);
    listenerFaqs = null;
    yield put(actions.subscribeFaqs.failure('global.generalErrorMessage'));
  } finally {
  }
}

function* setFetchTimeLimit({ payload }: any) {
  const { category } = payload;
  try {
    // Cancel previous realtime listener and start new
    switch (category) {
      case ID.message:
        yield put(actions.cancelMessages.trigger());
        yield put(actions.subscribeMessages.trigger({ timeLimit: false }));
        yield take(actions.subscribeMessages.SUCCESS);
        break;
      case ID.repair:
        yield put(actions.cancelRepairs.trigger());
        yield put(actions.subscribeRepairs.trigger({ timeLimit: false }));
        yield take(actions.subscribeRepairs.SUCCESS);
        break;
      case ID.pinboard:
        yield put(actions.cancelPinboards.trigger());
        yield put(actions.subscribePinboards.trigger({ timeLimit: false }));
        yield take(actions.subscribePinboards.SUCCESS);
        break;
      case ID.vote:
        yield put(actions.cancelVotes.trigger());
        yield put(actions.subscribeVotes.trigger({ timeLimit: false }));
        yield take(actions.subscribeVotes.SUCCESS);
        break;
      default:
        // @ts-ignore
        const rx_userAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.id} - Payload: ${JSON.stringify(payload)}`,
        );
    }
    yield put(actions.setFetchTimeLimit.success(category));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.setFetchTimeLimit.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.setFetchTimeLimit.fulfill());
  }
}

function* setFolderMetaAccess({ payload }: any) {
  const { folderPath, accessGroupString, accessUserString } = payload;
  try {
    let i = 0;
    // In case the .access file doesn't exist yet. Create it and try again. Max retry: 2
    while (i < 2) {
      i++;
      try {
        yield storageRef
          .child(`${folderPath}/${STRING_TOOLS.access_file}`)
          .updateMetadata({
            customMetadata: {
              // Note: this syntax is different than "x ?? null" as it checks if the string is empty, not just null
              // Setting null removes the value from server, which means the default access is used again
              access_group: accessGroupString ?? null,
              access_user: accessUserString ?? null,
            },
          });
        break;
      } catch (error: any) {
        // Upload a new empty .access file (without any metadata)
        if (error.code === ERROR_CODE.storage_object_not_found) {
          yield storageRef
            .child(`${folderPath}/${STRING_TOOLS.access_file}`)
            .putString('');
        } else {
          Reporting.Error(error);
          break;
        }
      }
    }
    // Refresh
    const parentRef = storageRef.child(folderPath).parent;
    yield put(
      actions.getFolderContent({
        path: parentRef?.fullPath,
        name: '',
        fastReturn: false,
      }),
    );
    yield put(actions.setFolderMetaAccess.success());
    // Show success message
    showMessage('documents.accessRightsUpdated', null, ID.success);
    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.setFolderMetaAccess.failure(error));
    // Show error message
    showMessage('global.generalErrorMessage', null, ID.error);
  } finally {
    yield delay(1);
    yield put(actions.setFolderMetaAccess.fulfill());
  }
}

function* getFolderContent({ payload }: any) {
  const { path, name, access_group, fastReturn } = payload;
  // @ts-ignore
  const rx_userAccount = yield select(getUserAccount);
  if (rx_userAccount == null) {
    return;
  }
  // @ts-ignore
  const rx_users = yield select(getUsers);
  try {
    const result: any = {
      data: [],
      path: path,
      breadcrumb: [{ name, path, access_group }],
    };
    if (path == null) {
      // @ts-ignore
      const rootFolders = (yield storageRef
        .child(STORAGE.document_files)
        .listAll()).prefixes;
      rootFolders.forEach((ele: any, index: number) => {
        let tmpRootId = undefined;
        let tmpRootName = '';
        let tmpRootType = '';

        const folderPattern = extractDocumentFolderPattern(ele.name) ?? {
          type: FOLDER_PATTERN.MANDATE,
          id: getMandateCodeOrFirstNumberInString(ele.name),
        };
        switch (folderPattern?.type) {
          case FOLDER_PATTERN.MANDATE:
            // Check if user.mandate_active contains this mandateId
            // Admins can also see inactive mandate folders
            // All other users only see active mandate folders
            if (
              rx_userAccount.mandate_data[folderPattern.id] == null ||
              (rx_userAccount.role < ACCOUNT_ROLE.admin.value &&
                rx_userAccount.mandate_active[folderPattern.id] == null)
            ) {
              return;
            }

            tmpRootId = folderPattern.id;
            tmpRootType =
              rx_userAccount.mandate_active[folderPattern.id] == null
                ? 'rootMandateInactive'
                : 'rootMandate';
            tmpRootName = `${folderPattern.id} - ${
              rx_userAccount.mandate_data[folderPattern.id]
            }`;
            break;
          case FOLDER_PATTERN.PERSON:
            // Check that the person ID matches
            if (rx_userAccount.role < ACCOUNT_ROLE.manager.value) {
              return;
            }
            tmpRootId =
              FOLDER_PATTERN.PERSON_SEARCH_ID_EXTRA + folderPattern.id; // Used for sorting. Always on bottom.
            tmpRootType = 'rootPerson';
            const user = rx_users.find(
              (user: any) => user.person_id === folderPattern.id,
            );
            tmpRootName = `${translate('document.personalFolder')}${
              user == null ? `` : ` - ${user.first_name} ${user.last_name}`
            } (ID: ${folderPattern.id})`;
            break;
          default:
            return;
        }

        result.data.push({
          type: ID.folder,
          subtype: tmpRootType,
          mandate_id: tmpRootId,
          name: tmpRootName,
          key: index,
          path: ele.fullPath,
        });
      });
      result.data.sort((a: any, b: any) => {
        if (a.mandate_id > b.mandate_id) {
          return 1;
        }
        if (a.mandate_id < b.mandate_id) {
          return -1;
        }
        return 0;
      });
      yield put(actions.getFolderContent.success(result));
    } else {
      // @ts-ignore
      const folderContent = yield storageRef.child(path).listAll();
      const promises = [];
      // Early folder, but without names in case we don't have access
      for (const folder of folderContent.prefixes) {
        result.data.push({
          type: ID.folder,
          name: null,
          key: folder.name,
          path: folder.fullPath,
          access_group: null,
          access_user: null,
          access_user_import: null,
        });
      }
      // Get folders complete
      for (const folder of folderContent.prefixes) {
        let access_group = null;
        let access_user = null;
        let access_user_import = null;
        promises.push(
          (async () => {
            try {
              const metadata = await storageRef
                .child(`${folder.fullPath}/${STRING_TOOLS.access_file}`)
                .getMetadata();
              if (metadata.customMetadata?.access_user != null) {
                access_user = metadata.customMetadata.access_user;
              }
              if (metadata.customMetadata?.access_user_import != null) {
                access_user_import = metadata.customMetadata.access_user_import;
              }
              if (metadata.customMetadata?.access_group != null) {
                // .access file exists and has access_group key
                access_group = metadata.customMetadata.access_group;
              } else {
                const doc = await Firebase.database
                  .collection(DB.settings)
                  .doc(DB.doc_filemanager)
                  .get();
                access_group = doc.data()?.[DB.field_default_access_group];
              }
            } catch (err) {
              // Access file doesnt exist yet (default)
              const doc = await Firebase.database
                .collection(DB.settings)
                .doc(DB.doc_filemanager)
                .get();
              access_group = doc.data()?.[DB.field_default_access_group];
              // Upload a new empty .access file (without any metadata so the default access is used)
              storageRef
                .child(`${folder.fullPath}/${STRING_TOOLS.access_file}`)
                .putString('');
            } finally {
              const elemIndex = result.data.findIndex(
                (e: any) => e.key === folder.name,
              );
              if (elemIndex > -1) {
                const abaImmoAccessRules =
                  rx_userAccount.role >= ACCOUNT_ROLE.manager.value ||
                  access_user?.includes(rx_userAccount.id) === true ||
                  access_user_import?.includes(rx_userAccount.id) === true;

                const defaultAccessRules =
                  rx_userAccount.role === ACCOUNT_ROLE.admin.value ||
                  access_group?.includes(rx_userAccount.role.toString()) ===
                    true ||
                  access_user?.includes(rx_userAccount.id) === true ||
                  access_user_import?.includes(rx_userAccount.id) === true;

                if (
                  process.env.REACT_APP_CLIENT_ERP_SYSTEM === ID.abaimmo
                    ? abaImmoAccessRules
                    : defaultAccessRules
                ) {
                  result.data[elemIndex].name = folder.name;
                  result.data[elemIndex].access_group = access_group;
                  result.data[elemIndex].access_user = access_user;
                  result.data[elemIndex].access_user_import =
                    access_user_import;
                } else {
                  result.data.splice(elemIndex, 1);
                }
              }
            }
          })(),
        );
      }
      // Early get file
      for (const file of folderContent.items) {
        if (HiddenFiles.some((ele) => ele === file.name.toLowerCase())) {
          continue;
        }
        result.data.push({
          name: file.name,
          key: file.name,
          path: file.fullPath,
        });
      }
      // Early return to reduce felt waiting time
      if (fastReturn) {
        yield put(actions.getFolderContent.success(result));
      }
      // Get items complete
      for (const file of folderContent.items) {
        if (HiddenFiles.some((ele) => ele === file.name.toLowerCase())) {
          continue;
        }
        promises.push(
          file
            .getMetadata()
            .then((metadata: any) => {
              const elemIndex = result.data.findIndex(
                (e: any) => e.path === file.fullPath,
              );
              if (elemIndex > -1) {
                result.data[elemIndex].last_updated_at = metadata.updated;
                result.data[elemIndex].file_size = metadata.size;
                result.data[elemIndex].type = metadata.contentType.replace(
                  /[-.]/g,
                  '_',
                );
              } else {
                result.data.splice(elemIndex, 1);
              }
            })
            .catch((error: any) => {
              console.warn(error.message);
            }),
        );
      }
      yield Promise.all(promises);
      result.data.sort((a: any, b: any) => {
        if (
          (a.type === ID.folder && b.type !== ID.folder) ||
          (b.type === ID.folder && a.type !== ID.folder)
        ) {
          return 1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      // Needed because otherwise the screen doesn't detect an update
      const resultNew = JSON.parse(JSON.stringify(result));
      yield put(actions.getFolderContent.success(resultNew));
    }
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getFolderContent.failure(error));
  } finally {
    yield delay(1);
    yield put(actions.getFolderContent.fulfill());
  }
}

function* getAllMandateData({ payload }: any) {
  const mandateIdInt = parseInt(payload);
  try {
    const promises = [];
    let properties: TPropertyWithObjectData[] = [];
    let objects: TObjectWithPersonData[] = [];
    let persons: CPerson[] = [];
    promises.push(
      database
        .collection(DB.ia_properties)
        .where(ID.mandate_id, '==', mandateIdInt)
        .orderBy(ID.property_id, 'asc')
        .get()
        .then((querySnapshot) => {
          // @ts-ignore
          properties = snapshotAsArray(querySnapshot);
        }),
    );
    // get objects
    promises.push(
      database
        .collection(DB.ia_objects)
        .where(ID.mandate_id, '==', mandateIdInt)
        .orderBy(ID.object_id, 'asc')
        .get()
        .then((querySnapshot) => {
          // @ts-ignore
          objects = snapshotAsArray(querySnapshot);
        }),
    );
    // persons
    promises.push(
      database
        .collection(DB.ia_persons)
        .where(ID.mandate_ids, 'array-contains', mandateIdInt)
        .get()
        .then((querySnapshot) => {
          // @ts-ignore
          persons = snapshotAsArray(querySnapshot);
        }),
    );
    yield Promise.all(promises);

    // get object owners (no need for async)
    objects = objects.map((object) => ({
      ...object,
      owners: object.owners.map((owner) => ({
        ...owner,
        person: queryFirst(persons, ID.person_id, owner.person_id),
      })),
    }));

    // get object tenants (not async)
    objects = objects.map((object) => ({
      ...object,
      tenants: object.tenants.map((tenant) => ({
        ...tenant,
        person: queryFirst(persons, ID.person_id, tenant.person_id),
      })),
    }));

    // get property objects (not async)
    properties = properties.map((property) => {
      property.objects = queryHelp(
        objects,
        ID.property_id,
        property.property_id,
      );
      return property;
    });
    yield put(
      actions.getAllMandateData.success({
        persons: persons,
        properties: properties,
      }),
    );
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getAllMandateData.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.getAllMandateData.fulfill());
  }
}

function* getMandateProperties({ payload }: any) {
  const mandateId = payload;
  try {
    // @ts-ignore
    const querySnapshot = yield database
      .collection(DB.ia_properties)
      .where(ID.mandate_id, '==', parseInt(mandateId))
      .orderBy(ID.property_id, 'asc')
      .get();
    const result = snapshotAsArray(querySnapshot, true);
    yield put(actions.getMandateProperties.success(result));
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.getMandateProperties.failure('global.generalErrorMessage'),
    );
  } finally {
    yield delay(1);
    yield put(actions.getMandateProperties.fulfill());
  }
}

function* getRootFolders() {
  try {
    // Get all the entries in documents folder. Then do a local search if the mandate id exists in any folder.
    // @ts-ignore
    const rootFoldersRaw = (yield storageRef
      .child(STORAGE.document_files)
      .listAll()).prefixes;
    yield put(actions.getRootFolders.success(rootFoldersRaw));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getRootFolders.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.getRootFolders.fulfill());
  }
}

function* searchMandateUsersByLastNameOrEmail({ payload }: any) {
  const { mandateId, searchInput } = payload;
  try {
    const qry = query(
      collection(database, DB.ia_users),
      and(
        where(ID.mandate_ids, 'array-contains', mandateId),
        or(
          where(ID.email, '==', searchInput.toLowerCase()),
          where(ID.last_name_ci, '==', searchInput.toLowerCase()),
        ),
      ),
    );
    // @ts-ignore
    const querySnapshot = yield getDocs(qry);
    const users = snapshotAsArray(querySnapshot, true).map((ele) => ({
      ...ele,
      uid: ele.id,
    }));
    yield put(actions.searchMandateUsersByLastNameOrEmail.success(users));
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.searchMandateUsersByLastNameOrEmail.failure(
        'global.generalErrorMessage',
      ),
    );
  } finally {
    yield delay(1);
    yield put(actions.searchMandateUsersByLastNameOrEmail.fulfill());
  }
}

function* getEmailDeliveryDetails({ payload }: any) {
  try {
    const messageId = payload;
    // @ts-ignore
    const docRef = yield call(
      rsfFirestore.getDocument,
      database
        .collection(DB.ia_messages)
        .doc(messageId)
        .collection(DB.sub_email_delivery_report_details)
        .doc('main'),
    );
    yield put(
      actions.getEmailDeliveryDetails.success(
        docRef.exists ? docRef.data().data : [],
      ),
    );
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getEmailDeliveryDetails.failure());
  } finally {
    yield delay(1);
    yield put(actions.getEmailDeliveryDetails.fulfill());
  }
}

function* getPerson({ payload }: any) {
  try {
    const personId = payload.toString();
    // @ts-ignore
    const docRef = yield call(
      rsfFirestore.getDocument,
      database.collection(DB.ia_persons).doc(personId),
    );
    if (docRef.exists) {
      yield put(actions.getPerson.success(docRef.data()));
    } else {
      const err = new Error('Person with id not found: ' + personId);
      Reporting.Error(err);
      yield put(actions.getPerson.failure(err));
    }
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getPerson.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.getPerson.fulfill());
  }
}

function* setPersonInvite({ payload }: any) {
  const { personId, invite, mandateIds, includeJanitors, language } = payload;
  try {
    // @ts-ignore
    const docRef = yield call(
      rsfFirestore.getDocument,
      database.collection(DB.ia_persons).doc(personId.toString()),
    );
    if (docRef.exists) {
      yield call(rsfFirestore.updateDocument, `${DB.ia_persons}/${personId}`, {
        invite: invite,
      });
      yield put(
        actions.createUsersFromPersons({
          securityQuestionApproved: false,
          mandateIds: mandateIds,
          includeJanitors: includeJanitors,
          language: language,
        }),
      );
      yield put(actions.setPersonInvite.success(docRef.data()));
    } else {
      const err = new Error('Person with id not found: ' + personId);
      Reporting.Error(err);
      yield put(actions.setPersonInvite.failure(err));
    }
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.setPersonInvite.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.setPersonInvite.fulfill());
  }
}

function* staffAction({ payload }: any) {
  const { actionName, id, ...record } = payload;
  if (record.last_name != null) {
    record.last_name_ci = record.last_name.toLowerCase();
  }
  try {
    switch (actionName) {
      case ID.delete:
        yield call(rsfFirestore.deleteDocument, `${DB.ia_users}/${id}`);
        yield put(actions.staffAction.success('staff.deleted'));
        break;
      case ID.update:
        if (record.time_invited != null) {
          record.time_invited = handleTimeStamps(record.time_invited);
        }
        // @ts-ignore
        yield call(rsfFirestore.setDocument, `${DB.ia_users}/${id}`, record);
        yield put(actions.staffAction.success('staff.updated'));
        break;
      case ID.insert:
        record.project_id = process.env.REACT_APP_FB_PROJECT_ID;
        record.verify_auto = true;
        record.source = USER_SOURCE.webapp_admin.value;
        record.send_email = record.send_email ?? true;
        record.show_as_contact = record.show_as_contact ?? true;
        record.language =
          process.env.REACT_APP_PLATFORM_LANG || DEFAULT_LANGUAGE_ID;
        const options: AxiosRequestConfig = {
          method: 'POST',
          url: `${URLS.control_cloud}/${CONTROL_FUNCTIONS.ic_register_user}?idToken=${SECRETS.access_token}`,
          data: record,
        };
        yield axios(options);
        yield put(actions.staffAction.success('staff.added'));
        break;
      default:
        // @ts-ignore
        const rx_userAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.id} - Payload: ${JSON.stringify(payload)}`,
        );
    }
    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error: any) {
    // 301: email already exists
    if (error.response && parseInt(error.response.status) === 301) {
      yield put(actions.staffAction.failure('global.emailExistsErrorMessage'));
    } else {
      Reporting.Error(error);
      // for general catch there is no need to call the failure action
      showMessage('global.generalErrorMessage', null, ID.error);
    }
  } finally {
    yield delay(1);
    yield put(actions.staffAction.fulfill());
  }
}

function* reservationAction({ payload }: any) {
  const { actionName, mandateId, data } = payload;
  try {
    yield database
      .collection(DB.ia_mandates)
      .doc(`${mandateId}`)
      .update({ reservation_places: data });

    switch (actionName) {
      case ID.insert:
        yield put(actions.reservationAction.success('reservation.added'));
        break;
      case ID.update:
        yield put(actions.reservationAction.success('reservation.updated'));
        break;
      case ID.delete:
        yield put(actions.reservationAction.success('reservation.deleted'));
        break;
      default:
        // @ts-ignore
        const rx_userAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.id} - Payload: ${JSON.stringify(payload)}`,
        );
    }

    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.reservationAction.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.reservationAction.fulfill());
  }
}

function* mandateAction({ payload }: any) {
  const { actionName, id, fileList, ...record } = payload;
  const hasNewFiles =
    fileList != null &&
    fileList.some(
      (f: any) =>
        f.size != null || f.originFileObj != null || f.isEdited === true,
    );
  const hasChangedFiles =
    fileList != null && record.image?.path != null && fileList.length === 0;
  try {
    switch (actionName) {
      case ID.delete:
        yield call(rsfFirestore.deleteDocument, `${DB.ia_mandates}/${id}`);
        yield put(actions.mandateAction.success('mandate.deleted'));
        break;
      case ID.update:
        if (hasNewFiles || hasChangedFiles) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.mandate_images,
            fileList,
          );
          showMessage('global.fileUploaded', null, ID.success, ID.key);
          record.image = files.length > 0 ? files[0] : {};
        }
        if (!record.useful_info) {
          record.useful_info = Firebase.FieldValue.delete();
        }
        yield database.collection(DB.ia_mandates).doc(`${id}`).update(record);
        yield put(actions.mandateAction.success('mandate.updated'));
        break;
      default:
        // @ts-ignore
        const rx_userAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.id} - Payload: ${JSON.stringify(payload)}`,
        );
    }
    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.mandateAction.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.mandateAction.fulfill());
    // This does not show a loading indicator
    yield put(actions.getLicenseObjects(true));
  }
}

function* messageAction({ payload }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { actionName, id, filesArray, isShell, dbCollection, ...record } =
    payload;
  record.date_creation = handleTimeStamps(record.date_creation);
  if (record.comments && !_.isEmpty(record.comments)) {
    Object.values(record.comments as Record<string, IComment>).forEach(
      (comment) => {
        comment.date_creation = handleTimeStamps(comment.date_creation);
        comment.time_created = handleTimeStamps(comment.time_created);
      },
    );
  }
  const hasNewFiles = filesArray?.some(
    (f: any) =>
      f.size != null || f.originFileObj != null || f.isEdited === true,
  );
  const hasChangedFiles =
    filesArray != null &&
    record.files != null &&
    filesArray.length !== record.files.length;
  try {
    switch (actionName) {
      case ID.delete:
        yield call(rsfFirestore.deleteDocument, `${DB.ia_messages}/${id}`);
        yield put(actions.messageAction.success('messages.deleted'));
        break;
      case ID.update:
        if (hasNewFiles || hasChangedFiles) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.message_files,
            filesArray,
          );
          showMessage(
            `global.${
              filesArray.length > record.files?.length
                ? 'filesUploaded'
                : 'filesUpdated'
            }`,
            null,
            ID.success,
            ID.key,
          );
          record.files = files;
        } else if (record.files == null || filesArray?.length === 0) {
          record.files = [];
        }
        // @ts-ignore
        yield call(rsfFirestore.setDocument, `${DB.ia_messages}/${id}`, record);
        yield put(actions.messageAction.success('messages.updated'));
        break;
      case ID.comment:
        // @ts-ignore
        const { user_id } = jwtDecode(getToken());
        const options: AxiosRequestConfig = {
          method: 'POST',
          url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/${SPACE_FUNCTIONS.ia_create_comment}?idToken=${SECRETS.access_token}`,
          data: {
            doc_id: id,
            description: record.comment,
            uid: user_id,
            dbCollection: DB.ia_messages,
          },
        };
        yield axios(options);
        yield put(actions.messageAction.success('global.commentAdded'));
        break;
      case ID.insert:
        if (filesArray?.length > 0) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.message_files,
            filesArray,
          );
          showMessage('global.filesUploaded', null, ID.success, ID.key);
          record.files = files;
        } else if (record.files == null || filesArray?.length === 0) {
          record.files = [];
        }
        yield call(rsfFirestore.addDocument, DB.ia_messages, record);
        yield put(actions.messageAction.success('messages.inserted'));
        break;
      case ID.copy:
        // hasNewFiles and hasChanged login was not reliable for copy, so treating it the same way as of insert(for all copy actions)
        if (filesArray?.length > 0) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.message_files,
            filesArray,
            { ...FileUploadProps, isCopy: true },
          );
          showMessage('global.filesUploaded', null, ID.success, ID.key);
          record.files = files;
        } else if (record.files == null || filesArray?.length === 0) {
          record.files = [];
        }
        yield call(rsfFirestore.addDocument, DB.ia_messages, record);
        yield put(actions.messageAction.success('messages.inserted'));
        break;
      default:
        // @ts-ignore
        const rx_userAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.id} - Payload: ${JSON.stringify(payload)}`,
        );
    }
    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.messageAction.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.messageAction.fulfill());
  }
}

function* repairAction({ payload }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { actionName, id, filesArray, isShell, dbCollection, ...record } =
    payload;
  record.date_creation = handleTimeStamps(record.date_creation);
  if (record.timeline && record.timeline.length > 0) {
    record.timeline.forEach((timeline: IRepairTimeline) => {
      timeline.date_creation = handleTimeStamps(timeline.date_creation);
    });
  }
  if (record.comments && !_.isEmpty(record.comments)) {
    Object.values(record.comments as Record<string, IComment>).forEach(
      (comment) => {
        comment.date_creation = handleTimeStamps(comment.date_creation);
        comment.time_created = handleTimeStamps(comment.time_created);
      },
    );
  }
  const hasNewFiles = filesArray?.some(
    (f: any) =>
      f.size != null || f.originFileObj != null || f.isEdited === true,
  );
  const hasChangedFiles =
    filesArray != null &&
    record.files != null &&
    filesArray.length !== record.files.length;
  try {
    switch (actionName) {
      case ID.delete:
        yield call(rsfFirestore.deleteDocument, `${DB.ia_repairs}/${id}`);
        yield put(actions.repairAction.success('repair.deleted'));
        break;
      case ID.update:
        if (hasNewFiles || hasChangedFiles) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.repair_files,
            filesArray,
          );
          showMessage(
            `global.${
              filesArray.length > record.files?.length
                ? 'filesUploaded'
                : 'filesUpdated'
            }`,
            null,
            ID.success,
            ID.key,
          );
          record.files = files;
        } else if (record.files == null || filesArray?.length === 0) {
          record.files = [];
        }
        // @ts-ignore
        yield call(rsfFirestore.setDocument, `${DB.ia_repairs}/${id}`, record);
        yield put(actions.repairAction.success('repair.updated'));
        break;
      case ID.comment:
        // @ts-ignore
        const { user_id } = jwtDecode(getToken());
        const options: AxiosRequestConfig = {
          method: 'POST',
          url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/${SPACE_FUNCTIONS.ia_create_comment}?idToken=${SECRETS.access_token}`,
          data: {
            uid: user_id,
            doc_id: id,
            description: record.comment,
            dbCollection: DB.ia_repairs,
          },
        };
        yield axios(options);
        yield put(actions.repairAction.success('global.commentAdded'));
        break;
      case ID.copy:
        if (filesArray?.length > 0) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.repair_files,
            filesArray,
            { ...FileUploadProps, isCopy: true },
          );
          showMessage('global.filesUploaded', null, ID.success, ID.key);
          record.files = files;
        } else if (record.files == null || filesArray?.length === 0) {
          record.files = [];
        }
        yield call(rsfFirestore.addDocument, DB.ia_repairs, record);
        yield put(actions.repairAction.success('repair.inserted'));
        break;
      case ID.insert:
        if (filesArray?.length > 0) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.repair_files,
            filesArray,
          );
          showMessage('global.filesUploaded', null, ID.success, ID.key);
          record.files = files;
        } else if (record.files == null || filesArray?.length === 0) {
          record.files = [];
        }
        yield call(rsfFirestore.addDocument, DB.ia_repairs, record);
        yield put(actions.repairAction.success('repair.inserted'));
        break;
      default:
        // @ts-ignore
        const rx_userAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.id} - Payload: ${JSON.stringify(payload)}`,
        );
    }
    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.repairAction.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.repairAction.fulfill());
  }
}

function* pinboardAction({ payload }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { actionName, id, filesArray, isShell, dbCollection, ...record } =
    payload;
  record.date_creation = handleTimeStamps(record.date_creation);
  if (record.comments && !_.isEmpty(record.comments)) {
    Object.values(record.comments as Record<string, IComment>).forEach(
      (comment) => {
        comment.date_creation = handleTimeStamps(comment.date_creation);
        comment.time_created = handleTimeStamps(comment.time_created);
      },
    );
  }
  const hasNewFiles = filesArray?.some(
    (f: any) =>
      f.size != null || f.originFileObj != null || f.isEdited === true,
  );
  const hasChangedFiles =
    filesArray != null &&
    record.files != null &&
    filesArray.length !== record.files.length;
  try {
    switch (actionName) {
      case ID.delete:
        yield call(rsfFirestore.deleteDocument, `${DB.ia_pinboard}/${id}`);
        yield put(actions.pinboardAction.success('pinboard.deleted'));
        break;
      case ID.update:
        if (hasNewFiles || hasChangedFiles) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.pinboard_files,
            filesArray,
          );
          showMessage(
            `global.${
              filesArray.length > record.files?.length
                ? 'filesUploaded'
                : 'filesUpdated'
            }`,
            null,
            ID.success,
            ID.key,
          );
          record.files = files;
        } else if (record.files == null || filesArray?.length === 0) {
          record.files = [];
        }
        // @ts-ignore
        yield call(rsfFirestore.setDocument, `${DB.ia_pinboard}/${id}`, record);
        yield put(actions.pinboardAction.success('pinboard.updated'));
        break;
      case ID.comment:
        // @ts-ignore
        const { user_id } = jwtDecode(getToken());
        const options: AxiosRequestConfig = {
          method: 'POST',
          url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/${SPACE_FUNCTIONS.ia_create_comment}?idToken=${SECRETS.access_token}`,
          data: {
            doc_id: id,
            description: record.comment,
            uid: user_id,
            dbCollection: DB.ia_pinboard,
          },
        };
        yield axios(options);
        yield put(actions.pinboardAction.success('global.commentAdded'));
        break;
      case ID.copy:
        if (filesArray?.length > 0) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.pinboard_files,
            filesArray,
            { ...FileUploadProps, isCopy: true },
          );
          showMessage('global.filesUploaded', null, ID.success, ID.key);
          record.files = files;
        } else if (record.files == null || filesArray?.length === 0) {
          record.files = [];
        }
        yield call(rsfFirestore.addDocument, DB.ia_pinboard, record);
        yield put(actions.pinboardAction.success('pinboard.inserted'));
        break;
      case ID.insert:
        if (filesArray?.length > 0) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.pinboard_files,
            filesArray,
          );
          showMessage('global.filesUploaded', null, ID.success, ID.key);
          record.files = files;
        } else if (record.files == null || filesArray?.length === 0) {
          record.files = [];
        }
        yield call(rsfFirestore.addDocument, DB.ia_pinboard, record);
        yield put(actions.pinboardAction.success('pinboard.inserted'));
        break;
      default:
        // @ts-ignore
        const rx_userAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.id} - Payload: ${JSON.stringify(payload)}`,
        );
    }
    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.pinboardAction.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.pinboardAction.fulfill());
  }
}

function* userAction({ payload }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { actionName, uid, id, ...record } = payload;
  if (record.last_name != null) {
    record.last_name_ci = record.last_name.toLowerCase();
  }
  try {
    switch (actionName) {
      case ID.delete:
        // If a connection with person exists, set approved to -2
        if (record.person_id > -1) {
          yield call(rsfFirestore.updateDocument, `${DB.ia_users}/${uid}`, {
            approved: -2,
          });
          showMessage('user.deactivated', null, ID.success);
          yield put(actions.userAction.success());
        } else {
          // Else: delete the user
          yield call(rsfFirestore.deleteDocument, `${DB.ia_users}/${uid}`);
          showMessage('user.deleted', null, ID.success);
          yield put(actions.userAction.success());
        }
        break;
      case ID.reactivate:
        yield call(rsfFirestore.updateDocument, `${DB.ia_users}/${uid}`, {
          approved: 1,
        });
        showMessage('user.reactivated', null, ID.success);
        yield put(actions.userAction.success());
        break;
      case ID.insert:
        record.project_id = process.env.REACT_APP_FB_PROJECT_ID;
        record.verify_auto = true;
        record.source = USER_SOURCE.webapp_admin.value;
        record.send_email = record.send_email ?? true;
        if (record.role > ACCOUNT_ROLE.owner.value) {
          record.show_as_contact = record.show_as_contact ?? true;
        }
        record.language =
          process.env.REACT_APP_PLATFORM_LANG || DEFAULT_LANGUAGE_ID;
        const options: AxiosRequestConfig = {
          method: 'POST',
          url: `${URLS.control_cloud}/${CONTROL_FUNCTIONS.ic_register_user}?idToken=${SECRETS.access_token}`,
          data: record,
        };
        showMessage('user.adding', null, ID.loading, ID.key);
        yield axios(options);
        showMessage('user.added', null, ID.success, ID.key);
        yield put(actions.userAction.success());
        // In case of success close modal
        yield put(actionsApp.setModal(ID.none));
        break;
      case ID.update:
        // Update all other the data directly
        if (record.time_invited != null) {
          record.time_invited = handleTimeStamps(record.time_invited);
        }
        // @ts-ignore
        yield call(rsfFirestore.setDocument, `${DB.ia_users}/${uid}`, record);
        showMessage('user.edited', null, ID.success);
        yield put(actions.userAction.success());
        // In case of success close modal
        yield put(actionsApp.setModal(ID.none));
        break;
      case ID.resend_access:
        showMessage('global.actionInProgress', null, ID.loading, ID.key);
        // @ts-ignore
        const result = yield refFireFunction.httpsCallable(
          SPACE_FUNCTIONS.ia_resend_access,
        )({
          uid: uid,
          email: record.email,
          first_name: record.first_name,
          last_name: record.last_name,
          role: record.role,
          language: record.language,
        });
        showMessage('user.resentAccessSuccess', null, ID.success, ID.key);
        yield put(actions.userAction.success(result.data));
        break;
      // Note insert not needed yet
      default:
        // @ts-ignore
        const rx_userAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.id} - Payload: ${JSON.stringify(payload)}`,
        );
    }
  } catch (error) {
    Reporting.Error(error);
    showMessage('global.generalErrorMessage', null, ID.error);
    yield put(actions.userAction.failure());
  } finally {
    yield delay(1);
    yield put(actions.userAction.fulfill());
  }
}

function* voteAction({ payload }: any) {
  const {
    actionName,
    id,
    total_completed_votes,
    filesArray,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isShell,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentUserAllValidRelationshipIds,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentUserFreeRelationshipIds,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dbCollection,
    ...record
  } = payload;
  record.date_start = handleTimeStamps(record.date_start);
  record.date_end = handleTimeStamps(record.date_end);
  const hasNewFiles = filesArray?.some(
    (f: any) =>
      f.size != null || f.originFileObj != null || f.isEdited === true,
  );
  const hasChangedFiles =
    filesArray != null &&
    record.files != null &&
    filesArray.length !== record.files.length;
  let ref; // Needed for insert and copy
  try {
    switch (actionName) {
      case ID.delete:
        // @ts-ignore
        const snapshot = yield call(
          rsfFirestore.getCollection,
          database.collection(DB.ia_votes).doc(id).collection(DB.sub_users),
        );
        const data = snapshotAsArray(snapshot, true);
        const promises = [];
        data.forEach((voteUser) => {
          promises.push(
            database
              .collection(DB.ia_votes)
              .doc(id)
              .collection(DB.sub_users)
              .doc(voteUser.id)
              .delete(),
          );
        });
        promises.push(database.collection(DB.ia_votes).doc(id).delete());
        yield Promise.all(promises);
        yield put(actions.voteAction.success('votes.deleted'));
        break;
      case ID.update:
        if (hasNewFiles || hasChangedFiles) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.voting_files,
            filesArray,
            { ...FileUploadProps, addUid: true },
          );
          showMessage(
            `global.${
              filesArray.length > record.files?.length
                ? 'filesUploaded'
                : 'filesUpdated'
            }`,
            null,
            ID.success,
            ID.key,
          );
          record.files = files;
        } else if (record.files == null || filesArray?.length === 0) {
          record.files = [];
        }
        // @ts-ignore
        yield call(rsfFirestore.setDocument, `${DB.ia_votes}/${id}`, record);
        yield put(actions.voteAction.success('votes.updated'));
        break;
      case ID.eligible_participants:
        yield call(rsfFirestore.updateDocument, `${DB.ia_votes}/${id}`, {
          eligible_participants: record.eligible_participants,
        });
        yield put(actions.voteAction.success('votes.updated'));
        break;
      case ID.copy:
        if (filesArray?.length > 0) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.voting_files,
            filesArray,
            { ...FileUploadProps, addUid: true, isCopy: true },
          );
          showMessage('global.filesUploaded', null, ID.success, ID.key);
          record.files = files;
        } else if (record.files == null || filesArray?.length === 0) {
          record.files = [];
        }
        // Get DocID so we can use it for creating the live_code
        ref = database.collection(DB.ia_votes).doc();
        if (record.step_current === VOTE_STEPS.live.value) {
          record.live_code = predictableIdAndTodayCode(ref.id);
        }
        yield call(
          // @ts-ignore
          rsfFirestore.setDocument,
          `${DB.ia_votes}/${ref.id}`,
          record,
        );
        yield put(actions.voteAction.success('votes.inserted'));
        break;
      case ID.insert:
        if (filesArray?.length > 0) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          // @ts-ignore
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.voting_files,
            filesArray,
            { ...FileUploadProps, addUid: true },
          );
          showMessage('global.filesUploaded', null, ID.success, ID.key);
          record.files = files;
        } else if (record.files == null || filesArray?.length === 0) {
          record.files = [];
        }
        // Get DocID so we can use it for creating the live_code
        ref = database.collection(DB.ia_votes).doc();
        if (record.step_current === VOTE_STEPS.live.value) {
          record.live_code = predictableIdAndTodayCode(ref.id);
        }
        yield call(
          // @ts-ignore
          rsfFirestore.setDocument,
          `${DB.ia_votes}/${ref.id}`,
          record,
        );
        yield put(actions.voteAction.success('votes.inserted'));
        break;
      default:
        // @ts-ignore
        const rx_userAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.id} - Payload: ${JSON.stringify(payload)}`,
        );
    }
    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.voteAction.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.voteAction.fulfill());
  }
}

function* insertVoteItem({ payload }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { actionName, vote_title, id, ...record } = payload;
  // @ts-ignore
  const rx_userAccount = yield select(getUserAccount);

  try {
    // Update real vote
    showMessage('upload.inprogress', null, ID.loading, ID.key);
    // @ts-ignore
    const filesArrayTemp = yield Firebase.getFiles(record.files);
    // @ts-ignore
    const files = yield Firebase.adminFilesToStorageFiles(
      STORAGE.voting_files,
      filesArrayTemp,
      { ...FileUploadProps, addUid: true, isCopy: true },
    );
    showMessage(`global.filesUploaded`, null, ID.success, ID.key);
    const newVoteItem: CVoteItem = {
      id: 'item-' + new Date().getTime(),
      title: record.title,
      description: record.timeline[record.timeline.length - 1].description,
      consider_for_vote: true,
      live_status: VOTEITEM_LIVE_STATUS.available.value,
      property_ids: record.properties.map(
        (p: any) => `${p.mandate_id}-${p.property_id}`,
      ),
      files: files.map((f: any) => f.id),
      quorum: Quorum.simple_majority_noabst.splitted,
      vote_count: VoteCount.head.value,
    };
    yield call(
      rsfFirestore.updateDocument,
      `${DB.ia_votes}/${record.vote_id}`,
      {
        files: Firebase.FieldValue.arrayUnion(...files),
        items: Firebase.FieldValue.arrayUnion(newVoteItem),
      },
    );
    // Connect voteItem with real vote and update traktandum timeline
    const newTimelineEntry: IRepairTimeline = {
      date_creation: handleTimeStamps(null),
      description: `Traktandum wurde der Abstimmung "${vote_title}" hinzugefügt.`, // We should translate this eventually.
      first_name: rx_userAccount.first_name,
      last_name: rx_userAccount.last_name,
      repair_status: REPAIR_STATUS.completed.value,
      uid: rx_userAccount.id,
    };
    record.timeline.unshift(newTimelineEntry);

    // Note: we could inform the user by resetting push_status and email_status of the voteItem.
    yield call(rsfFirestore.updateDocument, `${DB.ia_repairs}/${id}`, {
      vote_id: record.vote_id,
      timeline: record.timeline,
    });
    yield put(actions.insertVoteItem.success());
    showMessage('votes.agendaItemUpdated', null, ID.success);

    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    showMessage('global.generalErrorMessage', null, ID.error);
    yield put(actions.insertVoteItem.failure());
  } finally {
    yield delay(1);
    yield put(actions.insertVoteItem.fulfill());
  }
}

function* voteUserAction({ payload }: any) {
  const { actionName, voteId, userId, mandateId, ...record } = payload;

  try {
    switch (actionName) {
      case ID.delete:
        yield database
          .collection(DB.ia_votes)
          .doc(voteId)
          .collection(DB.sub_users)
          .doc(userId)
          .delete();
        showMessage('votes.voteDeleted', null, ID.success);
        break;
      case ID.update:
        yield database
          .collection(DB.ia_votes)
          .doc(voteId)
          .collection(DB.sub_users)
          .doc(userId)
          .update(record);
        showMessage('votes.userStatusUpdated', null, ID.success);
        break;
      case ID.insert:
        record.vote_updated = Firebase.FieldValue.serverTimestamp();
        if (userId == null) {
          yield database
            .collection(DB.ia_votes)
            .doc(voteId)
            .collection(DB.sub_users)
            .add(record);
        } else {
          yield database
            .collection(DB.ia_votes)
            .doc(voteId)
            .collection(DB.sub_users)
            .doc(userId)
            .set(record);
        }
        showMessage('votes.voteManuallyAddedSuccess', null, ID.success);
        break;
      default:
        // @ts-ignore
        const rx_userAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.id} - Payload: ${JSON.stringify(payload)}`,
        );
    }
    if (mandateId != null) {
      yield put(actions.getAllMandateData(mandateId));
    }
    // In case of success close modal
    yield put(actionsApp.setModal(ID.none_reset));
    yield put(actions.voteUserAction.success());
  } catch (error) {
    Reporting.Error(error);
    showMessage('global.generalErrorMessage', null, ID.error);
    yield put(actions.voteUserAction.failure());
  } finally {
    yield delay(1);
    yield put(actions.voteUserAction.fulfill());
  }
}

// Called only from voteDetails screen
function* updateVote({ payload }: any) {
  const { id, actionName, ...record } = payload;
  try {
    switch (actionName) {
      case ID.vote_status:
        // Update Live-Code
        // - Since this is not super confidential we can do it here
        // - Code will be the same if vote is activated twice in a day (by accident)
        if (record.step_current === VOTE_STEPS.live.value) {
          record.live_code = predictableIdAndTodayCode(id);
        }
        yield call(rsfFirestore.updateDocument, `${DB.ia_votes}/${id}`, record);
        // If date_end is set, the user is finishing the vote
        // We need to call a cloud function to set all live "voting" users to "completed"
        // If date_end is not set, we need to set voteItems completed to false
        yield refFireFunction.httpsCallable(SPACE_FUNCTIONS.vote_update)({
          vote_id: id,
          finish: record.date_end != null,
        });
        yield put(actions.updateVote.success());
        break;
      case ID.update:
        yield call(rsfFirestore.updateDocument, `${DB.ia_votes}/${id}`, record);
        // If a traktandum was set completed, check in cloud function if any user voteStatus needs to be set to completed. Async on purpose.
        refFireFunction.httpsCallable(SPACE_FUNCTIONS.vote_update)({
          vote_id: id,
          finish: false,
        });
        yield put(actions.updateVote.success());
        // In case of success close modal
        yield put(actionsApp.setModal(ID.none));
        break;
      default:
        // @ts-ignore
        const rx_userAccount = yield select(getUserAccount);
        throw new Error(
          `User: ${rx_userAccount.id} - Payload: ${JSON.stringify(payload)}`,
        );
    }
  } catch (error) {
    Reporting.Error(error);
    showMessage('global.generalErrorMessage', null, ID.error);
    yield put(actions.updateVote.failure());
  } finally {
    yield delay(1);
    yield put(actions.updateVote.fulfill());
  }
}

function* importVoteUsers({ payload }: any) {
  const { mandateId, voteId } = payload;
  try {
    // Get users of mandate
    // @ts-ignore
    const snapshot = yield call(
      rsfFirestore.getCollection,
      database
        .collection(DB.ia_users)
        .where(ID.mandate_ids, 'array-contains', mandateId),
    );
    const usersOfMandate = snapshotAsArray(snapshot, true);
    // Create/update voteUser entry for each user
    const promises: any[] = [];
    usersOfMandate.forEach((user) => {
      promises.push(
        database
          .collection(DB.ia_votes)
          .doc(voteId)
          .collection(DB.sub_users)
          .doc(user.id)
          .set(
            {
              // active: user.person_id > -1,
              user: {
                name_first: user.first_name,
                name_last: user.last_name,
                person_id: user.person_id,
                uid: user.id,
              },
            },
            { merge: true },
          ),
      );
    });
    yield Promise.all(promises);
    showMessage('votes.participantsUpdated', null, ID.success);
    yield put(actions.importVoteUsers.success());
  } catch (error) {
    Reporting.Error(error);
    showMessage('global.generalErrorMessage', null, ID.error);
    yield put(actions.importVoteUsers.failure());
  } finally {
    yield delay(1);
    yield put(actions.importVoteUsers.fulfill());
  }
}

function* downloadVoteResults({ payload }: any) {
  try {
    const cloudFunctionUrl = `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/${SPACE_FUNCTIONS.ia_pdf_vote_results_user}`;
    // @ts-ignore
    const idToken = yield getAuthToken();
    if (idToken == null) {
      throw new Error('GetAuthToken returned null.');
    }
    const options: AxiosRequestConfig = {
      method: 'POST',
      url: cloudFunctionUrl,
      data: payload,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      responseType: 'blob',
      timeout: 45000,
    };
    const start = new Date().getTime();
    // @ts-ignore
    const response = yield axios(options);
    console.info('PDF creation time', new Date().getTime() - start);
    yield put(
      actions.downloadVoteResults.success({
        response: response,
        userId: payload.user_id,
        cloudSuccess: true,
      }),
    );
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.downloadVoteResults.failure({
        response: null,
        userId: payload.user_id,
        cloudSuccess: false,
      }),
    );
  } finally {
    yield delay(1);
    yield put(actions.downloadVoteResults.fulfill());
  }
}

function* downloadVoteProtocol({ payload }: any) {
  const { fileType, ...params } = payload;
  try {
    // @ts-ignore
    const idToken = yield getAuthToken();
    if (idToken == null) {
      throw new Error('GetAuthToken returned null.');
    }
    const options: AxiosRequestConfig = {
      method: 'POST',
      url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/${
        fileType === 'pdf'
          ? SPACE_FUNCTIONS.ia_pdf_vote_protocol
          : SPACE_FUNCTIONS.ia_doc_vote_protocol
      }`,
      data: params,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      responseType: 'blob',
      timeout: 45000,
    };
    const start = new Date().getTime();
    // @ts-ignore
    const response = yield axios(options);
    console.info('File creation time', new Date().getTime() - start);
    yield put(actions.downloadVoteProtocol.success(response));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.downloadVoteProtocol.failure(null));
  } finally {
    yield delay(1);
    yield put(actions.downloadVoteProtocol.fulfill());
  }
}

function* updateVoteParticipantQuota({ payload }: any) {
  const {
    objectFullId,
    voteId,
    showSuccessMessage,
    eligibleParticipants,
    ...record
  } = payload;
  try {
    // Update the vote object
    yield call(rsfFirestore.updateDocument, `${DB.ia_votes}/${voteId}`, {
      eligible_participants: eligibleParticipants,
    });
    // We also have to update the object so the updated value can be used in next vote
    if (objectFullId) {
      yield call(
        rsfFirestore.updateDocument,
        `${DB.ia_objects}/${objectFullId}`,
        record,
      );
    }
    yield put(actions.updateVoteParticipantQuota.success());
    if (showSuccessMessage) {
      showMessage('votes.participantUpdated', null, ID.success);
    }
  } catch (error) {
    Reporting.Error(error);
    showMessage('global.generalErrorMessage', null, ID.error);
    yield put(actions.updateVoteParticipantQuota.failure());
  } finally {
    yield delay(1);
    yield put(actions.updateVoteParticipantQuota.fulfill());
  }
}

// TODO: admin sagas should be in admin folder

function* syncDocuments() {
  try {
    yield refFireFunction.httpsCallable(SPACE_FUNCTIONS.gl_sync_documents)({});
    yield put(actions.syncDocuments.success('settings.documentsSynced'));
  } catch (error: any) {
    if (error.code === ERROR_CODE.resource_exhausted) {
      // Do not report, just show warning to user
      yield put(
        actions.syncDocuments.failure('settings.documentsAlreadySyncing'),
      );
    } else {
      Reporting.Error(error);
      yield put(actions.syncDocuments.failure('global.generalErrorMessage'));
    }
  } finally {
    yield delay(1);
    yield put(actions.syncDocuments.fulfill());
    // Get new settings data to update timestamp
    yield put(actions.getSettings());
  }
}

function* getReportReceivers({ payload }: any) {
  const { report, fnId, communication } = payload;
  try {
    // @ts-ignore
    const result = yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.ia_get_report_receivers,
    )({
      fn_id: fnId,
      report: report,
      communication: communication,
    });
    yield put(actions.getReportReceivers.success(result.data));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getReportReceivers.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.getReportReceivers.fulfill());
    // Get new settings data to update timestamp
    yield put(actions.getSettings());
  }
}

function* updateRemoteConfigValue({ payload }: any) {
  const { key, value } = payload;
  try {
    yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.gl_update_remote_config,
    )({
      key: key,
      value: value,
    });
    yield put(actions.updateRemoteConfigValue.success());

    switch (key) {
      case REMOTE_CONFIG.accent_color:
        showMessage('settings.colorUpdated', null, ID.success);
        break;
      case REMOTE_CONFIG.access_comments:
        yield Firebase.updateRemoteConfig();
        yield put(actions.getRemoteConfigAccessComments());
        showMessage('settings.valueUpdated', null, ID.success);
        break;
      case REMOTE_CONFIG.access_pinboard:
        yield Firebase.updateRemoteConfig();
        yield put(actions.getRemoteConfigAccessPinboard());
        showMessage('settings.valueUpdated', null, ID.success);
        break;
      case REMOTE_CONFIG.access_voteitem_submit:
        yield Firebase.updateRemoteConfig();
        yield put(actions.getRemoteConfigAccessVoteItemSubmit());
        showMessage('settings.valueUpdated', null, ID.success);
        break;
      default:
        Reporting.Warning(
          `Unexpected switch value in function updateRemoteConfigValue. ${key}: ${value}.`,
        );
        break;
    }
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.updateRemoteConfigValue.failure('global.generalErrorMessage'),
    );
  } finally {
    yield delay(1);
    yield put(actions.updateRemoteConfigValue.fulfill());
  }
}

function* resetAllData() {
  try {
    yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.gl_reset_data,
      { timeout: 540 * 1000 }, // 540 Seconds, Max timeout available
    )();
    yield put(actions.resetAllData.success('settings.dataResetted'));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.resetAllData.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.resetAllData.fulfill());
    // Get new settings data to update timestamp
    yield put(actions.getSettings());
  }
}

function* createUsersFromPersons({ payload }: any) {
  const { mandateIds, securityQuestionApproved, includeJanitors, language } =
    payload;
  try {
    // @ts-ignore
    const rx_userAccount = yield select(getUserAccount);
    // @ts-ignore
    const result = yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.gl_create_users_from_persons,
      { timeout: 540 * 1000 }, // 540 Seconds, Max timeout available
    )({
      mandateIds: mandateIds,
      securityQuestionApproved: securityQuestionApproved,
      language: language,
      preparationOnly: false,
      max: securityQuestionApproved ? 100 : 99999999,
      includeJanitors: includeJanitors,
      senderEmail: rx_userAccount?.email,
    });
    if (securityQuestionApproved) {
      yield put(
        actions.createUsersFromPersons.success('settings.createUsersSuccess'),
      );
    } else {
      // Request the admin to approve the amount of new accounts that will be created
      yield put(actions.createUsersFromPersons.request(result.data));
    }
  } catch (error: any) {
    // If settings auto_invite_users is disabled, show a warning
    if (error.code === ERROR_CODE.failed_precondition) {
      yield put(actions.createUsersFromPersons.failure(ID.warning));
    } else {
      // Otherwise show an error
      Reporting.Error(error, [
        `mandateIds: ${mandateIds}`,
        `securityQuestionApproved: ${securityQuestionApproved}`,
        `includeJanitors: ${includeJanitors}`,
        `language: ${language}`,
      ]);
      yield put(actions.createUsersFromPersons.failure(ID.error));
    }
  } finally {
    yield delay(1);
    yield put(actions.createUsersFromPersons.fulfill());
    // Get new settings data to update timestamp
    yield put(actions.getSettings());
  }
}

function* forwardEmail({ payload }: any) {
  const {
    receiver_email,
    collection_id,
    document_id,
    mandate_id,
    mandate_name,
    language,
  } = payload;
  try {
    yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.ia_share_report_via_email_v2,
    )({
      show_handyman: true,
      collection: collection_id,
      document_id: document_id,
      receiver_email: receiver_email,
      mandate_id: mandate_id,
      mandate_name: mandate_name,
      language: language,
    });
    showMessage(
      'DASHBOARD.REPORT.SHARE.SUCCESS',
      { email: receiver_email },
      ID.success,
    );
    yield put(actions.forwardEmail.success());
    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error: any) {
    // If settings auto_invite_users is disabled, show a warning
    if (error.code === ERROR_CODE.failed_precondition) {
      yield put(actions.forwardEmail.failure(ID.warning));
    } else {
      // Otherwise show an error
      Reporting.Error(error);
      yield put(actions.forwardEmail.failure(ID.error));
    }
  } finally {
    yield delay(1);
    yield put(actions.forwardEmail.fulfill());
  }
}

function* excelMasterDataImport({ payload }: any) {
  const { fileList } = payload;
  try {
    // Upload excel file
    showMessage(
      'settings.masterDataImportInProgress',
      null,
      ID.loading,
      ID.key,
    );
    yield Firebase.adminFilesToStorageFiles(STORAGE.database_files, fileList, {
      ...FileUploadProps,
      useFileName: STORAGE.masterdata,
    });

    // Run CF to import data
    // @ts-ignore
    const result = yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.gl_excel_import,
      { timeout: 120 * 1000 }, // 120 Seconds
    )();

    if (result.data === true) {
      yield put(actions.excelMasterDataImport.success());
    } else {
      yield put(actions.excelMasterDataImport.failure(result.data));
    }
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.excelMasterDataImport.failure(ID.error));
  } finally {
    yield delay(1);
    yield put(actions.excelMasterDataImport.fulfill());
  }
}

function* getSettings() {
  try {
    // @ts-ignore
    const snapshot = yield call(
      rsfFirestore.getCollection,
      database.collection(DB.settings),
    );
    const data = snapshotAsObject(snapshot);
    yield put(actions.getSettings.success(data));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getSettings.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.getSettings.fulfill());
  }
}

function* setFilemanagerSettings({ payload }: any) {
  try {
    yield call(
      rsfFirestore.updateDocument,
      database.collection(DB.settings).doc(DB.doc_filemanager),
      { [DB.field_default_access_group]: payload },
    );
    yield put(actions.setFilemanagerSettings.success());
    yield put(actions.getFilemanagerSettings());
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.setFilemanagerSettings.failure('global.generalErrorMessage'),
    );
  } finally {
    yield delay(1);
    yield put(actions.setFilemanagerSettings.fulfill());
  }
}

function* getFilemanagerSettings() {
  try {
    // @ts-ignore
    const doc = yield call(
      rsfFirestore.getDocument,
      database.collection(DB.settings).doc(DB.doc_filemanager),
    );
    yield put(actions.getFilemanagerSettings.success(doc.data()));
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.getFilemanagerSettings.failure('global.generalErrorMessage'),
    );
  } finally {
    yield delay(1);
    yield put(actions.getFilemanagerSettings.fulfill());
  }
}

function* sendTestMail({ payload }: any) {
  const { userRole, language } = payload;
  try {
    showMessage('global.actionInProgress', null, ID.loading, ID.key);
    // @ts-ignore
    const rx_userAccount = yield select(getUserAccount);
    if (rx_userAccount == null) {
      Reporting.Error(new Error());
      showMessage('global.generalErrorMessage', null, ID.error, ID.key);
      return;
    }
    yield refFireFunction.httpsCallable(SPACE_FUNCTIONS.gl_email_test)({
      email: rx_userAccount.email,
      user_role: userRole,
      language: language,
    });
    showMessage('settings.inviteEmailSuccess', null, ID.success, ID.key);
    yield put(actions.sendTestMail.success());
  } catch (error) {
    Reporting.Error(error);
    showMessage('global.generalErrorMessage', null, ID.error, ID.key);
    yield put(actions.sendTestMail.failure());
  } finally {
    yield delay(1);
    yield put(actions.sendTestMail.fulfill());
  }
}

function* exampleMandate({ payload }: any) {
  const action = payload;
  try {
    showMessage('global.actionInProgress', null, ID.loading, ID.key);
    yield refFireFunction.httpsCallable(SPACE_FUNCTIONS.gl_example_mandate)({
      action,
    });
    switch (action) {
      case ID.insert:
        showMessage(
          'settings.exampleMandateCreateSuccess',
          null,
          ID.success,
          ID.key,
        );
        break;
      case ID.reset:
        showMessage(
          'settings.exampleMandateResetSuccess',
          null,
          ID.success,
          ID.key,
        );
        break;
      case ID.delete:
        showMessage(
          'settings.exampleMandateDeleteSuccess',
          null,
          ID.success,
          ID.key,
        );
        break;
      default:
        Reporting.Error(
          new Error(
            `Unexpected action for function "exampleMandate": ${action}.`,
          ),
        );
        break;
    }
    yield put(actions.exampleMandate.success());
  } catch (error) {
    Reporting.Error(error);
    showMessage('global.generalErrorMessage', null, ID.error, ID.key);
    yield put(actions.exampleMandate.failure());
  } finally {
    yield delay(1);
    yield put(actions.exampleMandate.fulfill());
  }
}

function* sendExternalPreviewMail({ payload }: any) {
  const { ...record } = payload;
  try {
    if (
      record.mandate_ids == null ||
      record.mandate_ids.length === 0 ||
      record.mandate_data == null
    ) {
      yield put(
        actions.sendExternalPreviewMail.failure(
          PREVIEW_MAIL_STATUS.select_mandates,
        ),
      );
      return;
    }
    // @ts-ignore
    const rx_userAccount = yield select(getUserAccount);
    if (rx_userAccount == null) {
      Reporting.Error(new Error());
      yield put(actions.sendExternalPreviewMail.failure());
      return;
    }
    yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.ia_external_email_preview,
    )({
      uid: rx_userAccount.id,
      email: rx_userAccount.email,
      first_name: rx_userAccount.first_name,
      last_name: rx_userAccount.last_name,
      mandate_id: record.mandate_ids[0],
      mandate_name:
        record.mandate_data[record.mandate_ids[0]] ??
        '[Keine Immobilie vorhanden.]',
      property_street: record.property_street ?? '',
      title: record.title ?? '[Kein Titel vorhanden.]',
      description: record.description ?? '[Keine Beschreibung vorhanden.]',
    });
    yield put(actions.sendExternalPreviewMail.success());
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.sendExternalPreviewMail.failure());
  } finally {
    yield delay(1);
    yield put(actions.sendExternalPreviewMail.fulfill());
  }
}

function* sendHandymanPreviewMail({ payload }: any) {
  const { document_id, handyman } = payload;
  try {
    // @ts-ignore
    const rx_userAccount = yield select(getUserAccount);
    if (rx_userAccount == null) {
      Reporting.Error(new Error());
      yield put(actions.sendHandymanPreviewMail.failure());
      return;
    }
    yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.ia_handyman_email_preview,
    )({
      document_id: document_id,
      handyman: handyman,
      receiver_uid: rx_userAccount.id,
    });
    yield put(actions.sendHandymanPreviewMail.success());
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.sendHandymanPreviewMail.failure());
  } finally {
    yield delay(1);
    yield put(actions.sendHandymanPreviewMail.fulfill());
  }
}

function* getImmoMoveFiles() {
  try {
    // @ts-ignore
    const snapshot = yield call(
      rsfFirestore.getDocument,
      database.collection(DB.im_files_common).doc(DB.doc_files_common),
    );
    const data = snapshot.data().files ? snapshot.data().files : [];
    yield put(actions.getImmoMoveFiles.success(data));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getImmoMoveFiles.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.getImmoMoveFiles.fulfill());
  }
}

function* saveImmoMoveFiles({ payload }: any) {
  try {
    // @ts-ignore
    const files = yield Firebase.adminFilesToStorageFiles(
      STORAGE.immomove_files_common,
      payload,
      { ...FileUploadProps, addUid: true },
    );
    files.map((ele: any) => {
      ele.time_created = handleTimeStamps(ele.time_created);
      ele.time_updated = handleTimeStamps(ele.time_updated);
      return ele;
    });
    yield database
      .collection(DB.im_files_common)
      .doc(DB.doc_files_common)
      .set({ files: files });
    yield put(actions.saveImmoMoveFiles.success('settings.filesCommonUpdated'));
    // Refresh
    yield put(actions.getImmoMoveFiles());
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.saveImmoMoveFiles.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.saveImmoMoveFiles.fulfill());
  }
}

function* getInfocenterFiles() {
  try {
    // @ts-ignore
    const snapshot = yield call(
      rsfFirestore.getDocument,
      database.collection(DB.infocenter).doc(DB.doc_documents),
    );
    const data = snapshot.data()?.data ?? [];
    yield put(actions.getInfocenterFiles.success(data));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getInfocenterFiles.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.getInfocenterFiles.fulfill());
  }
}

function* saveInfocenterFiles({ payload }: any) {
  try {
    // @ts-ignore
    const files = yield Firebase.adminFilesToStorageFiles(
      STORAGE.infocenter_files,
      payload,
      {
        ...FileUploadProps,
      },
    );
    yield database
      .collection(DB.infocenter)
      .doc(DB.doc_documents)
      .set({ data: files });
    yield put(
      actions.saveInfocenterFiles.success('settings.filesCommonUpdated'),
    );
    // Refresh
    yield put(actions.getInfocenterFiles());
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.saveInfocenterFiles.failure('global.generalErrorMessage'),
    );
  } finally {
    yield delay(1);
    yield put(actions.saveInfocenterFiles.fulfill());
  }
}

function* saveInfocenterFAQSections({ payload }: any) {
  const { data, isModal } = payload;

  try {
    yield database
      .collection(DB.infocenter)
      .doc(DB.doc_faqs)
      .set({ data: data });
    yield put(
      actions.saveInfocenterFAQSections.success('INFOCENTER.FAQ.DATA_UPDATED'),
    );
    // In case of success close modal
    if (isModal) {
      yield put(actionsApp.setModal(ID.none));
    }
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.saveInfocenterFAQSections.failure('global.generalErrorMessage'),
    );
  } finally {
    yield delay(1);
    yield put(actions.saveInfocenterFAQSections.fulfill());
  }
}

function* updateSettingsField({ payload }: any) {
  const { key, data } = payload;
  try {
    yield database
      .collection(DB.settings)
      .doc(DB.doc_common_settings)
      .update({ [key]: data });
    yield put(
      actions.updateSettingsField.success('settings.settingsFieldUpdated'),
    );
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.updateSettingsField.failure('global.generalErrorMessage'),
    );
  } finally {
    yield delay(1);
    yield put(actions.updateSettingsField.fulfill());
  }
}

function* getRemoteConfigAccessComments() {
  try {
    yield Firebase.waitUntilRemoteConfigReady();
    const res =
      Firebase.getRemoteConfigString(REMOTE_CONFIG.access_comments) ===
      String(true);
    yield put(actions.getRemoteConfigAccessComments.success(res));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getRemoteConfigAccessComments.failure());
  } finally {
    yield delay(1);
    yield put(actions.getRemoteConfigAccessComments.fulfill());
  }
}

function* getRemoteConfigAccessPinboard() {
  try {
    yield Firebase.waitUntilRemoteConfigReady();
    const res =
      Firebase.getRemoteConfigString(REMOTE_CONFIG.access_pinboard) ===
      String(true);
    yield put(actions.getRemoteConfigAccessPinboard.success(res));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getRemoteConfigAccessPinboard.failure());
  } finally {
    yield delay(1);
    yield put(actions.getRemoteConfigAccessPinboard.fulfill());
  }
}

function* getRemoteConfigAccessVoteItemSubmit() {
  try {
    yield Firebase.waitUntilRemoteConfigReady();
    const res =
      Firebase.getRemoteConfigString(REMOTE_CONFIG.access_voteitem_submit) ===
      String(true);
    yield put(actions.getRemoteConfigAccessVoteItemSubmit.success(res));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getRemoteConfigAccessVoteItemSubmit.failure());
  } finally {
    yield delay(1);
    yield put(actions.getRemoteConfigAccessVoteItemSubmit.fulfill());
  }
}

function* getStatistics() {
  try {
    // @ts-ignore
    const snapshot = yield database.collection(DB.stats).get();
    const data = snapshotAsObject(snapshot);
    yield put(actions.getStatistics.success(data));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getStatistics.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.getStatistics.fulfill());
  }
}

// Cache for reduced read cost
let objectsData: any;
function* getLicenseObjects({ payload }: any) {
  try {
    // @ts-ignore
    const docRef = yield database.collection(DB.stats).doc(DB.doc_license);
    // @ts-ignore
    const doc = yield docRef.get();
    if (payload) {
      // User changed a mandate active/inactive status --> Update license numbers
      // @ts-ignore
      const mandatesActive = (yield database
        .collection(DB.ia_mandates)
        .where('archived', '==', false)
        .get()).docs.map((m: any) => m.data());
      if (objectsData == null) {
        // Cache for reduced read cost
        // @ts-ignore
        objectsData = (yield database.collection(DB.ia_objects).get()).docs.map(
          (m: any) => m.data(),
        );
      }
      const objectsActive = objectsData.filter((o: any) =>
        mandatesActive.find(
          (m: any) =>
            m.mandate_id === o.mandate_id && m.is_example_mandate !== true,
        ),
      );
      yield docRef.update({
        active_objects: objectsActive.length,
      });
      yield put(
        actions.getLicenseObjects.success({
          active_objects: objectsActive.length,
          licensed_objects: doc.data().licensed_objects,
          total_objects: objectsData.length,
        }),
      );
    } else {
      // Document doesn't exist for new spaces until first day gl_scheduler run.
      if (doc.exists) {
        yield put(actions.getLicenseObjects.success(doc.data()));
      }
    }
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getLicenseObjects.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.getLicenseObjects.fulfill());
  }
}

function* getImmoMoveStatistics() {
  try {
    // @ts-ignore
    const snapshot = yield database.collection(DB.im_stats).get();
    const data = snapshotAsObject(snapshot);
    yield put(actions.getImmoMoveStatistics.success(data));
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.getImmoMoveStatistics.failure('global.generalErrorMessage'),
    );
  } finally {
    yield delay(1);
    yield put(actions.getImmoMoveStatistics.fulfill());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.subscribeCommonSettings.TRIGGER, subscribeCommonSettings),
    takeEvery(actions.subscribeUsers.TRIGGER, subscribeUsers),
    takeEvery(actions.subscribeStaff.TRIGGER, subscribeStaff),
    takeEvery(actions.subscribeMandates.TRIGGER, subscribeMandates),
    takeEvery(actions.subscribeProperties.TRIGGER, subscribeProperties),
    takeEvery(actions.subscribeMessages.TRIGGER, subscribeMessages),
    takeEvery(actions.subscribeRepairs.TRIGGER, subscribeRepairs),
    takeEvery(actions.subscribePinboards.TRIGGER, subscribePinboards),
    takeEvery(actions.subscribeVotes.TRIGGER, subscribeVotes),
    takeEvery(actions.subscribeVoteUsers.TRIGGER, subscribeVoteUsers),
    takeEvery(actions.setFetchTimeLimit.TRIGGER, setFetchTimeLimit),
    takeEvery(actions.setFolderMetaAccess.TRIGGER, setFolderMetaAccess),
    takeEvery(actions.getFolderContent.TRIGGER, getFolderContent),
    takeEvery(actions.getAllMandateData.TRIGGER, getAllMandateData),
    takeEvery(actions.getMandateProperties.TRIGGER, getMandateProperties),
    takeEvery(actions.getRootFolders.TRIGGER, getRootFolders),
    takeEvery(
      actions.searchMandateUsersByLastNameOrEmail.TRIGGER,
      searchMandateUsersByLastNameOrEmail,
    ),
    takeEvery(actions.getEmailDeliveryDetails.TRIGGER, getEmailDeliveryDetails),
    takeEvery(actions.getPerson.TRIGGER, getPerson),
    takeEvery(actions.setPersonInvite.TRIGGER, setPersonInvite),
    takeEvery(actions.staffAction.TRIGGER, staffAction),
    takeEvery(actions.reservationAction.TRIGGER, reservationAction),
    takeEvery(actions.mandateAction.TRIGGER, mandateAction),
    takeEvery(actions.messageAction.TRIGGER, messageAction),
    takeEvery(actions.repairAction.TRIGGER, repairAction),
    takeEvery(actions.pinboardAction.TRIGGER, pinboardAction),
    takeEvery(actions.userAction.TRIGGER, userAction),
    takeEvery(actions.voteAction.TRIGGER, voteAction),
    takeEvery(actions.insertVoteItem.TRIGGER, insertVoteItem),
    takeEvery(actions.updateVote.TRIGGER, updateVote),
    takeEvery(actions.voteUserAction.TRIGGER, voteUserAction),
    takeEvery(actions.importVoteUsers.TRIGGER, importVoteUsers),
    takeEvery(actions.downloadVoteResults.TRIGGER, downloadVoteResults),
    takeEvery(actions.downloadVoteProtocol.TRIGGER, downloadVoteProtocol),
    takeEvery(
      actions.updateVoteParticipantQuota.TRIGGER,
      updateVoteParticipantQuota,
    ),
    takeEvery(actions.syncDocuments.TRIGGER, syncDocuments),
    takeEvery(actions.getSettings.TRIGGER, getSettings),
    takeEvery(actions.setFilemanagerSettings.TRIGGER, setFilemanagerSettings),
    takeEvery(actions.getFilemanagerSettings.TRIGGER, getFilemanagerSettings),
    takeEvery(actions.sendTestMail.TRIGGER, sendTestMail),
    takeEvery(actions.exampleMandate.TRIGGER, exampleMandate),
    takeEvery(actions.sendExternalPreviewMail.TRIGGER, sendExternalPreviewMail),
    takeEvery(actions.sendHandymanPreviewMail.TRIGGER, sendHandymanPreviewMail),
    takeEvery(actions.getReportReceivers.TRIGGER, getReportReceivers),
    takeEvery(actions.updateRemoteConfigValue.TRIGGER, updateRemoteConfigValue),
    takeEvery(actions.resetAllData.TRIGGER, resetAllData),
    takeEvery(actions.createUsersFromPersons.TRIGGER, createUsersFromPersons),
    takeEvery(actions.forwardEmail.TRIGGER, forwardEmail),
    takeEvery(actions.excelMasterDataImport.TRIGGER, excelMasterDataImport),
    takeEvery(actions.getImmoMoveFiles.TRIGGER, getImmoMoveFiles),
    takeEvery(actions.saveImmoMoveFiles.TRIGGER, saveImmoMoveFiles),
    takeEvery(actions.getInfocenterFiles.TRIGGER, getInfocenterFiles),
    takeEvery(actions.saveInfocenterFiles.TRIGGER, saveInfocenterFiles),
    takeEvery(actions.subscribeFaqs.TRIGGER, subscribeFaqs),
    takeEvery(
      actions.saveInfocenterFAQSections.TRIGGER,
      saveInfocenterFAQSections,
    ),
    takeEvery(actions.updateSettingsField.TRIGGER, updateSettingsField),
    takeEvery(
      actions.getRemoteConfigAccessComments.TRIGGER,
      getRemoteConfigAccessComments,
    ),
    takeEvery(
      actions.getRemoteConfigAccessPinboard.TRIGGER,
      getRemoteConfigAccessPinboard,
    ),
    takeEvery(
      actions.getRemoteConfigAccessVoteItemSubmit.TRIGGER,
      getRemoteConfigAccessVoteItemSubmit,
    ),
    takeEvery(actions.getStatistics.TRIGGER, getStatistics),
    takeEvery(actions.getLicenseObjects.TRIGGER, getLicenseObjects),
    takeEvery(actions.getImmoMoveStatistics.TRIGGER, getImmoMoveStatistics),
  ]);
}
