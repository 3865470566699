export const TRANSLATIONS_FR = {
  'GLOBAL.ENGLISH': 'Anglais',
  'GLOBAL.GERMAN': 'Allemand',
  'GLOBAL.FRENCH': 'Français',
  'GLOBAL.ITALIAN': 'Italien',
  'GLOBAL.STEP_X_OF_Y': 'Étape {{current}} sur {{total}}',
  'GLOBAL.PUSH_NOTIFICATION': 'Message push',
  'GLOBAL.MESSAGE': 'Message',
  'GLOBAL.PHONE': 'Numéro de téléphone',
  'GLOBAL.TITLE': 'Titre',
  'GLOBAL.EMAIL': 'E-mail',
  'GLOBAL.PASSWORD': 'Mot de passe',
  'GLOBAL.SAVE': 'Sauvegarder',
  'GLOBAL.DESCRIPTION': 'Description',
  'GLOBAL.PERIOD': 'Période',
  'GLOBAL.ERROR': 'Erreur',
  'GLOBAL.ERROR.EMAIL_EXISTS': "L'adresse e-mail saisie est déjà utilisée.",
  'GLOBAL.TRANSLATION_MISSING': 'Traduction manquante',
  'GLOBAL.SUCCESS': 'Succès',
  'GLOBAL.LOADING': 'Chargement...',
  'GLOBAL.LOADING_LOGIN': 'Connexion...',
  'GLOBAL.MAX_100_CHARS': 'Un maximum de 100 caractères est autorisé.',
  'GLOBAL.YES': 'Oui',
  'GLOBAL.NO': 'Non',
  'GLOBAL.ACCEPT': 'Accepter',
  'GLOBAL.LATER': 'Plus tard',
  'GLOBAL.ABSTAIN': 'Abstention',
  'GLOBAL.CANCEL': 'Annuler',
  'GLOBAL.OK': 'OK',
  'GLOBAL.UPDATE': 'Mise à jour',
  'GLOBAL.UPDATING': 'Mise à jour...',
  'GLOBAL.PROPERTY': 'Propriété',
  'GLOBAL.MANDATE': 'Immeuble',
  'GLOBAL.MANDATES': 'Immobilier',
  'GLOBAL.EXTERNAL': 'Externe',
  'GLOBAL.NAME': 'Nom',
  'GLOBAL.FIRSTNAME': 'Prénom',
  'GLOBAL.LASTNAME': 'Nom de famille',
  'GLOBAL.NO_ENTRIES': 'Aucune entrée disponible.',
  'GLOBAL.NO_MORE_ENTRIES': "Il n'y a plus d'entrée disponible.",
  'GLOBAL.PRIVATE': 'Privé',
  'GLOBAL.PRIVATE_E': 'Privé',
  'GLOBAL.ATTACHMENTS': 'Pièces jointes',
  'GLOBAL.UNDO': 'Annuler',
  'GLOBAL.FROM': 'De',
  'GLOBAL.NEXT': 'Suivant',
  'GLOBAL.EMAIL_PLACEHOLDER': 'Veuillez saisir votre adresse mail...',
  'GLOBAL.EMAIL_REQUIRED': "L'adresse mail est requise.",
  'GLOBAL.EMAIL_INVALID': 'Saisissez une adresse mail valide.',
  'GLOBAL.FIELD_REQUIRED': 'Champ requis.',
  'GLOBAL.FIELD_MAX_LENGTH': 'Le texte peut contenir au maximum {{length}} caractères.',
  'GLOBAL.FIELD_MIN_NUMBER': 'Minimum : {{number}}',
  'GLOBAL.FIELD_MAX_NUMBER': 'Maximum: {{number}}',
  'GLOBAL.ONLY_NUMBER': 'Veuillez entrer un numéro.',
  'GLOBAL.ONLY_NUMBERS': 'Veuillez saisir uniquement des chiffres.',
  'GLOBAL.RESET': 'Réinitialiser',
  'GLOBAL.STREET': 'Rue',
  'GLOBAL.ZIP': 'ZIP',
  'GLOBAL.CITY': 'Ville',
  'GLOBAL.OPTIONAL': '(facultatif)',
  'GLOBAL.CHOOSE_NOW': 'Sélectionnez maintenant',
  'GLOBAL.CONTACTDATA': 'Détails du contact',
  'GLOBAL.VISIBILITY': 'Visibilité',
  'GLOBAL.COMMENT': 'Commentaire',
  'GLOBAL.PUBLIC': 'Public',
  'GLOBAL.SENDER': 'Expéditeur',
  'GLOBAL.LOCATION': 'Emplacement',
  'GLOBAL.ADMINISTRATION': 'Administration',
  'GLOBAL.LINK': 'Lien',
  'GLOBAL.INTRODUCTION': 'Introduction',
  'GLOBAL.IN': 'Dans',
  'GLOBAL.QUESTIONS': 'Des questions?',
  'GLOBAL.CODE': 'Code',
  'GLOBAL.TO': 'À',
  'GLOBAL.FOR': 'Pour',
  'GLOBAL.RESERVATION': 'Réservation',
  'GLOBAL.COMMON_SPACE': 'Espace commun',
  'GLOBAL.COMMON_SPACES': 'Espaces communs',
  'GLOBAL.PERSONALLY_FOR_YOU': 'Personnellement pour vous',
  'GLOBAL.FROM_THE_IMMOAPP': "de l'ImmoApp",
  'GLOBAL.START': 'Début',
  'GLOBAL.HERE': 'Ici',
  'GLOBAL.PARTIES': 'Des soirées',
  'GLOBAL.THE': "l'",
  'GLOBAL.RESTRICTED.COMMENT': "Vous n'êtes actuellement pas autorisé à saisir des commentaires. Si vous avez des questions, veuillez contacter votre administration.",
  'GLOBAL.RESTRICTED.REPORT': "Vous n'êtes actuellement pas autorisé à enregistrer des rapports. Si vous avez des questions, veuillez contacter votre administration.",
  'GLOBAL.REMINDER': 'Rappel',
  'GLOBAL.HANDYMAN.RESPONSIBLE': 'Bricoleur responsable',

  'ERROR.UNEXPECTED_RETRY_WITH_EXPLANATION': "Une erreur inattendue s'est produite: {{error}}. Veuillez réessayer.",
  'ERROR.UNEXPECTED_RETRY_SIMPLE': "Une erreur inattendue s'est produite. Veuillez réessayer.",

  'DATE.ANGULAR.SHORT': 'd. LLL',
  'DATE.ANGULAR.LONG': "dd.MM.YYYY 'à' HH:mm",
  'DATE.ANGULAR.FULL': "dd. MMMM YYYY 'à' HH:mm",
  'DATE.ANGULAR.HOUR': "HH 'heures'",
  'DATE.DAYJS.SHORT': 'D. MMM',
  'DATE.DAYJS.LONG': 'DD.MM.YYYY [à] HH:mm',
  'DATE.DAYJS.FULL': 'DD. MMMM YYYY [à] HH:mm',
  'DATE.DAYJS.HOUR': 'HH [heures]',

  'ACCOUNT_ROLE.TENANT_RESTRICTED': 'Locataire (limité)',
  'ACCOUNT_ROLE.TENANT': 'Locataire',
  'ACCOUNT_ROLE.OWNER': 'Propriétaire',
  'ACCOUNT_ROLE.HANDYMAN': 'Bricoleur',
  'ACCOUNT_ROLE.JANITOR': 'Concierge',
  'ACCOUNT_ROLE.MANAGER': 'Gestionnaire',
  'ACCOUNT_ROLE.ADMIN': 'Gestionnaire (Admin)',

  'APP.WEB_PUSH_NA.TEXT': "Malheureusement, votre navigateur ne prend pas en charge les notifications push. Afin d'être toujours bien informé des dernières nouvelles, nous vous recommandons de télécharger l'application iOS ou Android.",
  'APP.WEB_PUSH_NA.BUTTON.HIDE': 'Masquer',
  'APP.UPDATE.AVAILABLE': "Mise à jour de l'application disponible",
  'APP.UPDATE.UPDATE_NOW_QUESTION': 'Souhaitez-vous mettre à jour la dernière version maintenant?',
  'APP.UPDATE.LOADING': "Nouvelle version de l'app en cours de chargement...",
  'APP.UPDATE.INSTALLING': "La nouvelle version de l'app est installée...",
  'APP.NO_INTERNET_CONNECTION.TEXT': 'Pas de connexion Internet.',
  'APP.BACKBUTTON_EXIT_WARNING': 'Appuyez à nouveau sur le bouton de retour pour quitter ImmoApp.',
  'APP.UPDATE.NEEDED.HEADER': 'Mise à jour requise',
  'APP.UPDATE.NEEDED.MESSAGE': "Une mise à jour de sécurité importante est disponible. Pour continuer à utiliser l'ImmoApp, veuillez mettre à jour vers la dernière version.",
  'APP.UPDATE.NEEDED.BUTTON': 'Mettre à jour maintenant',

  'TABS.HOME': 'Accueil',
  'TABS.DOCUMENTS': 'Documents',
  'TABS.VOTES': 'Votes',
  'TABS.PROFILE': 'Profil',

  'REPAIR.STATUS_0': 'En attente',
  'REPAIR.STATUS_1': 'En cours',
  'REPAIR.STATUS_2': 'Terminé',

  'RESERVATION.STATUS_0': 'En attente',
  'RESERVATION.STATUS_1': 'En cours',
  'RESERVATION.STATUS_2': 'Confirmé',

  'AUTHENTICATION.LOGIN': 'Se connecter',
  'AUTHENTICATION.FORGOT_PASSWORD': 'Mot de passe oublié?',
  'AUTHENTICATION.REGISTER': "S'inscrire",
  'AUTHENTICATION.NO_ACCOUNT': 'Pas encore de compte?',
  'AUTHENTICATION.INSTRUCTIONS': 'Veuillez remplir les informations de votre compte.',
  'AUTHENTICATION.PASSWORD_PLACEHOLDER': 'Veuillez saisir le mot de passe...',
  'AUTHENTICATION.PASSWORD_REQUIRED': 'Le mot de passe est requis.',
  'AUTHENTICATION.PASSWORD_LENGTH': 'Le mot de passe doit comporter au moins 6 caractères.',
  'AUTHENTICATION.PASSWORDS_NOT_THE_SAME': 'Les deux mots de passe ne sont pas identiques.',
  'AUTHENTICATION.WRONG_PASSWORD': 'Le mot de passe saisi est incorrect. Veuillez réessayer.',
  'AUTHENTICATION.INVALID_CREDENTIAL': 'Identifiants non valides. Veuillez réessayer.',
  'AUTHENTICATION.TOO_MANY_REQUEST': "Trop de tentatives de connexion infructueuses. L'accès à votre compte a été bloqué pendant une courte période. Veuillez réessayer plus tard.",
  'AUTHENTICATION.EMAIL_NOT_VERIFIED': "Votre adresse mail n'a pas encore été vérifiée. Des instructions ont été envoyées à votre adresse e-mail.",
  'AUTHENTICATION.USER_NOT_FOUND': "Nous n'avons pas trouvé d'utilisateur avec cette adresse e-mail. Veuillez réessayer.",
  'AUTHENTICATION.NO_RIGHTS': "Votre utilisateur n'a pas de droits d'accès. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter votre administration.",
  'AUTHENTICATION.SELECT_SPACE_FIRST': "Veuillez d'abord sélectionner une administration.",
  'AUTHENTICATION.SIGN_IN_FAILED': 'Échec de la connexion.',
  'AUTHENTICATION.RESET_PASSWORD_FAILED': 'La réinitialisation du mot de passe a échoué.',
  'AUTHENTICATION.NO_SPACES_AVAILABLE': "L'adresse mail que vous avez saisie n'est pas attribuée à une administration.",
  'AUTHENTICATION.OTHER_ERROR': 'Échec de la connexion. Veuillez réessayer.',
  'AUTHENTICATION.SELECT_ADMINISTRATION': "Sélectionner l'administration",
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.HEADER': 'ImmoApp version 2',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.MESSAGE': 'L’application ImmoApp a été mise à jour. Pour des raisons de sécurité, nous vous demandons de saisir un nouveau mot de passe.',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.NEW_PASSWORD': 'Nouveau mot de passe...',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.NEW_PASSWORD_REPEAT': 'Répéter le nouveau mot de passe...',

  'AUTHENTICATION.SIGNUP.STEP_1_TITLE': 'Information',
  'AUTHENTICATION.SIGNUP.STEP_2_TITLE': "L’adresse mail & code d'accès",
  'AUTHENTICATION.SIGNUP.STEP_3_TITLE': 'Nom & mot de passe',
  'AUTHENTICATION.SIGNUP.STEP_1_DESCRIPTION': "Si vous avez déjà reçu vos données d'accès personnelles de votre administration, vous pouvez vous connecter directement à l'écran d'accueil. Dans ce cas, un enregistrement n'est pas nécessaire.",
  'AUTHENTICATION.SIGNUP.STEP_2_DESCRIPTION': "Veuillez indiquer votre e-mail et le code d'accès à 6 chiffres que vous avez reçu de votre administration.",
  'AUTHENTICATION.SIGNUP.STEP_3_DESCRIPTION': 'Veuillez indiquer votre nom et un mot de passe que vous avez choisi.',
  'AUTHENTICATION.SIGNUP.STEP_4_DESCRIPTION_A': 'Vous vous êtes connecté avec succès. Nous vous remercions pour votre inscription.',
  'AUTHENTICATION.SIGNUP.STEP_4_DESCRIPTION_B': 'Veuillez confirmer votre adresse mail. Un e-mail de vérification vous a été envoyé à votre adresse mail.',
  'AUTHENTICATION.SIGNUP.STEP_4_DESCRIPTION_C': "Remarque: L'administration vérifie d'abord votre compte avant de vous donner accès à l'ImmoApp. Vous serez informé de l'activation de votre compte par e-mail.",
  'AUTHENTICATION.SIGNUP.FINISH': 'Terminé',
  'AUTHENTICATION.SIGNUP.TERMS': "J'accepte par la présente les dispositions relatives à la protection des données.",
  'AUTHENTICATION.SIGNUP.ERROR.INVITE_CODE_REQUIRED': "Code d'accès requis.",
  'AUTHENTICATION.SIGNUP.ERROR.FIRST_NAME_REQUIRED': 'Le prénom est requis.',
  'AUTHENTICATION.SIGNUP.ERROR.LAST_NAME_REQUIRED': 'Le nom de famille est requis.',
  'AUTHENTICATION.SIGNUP.ERROR.INVITE_CODE_INVALID': "Le code d'accès saisi n'est pas valide. Veuillez réessayer ou contacter votre administration.",
  'AUTHENTICATION.SIGNUP.ERROR.DOMAIN_INVALID': "Le domaine de votre adresse e-mail ({{domain}}) n'a pas pu être vérifié. Veuillez faire attention aux fautes de frappe et réessayer. Si l'erreur persiste, veuillez contacter votre administration.",
  'AUTHENTICATION.SIGNUP.INVITE_CODE_PLACEHOLDER': "Code d'accès",
  'AUTHENTICATION.INVITE_CODE_LENGTH': "Le code d'accès doit comporter 6 caractères.",

  'AUTHENTICATION.RECOVER_PASSWORD.TITLE': 'Réinitialisez votre mot de passe',
  'AUTHENTICATION.RECOVER_PASSWORD.DESCRIPTION': 'Veuillez saisir votre adresse e-mail et nous vous enverrons un e-mail pour réinitialiser votre mot de passe.',
  'AUTHENTICATION.RECOVER_PASSWORD.BUTTON': 'Réinitialiser le mot de passe',
  'AUTHENTICATION.RECOVER_PASSWORD.SUCCESS_MESSAGE': 'Un e-mail contenant des instructions vous a été envoyé avec succès.',

  'PROFILE.TITLE': 'Profil',
  'PROFILE.TENANT.TITLE': 'Locataires',
  'PROFILE.TENANT.TITLE_LONG': 'Administration du locataires',
  'PROFILE.TENANT.ADMINISTER_TITLE': 'Gestion des locataires',
  'PROFILE.TENANT.ADMINISTER_DESCRIPTION': 'locataires',
  'PROFILE.TENANT.INVITED_ON': 'Invité le',
  'PROFILE.TENANT.NO_TENANTS_AVAILABLE': 'Vous ne gérez actuellement aucun locataire.',
  'PROFILE.TENANT.ADD.TITLE': 'Ajouter un locataire',
  'PROFILE.TENANT.ADD.MANDATE.PLACEHOLDER': 'Veuillez sélectionner une propriété',
  'PROFILE.TENANT.ADD.FIRSTNAME': 'Prénom',
  'PROFILE.TENANT.ADD.FIRSTNAME.PLACEHOLDER': 'Prénom',
  'PROFILE.TENANT.ADD.LASTNAME': 'Nom de famille',
  'PROFILE.TENANT.ADD.LASTNAME.PLACEHOLDER': 'Nom de famille',
  'PROFILE.TENANT.ADD.EMAIL_REQUIRED': 'L’adresse mail est requise.',
  'PROFILE.TENANT.ADD.EMAIL_INVALID': 'Entrez un e-mail valide.',
  'PROFILE.TENANT.ADD.MANDATE_REQUIRED': "L'immeuble est nécessaire.",
  'PROFILE.TENANT.ADD.FIRSTNAME_REQUIRED': 'Le prénom est requis.',
  'PROFILE.TENANT.ADD.LASTNAME_REQUIRED': 'Le nom de famille est requis.',
  'PROFILE.TENANT.ADD.LOADING_MESSAGE': 'Le locataire est créé...',
  'PROFILE.TENANT.ADD.SUCCESS': 'Le locataire a été créé avec succès.',
  'PROFILE.TENANT.ADD.ERROR.EXISTING_EMAIL': "L'adresse mail saisie est déjà attribuée à un autre compte.",

  'PROFILE.TENANT.EDIT.TITLE': 'Modifier un locataire',
  'PROFILE.TENANT.EDIT.MESSAGE': "Ici, vous pouvez renvoyer à votre locataire les données d'accès et mettre fin à la location (et donc à l'accès à l'ImmoApp).",
  'PROFILE.TENANT.EDIT.TERMINATE': 'Résilier le bail',
  'PROFILE.TENANT.EDIT.TERMINATE.LOADING_MESSAGE': 'Résilier le bail...',
  'PROFILE.TENANT.EDIT.TERMINATE.SUCCESS': "Le locataire a été supprimé avec succès et ne possède désormais plus d'accès à ImmoApp.",
  'PROFILE.TENANT.EDIT.RESEND_ACCESS': "Renvoyer l'accès",
  'PROFILE.TENANT.EDIT.RESEND_ACCESS.LOADING_MESSAGE': "L'accès est envoyé...",
  'PROFILE.TENANT.EDIT.RESEND_ACCESS.SUCCESS': "Les données d'accès ont été envoyées avec succès à l'adresse e-mail du locataire.",
  'PROFILE.INFO.TITLE': 'Infos',
  'PROFILE.INFO.YOUR_DATA_TITLE': 'Vos données',
  'PROFILE.INFO.YOUR_DATA_DESCRIPTION': 'Connecté en tant que',
  'PROFILE.INFO.TERMS_OF_USE_TITLE': "Conditions d'utilisation",
  'PROFILE.INFO.TERMS_OF_USE_DESCRIPTION': "Règles pour l'ImmoApp",
  'PROFILE.INFO.INSTRUCTION_TITLE': 'Instructions',
  'PROFILE.INFO.INSTRUCTION_GENERATING': 'Les instructions sont générées...',
  'PROFILE.INFO.INSTRUCTION_DESCRIPTION': "Entrer dans l'ImmoApp",
  'PROFILE.INFO.CONTACT_TITLE': 'Contact',
  'PROFILE.INFO.CONTACT_DESCRIPTION': "Contacter directement l'administration",
  'PROFILE.INFO.LEGAL_TITLE': 'Informations légales',
  'PROFILE.INFO.LEGAL_DESCRIPTION': 'Politique de confidentialité',
  'PROFILE.SETTINGS.TITLE': 'Paramètres',
  'PROFILE.SETTINGS.ACCOUNT_TITLE': 'Paramètres du compte',
  'PROFILE.SETTINGS.ACCOUNT_DESCRIPTION': 'Adresse mail, mot de passe & suppression',
  'PROFILE.SETTINGS.APP_TITLE': "Paramètres de l'application",
  'PROFILE.SETTINGS.APP_DESCRIPTION': 'Langue & notifications',
  'PROFILE.SETTINGS.DELETE_ACCOUNT': 'Supprimer le compte',
  'PROFILE.SETTINGS.DELETE_ACCOUNT.CONFIRM': 'Si vous confirmez par OK, votre compte sera supprimé.',
  'PROFILE.SETTINGS.DELETE_ACCOUNT.SUCCESS': 'Votre compte ImmoApp a été supprimé avec succès. Une confirmation vous a été envoyée par email.',
  'PROFILE.SETTINGS.LOGOUT': 'Se déconnecter',
  'PROFILE.SETTINGS.LOGOUT.CONFIRM': 'Voulez-vous vous déconnecter?',
  'PROFILE.SETTINGS.LOGOUT.IN_PROGRESS': 'Vous allez être déconnecté. Veuillez patienter un instant.',
  'PROFILE.UPDATE_PHOTO.TITLE': 'Modifier la photo',
  'PROFILE.UPDATE_PHOTO_SUCCESS': 'Votre photo a été mise à jour avec succès.',

  'PROFILE.APP_SETTINGS.SELECT_LANGUAGE': 'Sélectionner la langue',
  'PROFILE.APP_SETTINGS.PUSH_NOTIFICATIONS': 'Notifications push',
  'PROFILE.APP_SETTINGS.EMAIL_NOTIFICATIONS': 'Notifications par e-mail',
  'PROFILE.APP_SETTINGS.LANGUAGE': 'Langue',

  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.DOCUMENT.TITLE': 'Documents',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.DOCUMENT.DESCRIPTION': 'Documents récemment ajoutés',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.MANDATE.TITLE': 'Actualités',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.MANDATE.DESCRIPTION': 'Actualités et déclarations de sinistre',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.PINBOARD.TITLE': "Tableau d'affichage",
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.PINBOARD.DESCRIPTION': 'Nouvelles de vos voisins',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.VOTE.TITLE': 'Votes',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.VOTE.DESCRIPTION': 'Mises à jour et rappels',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.STAFF.TITLE': 'Inscription (uniquement pour les employés)',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.STAFF.DESCRIPTION': "Notification d'une nouvelle inscription",
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.RESERVATION.TITLE': 'Réservations',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.RESERVATION.DESCRIPTION': 'Mises à jour et rappels',

  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.MESSAGE.TITLE': 'Messages',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.MESSAGE.DESCRIPTION': 'Recevoir un e-mail pour les nouveaux messages',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR.TITLE': 'Nouveaux billets',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR.DESCRIPTION': 'Recevoir un e-mail pour un nouveau billet',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR_UPDATE.TITLE': 'Mises à jour des billets',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR_UPDATE.DESCRIPTION': 'Recevoir un e-mail pour le nouveau statut du ticket',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.PINBOARD.TITLE': "Entrées du tableau d'affichage",
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.PINBOARD.DESCRIPTION': "Recevoir un e-mail lors de la création d'une nouvelle entrée sur le tableau d'affichage",
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.COMMENT.TITLE': 'Commentaires',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.COMMENT.DESCRIPTION': 'Recevoir un e-mail pour un nouveau commentaire',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.VOTEITEM.TITLE': "Vote point de l'ordre du jour (uniquement pour les collaborateurs)",
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.VOTEITEM.DESCRIPTION': "Recevoir un e-mail lors d'un nouveau point à l'ordre du jour",
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.RESERVATION.TITLE': 'Réservations',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.RESERVATION.DESCRIPTION': 'Recevoir un email pour une nouvelle réservation',

  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD': 'Le mot de passe a été mis à jour avec succès.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL': "L'adresse mail a été mise à jour avec succès. Pour des raisons de sécurité, vous devez vous reconnecter.",
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_TITLE': 'Modifier le mot de passe',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_TITLE': "Modifier l'adresse mail",
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_ERP_NOTICE1': 'Votre adresse mail doit être modifiée dans le système de votre administration.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_ERP_NOTICE2': 'Cliquez ici pour contacter votre administration par e-mail.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_PLACEHOLDER': 'Saisissez un nouveau mot de passe...',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_PLACEHOLDER': 'Entrez une nouvelle adresse e-mail...',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_INPUT': 'Nouveau adresse e-mail',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_REPEATED': 'Veuillez saisir une nouvelle adresse e-mail.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_REPEATED': 'Veuillez saisir un nouveau mot de passe.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_INPUT': 'Nouveau mot de passe',
  'PROFILE.ACCOUNT_SETTINGS.CURRENT_PASSWORD_PLACEHOLDER': 'Entrez votre mot de passe actuel...',
  'PROFILE.ACCOUNT_SETTINGS.CURRENT_PASSWORD_TITLE': 'Mot de passe actuel',
  'PROFILE.ACCOUNT_SETTINGS.CURRENT_PASSWORD_NOTE': "Afin d'éviter tout abus, nous vous demandons d'abord de saisir à nouveau votre mot de passe avant de pouvoir effectuer les modifications.",

  'PROFILE.CONTACT.TITLE': 'Contact',
  'PROFILE.CONTACT.DEVELOPMENT': 'Développé par',

  'PROFILE.PERSON_DATA.STATUS': 'Statut',
  'PROFILE.PERSON_DATA.POST_BOX': 'Boîte postale',
  'PROFILE.PERSON_DATA.PRIVATE_PHONE': 'Tél. (privé)',
  'PROFILE.PERSON_DATA.MOBILE_PHONE': 'Tél. (portable)',
  'PROFILE.PERSON_DATA.WORK_PHONE': 'Tél. (travail)',

  'PROFILE.TERMS_OF_USE.TITLE': "Conditions d'utilisation de l'ImmoApp",
  'PROFILE.TERMS_OF_USE.SUBTITLE': 'Ensemble pour une expérience App respectueuse et positive',
  'PROFILE.TERMS_OF_USE.INTRO_TITLE': "Ce que nous attendons de l'utilisation de l'ImmoApp",
  'PROFILE.TERMS_OF_USE.INTRO_TEXT1': "Afin de nous assurer que tout le monde se sente bienvenu et à l'aise dans notre communauté, nous avons défini quelques règles de base pour ImmoApp. Ces règles reflètent nos valeurs communes et constituent la base d'une expérience App respectueuse et positive.",
  'PROFILE.TERMS_OF_USE.INTRO_TEXT2': 'Nous demandons à tous les utilisateurs de respecter les directives suivantes:',
  'PROFILE.TERMS_OF_USE.LIST_1_TITLE': 'Publication de données personnelles',
  'PROFILE.TERMS_OF_USE.LIST_1_TEXT': "Les informations personnelles qui pourraient permettre d'identifier une personne privée ne peuvent être publiées qu'avec le consentement explicite de la personne concernée. Cela s'applique également aux images/vidéos de groupes de personnes ou de personnes/plaques d'immatriculation aléatoires en arrière-plan.",
  'PROFILE.TERMS_OF_USE.LIST_2_TITLE': 'Harcèlement et/ou incitation à la violence',
  'PROFILE.TERMS_OF_USE.LIST_2_TEXT': "Toute forme de harcèlement, de menace ou d'incitation à la violence contre des individus ou des groupes de personnes est interdite dans l'ImmoApp.",
  'PROFILE.TERMS_OF_USE.LIST_3_TITLE': 'Discrimination',
  'PROFILE.TERMS_OF_USE.LIST_3_TEXT': "Nous ne tolérons aucune discrimination ni aucun discours de haine pour quelque raison que ce soit. Tout le monde est le bienvenu dans notre communauté et est accepté pour ce qu'il/elle est. Exprimez vos opinions et vos expériences de manière constructive, sans insulter les autres, ni faire de généralisations inutiles ou de provocation.",
  'PROFILE.TERMS_OF_USE.LIST_4_TITLE': 'Comportement perturbateur',
  'PROFILE.TERMS_OF_USE.LIST_4_TEXT': "La publication répétée de contenus identiques ou similaires, les réponses inutilement désagréables ou le fait de perturber délibérément l'expérience ImmoApp des autres utilisateurs ne sont pas autorisés.",
  'PROFILE.TERMS_OF_USE.LIST_5_TITLE': 'Comportement sexuel importun',
  'PROFILE.TERMS_OF_USE.LIST_5_TEXT': "Les comportements sexuels intrusifs ou l'offre de services sexuels ne sont pas tolérés. Traitez toujours les autres utilisateurs avec respect et dignité.",
  'PROFILE.TERMS_OF_USE.LIST_6_TITLE': 'Contenu violent et/ou explicitement sexuel',
  'PROFILE.TERMS_OF_USE.LIST_6_TEXT': "Tout type de contenu drastiquement violent et/ou explicitement sexuel (sous forme écrite ou visuelle) n'est pas autorisé. Les images de personnes sont autorisées à condition que toutes les parties intimes du corps/sexe soient couvertes.",
  'PROFILE.TERMS_OF_USE.LIST_7_TITLE': 'Contenus de tiers',
  'PROFILE.TERMS_OF_USE.LIST_7_TEXT': "Le partage de contenus protégés par des droits d'auteur n'est pas autorisé dans l'ImmoApp.",
  'PROFILE.TERMS_OF_USE.LIST_8_TITLE': 'Contenu commercial',
  'PROFILE.TERMS_OF_USE.LIST_8_TEXT': "La publicité commerciale n'est pas autorisée sur l'ImmoApp. Les petites annonces privées sont toutefois acceptables.",
  'PROFILE.TERMS_OF_USE.LIST_9_TITLE': 'Activités illégales',
  'PROFILE.TERMS_OF_USE.LIST_9_TEXT': "L'exécution, la promotion, la planification ou l'incitation d'activités illégales ne sont pas autorisées.",
  'PROFILE.TERMS_OF_USE.MODERATION_TITLE': "Modération par l'administration",
  'PROFILE.TERMS_OF_USE.MODERATION_TEXT1': "La modération de l'ImmoApp est entre les mains de chaque administration. C'est sa responsabilité et son privilège de veiller à ce que tous les utilisateurs puissent profiter de l'App et des contributions de la communauté dans une atmosphère sûre, respectueuse et positive. L'administration veille à ce que les directives susmentionnées soient respectées et est habilitée à agir en conséquence si des infractions sont constatées.",
  'PROFILE.TERMS_OF_USE.MODERATION_TEXT2': "Si vous rencontrez des contenus problématiques ou si vous avez des questions concernant la modération, veuillez vous adresser directement à votre administration. Nous vous remercions de votre compréhension et de votre participation à la création d'une communauté accueillante et respectueuse sur ImmoApp.",

  'DASHBOARD.NO_DESCRIPTION_AVAILABLE': 'Aucune description disponible.',

  'REPORT.TICKET.REPAIR.TITLE': 'Déclaration de réparation',
  'REPORT.TICKET.REPAIR.WITH_NEIGHBOURS.DESCRIPTION': "Informez l'administration (et éventuellement vos voisins) de tout dommage",
  'REPORT.TICKET.REPAIR.WITHOUT_NEIGHBOURS.DESCRIPTION': "Informez l'administration de tout dommage",
  'REPORT.TICKET.KEYORDER.TITLE': "Clé ou carte d'accès",
  'REPORT.TICKET.KEYORDER.DESCRIPTION': 'Remplacez les clés perdues, commandez des clés de remplacement ou remplacez les cylindres',
  'REPORT.TICKET.NAMEPLATE.TITLE': 'Étiquette de nom',
  'REPORT.TICKET.NAMEPLATE.DESCRIPTION': "Nouvelle plaque signalétique pour porte d'entrée, porte d'appartement ou boîte aux lettres",
  'REPORT.TICKET.DOCUMENT.TITLE': 'Document ou formulaire',
  'REPORT.TICKET.DOCUMENT.DESCRIPTION': 'Contrat de commande, règlement, formulaire de candidature et autres documents',
  'REPORT.TICKET.OTHER.TITLE': 'Autre message',
  'REPORT.TICKET.OTHER.DESCRIPTION': "Informer l'administration d'autre chose",
  'REPORT.TICKET.OTHER.DESCRIPTION.SPECTREN': "Informer le service technique d'autre chose",
  'REPORT.MESSAGE': 'Message',
  'REPORT.PINBOARD': "Tableau d'affichage",
  'REPORT.VOTE_ITEM': "Point de l'ordre du jour",
  'REPORT.RESERVATION.TITLE': '"{{reservationPlace}}" le {{date}} de {{startHour}} à {{endHour}} heures',
  'REPORT.RESERVATION.CONFIRMATION_NEEDED': "La demande doit d'abord être confirmée par l'administration.",
  'REPORT.RESERVATION.VALID': "La réservation ne nécessite pas de confirmation de la part de l'administration, mais est valable immédiatement.",
  'REPORT.ADD.TITLE': 'Bonjour, comment pouvons-nous vous aider?',
  'REPORT.ADD.SECTION.REPAIR': 'Déclaration de réparation',
  'REPORT.ADD.SECTION.ORDER': 'Commander',
  'REPORT.ADD.SECTION.OTHER': 'Autres préoccupations',
  'REPORT.ADD.NOTE.REPAIR': 'Vous {{action}} un rapport de réparation pour {{location}}.',
  'REPORT.ADD.NOTE.REPAIR.PUBLIC': 'Vous {{action}} un rapport de réparation public pour {{location}}.',
  'REPORT.ADD.NOTE.REPAIR.PRIVATE': 'Vous {{action}} un rapport de réparation privé pour {{location}}.',
  'REPORT.ADD.NOTE.KEYORDER': 'Vous {{action}} une commande de clé ou de carte pour {{location}}.',
  'REPORT.ADD.NOTE.NAMEPLATE': 'Vous {{action}} une commande de plaque signalétique pour {{location}}.',
  'REPORT.ADD.NOTE.DOCUMENT': 'Vous {{action}} une commande de document ou de formulaire pour {{location}}.',
  'REPORT.ADD.NOTE.PINBOARD': "Vous {{action}} une entrée de tableau d'affichage public pour {{location}}.",
  'REPORT.ADD.NOTE.RESERVATION': "Vous {{action}} une réservation pour {{location}}. La réservation ne nécessite pas de confirmation de la part de l'administration, mais est valable immédiatement.",
  'REPORT.ADD.NOTE.RESERVATION.REQUEST': 'Vous avez {{action}} une demande de réservation pour "{{location}}". La demande doit d\'abord être confirmée par l\'administration.',
  'REPORT.ADD.NOTE.VOTEITEM': "Vous {{action}} un point de l'ordre du jour du vote pour {{location}}.",
  'REPORT.ADD.NOTE.OTHER': 'Vous {{action}} un autre message privé pour {{location}}.',
  'REPORT.ADD.NOTE.CREATING': 'créez',
  'REPORT.ADD.NOTE.EDITING': 'éditez',
  'REPORT.ADD.SUBMIT': 'Soumettre',
  'REPORT.ADD.LIST.PINBOARD.TITLE': "Entrée de tableau d'affichage",
  'REPORT.ADD.LIST.PINBOARD.DESCRIPTION': 'Partagez des informations, des offres et des événements avec vos voisins',
  'REPORT.ADD.LIST.RESERVATION.TITLE': 'Réservation',
  'REPORT.ADD.LIST.RESERVATION.DESCRIPTION': 'Réserver un espace commun',
  'REPORT.ADD.LIST.VOTEITEM.TITLE': 'Point de vote',
  'REPORT.ADD.LIST.VOTEITEM.DESCRIPTION': 'Enregistrez une discussion ou un point de vote pour la prochaine réunion',
  'REPORT.ADD.REPAIR.NEW': 'Nouvelle déclaration de réparation',
  'REPORT.ADD.PINBOARD.NEW': "Nouvelle entrée sur le tableau d'affichage",
  'REPORT.ADD.RESERVATION.NEW': 'Nouvelle réservation',
  'REPORT.ADD.KEYORDER.NEW': 'Nouvelle commande de clé ou de carte',
  'REPORT.ADD.NAMEPLATE.NEW': 'Nouvelle commande de plaque signalétique',
  'REPORT.ADD.DOCUMENT.NEW': "Nouveau commande d'un document ou d'un formulaire",
  'REPORT.ADD.VOTEITEM.NEW': "Nouveau point à l'ordre du jour",
  'REPORT.ADD.RESERVATION.PLACE.TITLE': 'Zone commune',
  'REPORT.ADD.RESERVATION.PLACE.PLACEHOLDER': 'Choisir la zone commune...',
  'REPORT.ADD.RESERVATION.TIME.TITLE': 'Jour et heure',
  'REPORT.ADD.RESERVATION.MANDATE.NO_SPACES': "Aucune zone commune n'a encore été enregistrée pour cette propriété.",
  'REPORT.ADD.RESERVATION.CHOOSE.DAY': 'Choisir le jour',
  'REPORT.ADD.RESERVATION.CHOOSE.FROM': 'Depuis quand',
  'REPORT.ADD.RESERVATION.CHOOSE.UNTIL': "Jusqu'à quand",
  'REPORT.ADD.RESERVATION.CHOOSE.TIME': "Sélectionner l'heure souhaitée",
  'REPORT.ADD.RESERVATION.TIMZONE.NOTICE': "Remarque : Toutes les informations s'appliquent au fuseau horaire suisse.",
  'REPORT.ADD.OTHER.NEW': 'Autre demande',
  'REPORT.ADD.LOCATION': 'Lieu',
  'REPORT.ADD.LOCATION.PLACEHOLDER': 'Veuillez choisir une localisation...',
  'REPORT.ADD.LOCATION.DETAILS': "Plus de détails sur l'emplacement, par exemple le numéro de l'appartement",
  'REPORT.ADD.DEVICEINFO': "Informations sur l'appareil",
  'REPORT.ADD.SERIALNUMBER.TITLE': 'Numéro de série',
  'REPORT.ADD.SERIALNUMBER.DESCRIPTION': "Numéro de série de l'appareil",
  'REPORT.ADD.MANUFACTURER.TITLE': 'Fabricant',
  'REPORT.ADD.MANUFACTURER.DESCRIPTION': "Fabricant de l'appareil",
  'REPORT.ADD.PUBLIC': 'Public',
  'REPORT.ADD.PUBLIC.VISIBILITY': 'Visible pour les voisins?',
  'REPORT.ADD.NAMEPLATE.TITLE': 'Étiquette de nom',
  'REPORT.ADD.NAMEPLATE.ROW1': 'Ligne 1',
  'REPORT.ADD.NAMEPLATE.ROW2': 'Ligne 2',
  'REPORT.ADD.KEYORDER.TITLE': "Plus d'informations sur la clé / carte",
  'REPORT.ADD.KEYORDER.QUANTITY': 'Quantité',
  'REPORT.ADD.KEYORDER.LABEL': 'Étiquette de clé',
  'REPORT.ADD.DATAENTRYST.TITLE': 'Entrées de données',
  'REPORT.ADD.DATAENTRYST.NAME': 'Nom',
  'REPORT.ADD.ATTACHMENTS.ADD': 'Ajouter une annexe',
  'REPORT.ADD.ATTACHMENTS.REPAIR': 'Veuillez télécharger des photos des dégâts.',
  'REPORT.ADD.ATTACHMENTS.KEYORDER': 'Veuillez télécharger une image de la clé / carte.',
  'REPORT.ADD.TITLE_DESCRIPTION': 'Titre / Message',
  'REPORT.ADD.TITLE_DESCRIPTION.DESCRIPTION': 'Message',
  'REPORT.SUBMIT_REPORT.REPAIR.LOADING': "Votre rapport de sinistre est en cours d'envoi...",
  'REPORT.SUBMIT_REPORT.ORDER.LOADING': "Votre commande est en cours d'envoi...",
  'REPORT.SUBMIT_REPORT.OTHER.LOADING': 'Votre message est envoyé...',
  'REPORT.SUBMIT_REPORT.PINBOARD.LOADING': "Votre entrée dans le tableau d'affichage est en cours d'envoi...",
  'REPORT.SUBMIT_REPORT.VOTEITEM.LOADING': "Votre article de vote est en cours d'envoi...",
  'REPORT.SUBMIT_REPORT.RESERVATION.LOADING': "Votre réservation est en cours d'envoi...",
  'REPORT.SUBMIT_REPORT.REPAIR.SUCCESS': 'Votre déclaration de sinistre a été envoyée avec succès.',
  'REPORT.SUBMIT_REPORT.ORDER.SUCCESS': 'Votre commande a été soumise avec succès.',
  'REPORT.SUBMIT_REPORT.OTHER.SUCCESS': 'Votre message a été soumis avec succès.',
  'REPORT.SUBMIT_REPORT.PINBOARD.SUCCESS': "Votre entrée dans le tableau d'affichage a été envoyée avec succès.",
  'REPORT.SUBMIT_REPORT.VOTEITEM.SUCCESS': 'Votre vote a été envoyé avec succès.',
  'REPORT.SUBMIT_REPORT.RESERVATION.SUCCESS': 'Votre réservation a été envoyé avec succès',
  'REPORT.UPDATE_REPORT.REPAIR.LOADING': 'La déclaration de sinistre est mise à jour...',
  'REPORT.UPDATE_REPORT.ORDER.LOADING': 'La commande est en cours de mise à jour...',
  'REPORT.UPDATE_REPORT.OTHER.LOADING': 'Le message est en cours de mise à jour...',
  'REPORT.UPDATE_REPORT.PINBOARD.LOADING': "L'entrée du tableau d'affichage est mise à jour...",
  'REPORT.UPDATE_REPORT.VOTEITEM.LOADING': "Mise à jour de l'ordre du jour...",
  'RAPPORT.UPDATE_REPORT.RESERVATION.LOADING': 'La réservation est en cours de mise à jour...',
  'REPORT.UPDATE_REPORT.REPAIR.SUCCESS': 'Votre déclaration de réparation a été mise à jour avec succès.',
  'REPORT.UPDATE_REPORT.ORDER.SUCCESS': 'Votre commande a été mise à jour avec succès.',
  'REPORT.UPDATE_REPORT.OTHER.SUCCESS': 'Votre message a été mis à jour avec succès.',
  'REPORT.UPDATE_REPORT.PINBOARD.SUCCESS': "Votre inscription sur le tableau d'affichage a été mise à jour avec succès.",
  'REPORT.UPDATE_REPORT.VOTEITEM.SUCCESS': "Votre point de l'ordre du jour a été mis à jour avec succès.",
  'REPORT.UPDATE_REPORT.RESERVATION.SUCCESS': 'Votre réservation a été mise à jour avec succès',
  'REPORT.TOO_MANY_REQUESTS': 'Il y a eu trop de demandes en peu de temps. Veuillez patienter quelques minutes, puis réessayer.',
  'REPORT.UPLOAD_CANCEL': 'Le téléchargement a été supprimé.',
  'REPORT.ADD.ACCEPT_PRIVACY_POLICY_WRAPPER': "J'accepte le {{link}}.",
  'REPORT.ADD.ACCEPT_PRIVACY_POLICY_WORD': 'Politique de confidentialité',
  'REPORT.ADD.TITLE_REQUIRED': 'Veuillez entrer un titre.',
  'REPORT.ADD.STATUS_REQUIRED': 'Veuillez sélectionner un statut.',
  'REPORT.ADD.DESCRIPTION_REQUIRED': 'Veuillez saisir une description.',
  'REPORT.ADD.MESSAGE_REQUIRED': 'Veuillez saisir un message.',
  'REPORT.ADD.CAPTCHA_REQUIRED': "Veuillez d'abord résoudre le CAPTCHA.",
  'REPORT.ADD.PROPERTY_REQUIRED': 'Veuillez sélectionner un bien immobilier.',
  'REPORT.ADD.FIRSTNAME_REQUIRED': 'Veuillez saisir un prénom.',
  'REPORT.ADD.LASTNAME_REQUIRED': 'Veuillez saisir un nom de famille.',
  'REPORT.DELETE.TITLE': "Supprimer l'entrée",
  'REPORT.DELETE.DESCRIPTION': "Si vous confirmez par OK, l'inscription que vous avez rédigée sera irrévocablement supprimée.",
  'REPORT.DELETE.NOT_POSSIBLE': 'Le ticket ne peut plus être supprimé car le statut a déjà changé.',
  'REPORT.DELETE.SUCCESS': "L'entrée a été supprimée avec succès.",

  'REPORT.EDIT.GENERAL': "Modifier l'entrée",
  'REPORT.EDIT.REPAIR': 'Modifier le rapport de dommages',
  'REPORT.EDIT.PINBOARD': "Modifier l'entrée sur le tableau d'affichage",
  'REPORT.EDIT.RESERVATION': 'Modifier la réservation',
  'REPORT.EDIT.KEYORDER': 'Modifier la commande de clés ou de cartes',
  'REPORT.EDIT.NAMEPLATE': "Modifier l'ordre des plaques signalétiques",
  'REPORT.EDIT.DOCUMENT': "Modifier l'ordre d'un document ou d'un formulaire",
  'REPORT.EDIT.VOTE_ITEM': "Editer un point de l'ordre du jour",
  'REPORT.EDIT.CONTACT_DATA': 'Traiter une demande de données',
  'REPORT.EDIT.OTHER': 'Traiter une autre demande',
  'REPORT.DELETE.RESERVATION': 'Supprimer la réservation',

  'VOTES.ITEMS.SUBMITTED_ON': 'Transmis le',
  'VOTES.ITEMS.EDIT_NOT_ALLOWED': "Votre point de l'ordre du jour est déjà utilisé dans un vote et ne peut donc plus être traité.",

  'VOTES.TITLE': 'Votes',
  'VOTES.MY_VOTE_ITEMS': "Mes points à l'ordre du jour",
  'VOTES.DATE_UNTIL': "jusqu'à",
  'VOTES.LIST.TITLE': 'Votes en',
  'VOTES.DATE_END': 'Fin',
  'VOTES.NO_VOTES_AVAILABLE': 'Aucun vote disponible.',
  'VOTES.NO_VOTE_ITEMS_SUBMITTED': "Vous n'avez pas encore soumis de points à l'ordre du jour.",
  'VOTES.STATUS_ACTIVE_NOT_REGISTERED': "S'inscrire et voter maintenant",
  'VOTES.STATUS_ACTIVE_REGISTERED.VOTE': "Vote actif jusqu'au",
  'VOTES.STATUS_ACTIVE_REGISTERED.SURVEY': "Le sondage dure jusqu'à",
  'VOTES.STATUS_WARRANT_REGISTERED': 'Donner procuration',
  'VOTES.STATUS_WARRANT_COMPLETED': 'Terminé par procuration',
  'VOTES.STATUS_COMPLETED.VOTE': 'Vote terminé avec succès',
  'VOTES.STATUS_COMPLETED.SURVEY': 'Le sondage terminé avec succès',
  'VOTES.STATUS_NOT_STARTED_NOT_REGISTERED': "S'inscrire maintenant",
  'VOTES.STATUS_NOT_STARTED_REGISTERED.VOTE': 'Début du vote le',
  'VOTES.STATUS_NOT_STARTED_REGISTERED.SURVEY': 'Début du sondage le',
  'VOTES.STATUS_INACTIVE.VOTE': "Le vote n'est plus actif",
  'VOTES.STATUS_INACTIVE.SURVEY': "Le sondage n'est plus actif",
  'VOTES.STAFF_ALL_VOTES_IN_ADMIN_APP': "Sur l'ImmoApp, seuls les votes des 2 dernières années sont affichés pour les collaborateurs. Pour voir les votes plus anciens, veuillez ouvrir l'Admin-App.",

  'VOTE.STATUS_NOT_ALLOWED.LABEL': "Vous n'êtes pas autorisé à voter. Cela peut se produire si un vote a déjà été soumis pour votre numéro de propriétaire.",
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.LABEL.VOTE': "S'enregistrer maintenant et recevoir un rappel automatique du début du vote.",
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.LABEL.SURVEY': "S'enregistrer maintenant et recevoir un rappel automatique du début du sondage.",
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED_LIVE.LABEL': "S'enregistrer maintenant",
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.BUTTON.VOTE': 'Enregistrement pour le vote',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.BUTTON.SURVEY': 'Enregistrement pour le sondage',
  'VOTE.STATUS_NOT_STARTED_REGISTERED.LABEL.VOTE': 'Le vote débute le {{dateStart}}. Vous êtes enregistré et vous serez notifié.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED.LABEL.SURVEY': 'Le sondage débute le {{dateStart}}. Vous êtes enregistré et vous serez notifié.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED_LIVE.LABEL.VOTE': 'Le vote débute le {{dateStart}}. Vous êtes enregistré.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED_LIVE.LABEL.SURVEY': 'Le sondage débute le {{dateStart}}. Vous êtes enregistré.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED.BUTTON': 'Vote',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.LABEL': "S'inscrire maintenant et voter immédiatement.",
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON.VOTE': "S'inscrire pour voter",
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON.SURVEY': "S'inscrire pour sondage",
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON_LIVE.VOTE': "S'enregistrer pour voter en direct",
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON_LIVE.SURVEY': "S'enregistrer pour sondage en direct",
  'VOTE.STATUS_ACTIVE_REGISTERED.BUTTON': 'Votez maintenant',
  'VOTE.STATUS_COMPLETED.BUTTON': 'Voir votre sélection',
  'VOTE.STATUS_WARRANT_COMPLETED.BUTTON': 'Voir sélection',
  'VOTE.STATUS_COMPLETED_LIVE.LABEL': "Vous pouvez encore modifier votre sélection jusqu'à la fin du vote en direct.",
  'VOTE.STATUS_COMPLETED_LIVE.BUTTON': 'Modifier la sélection',
  'VOTE.STATUS_COMPLETED_ONLINE_NOW_LIVE.LABEL': 'Vous avez soumis votre vote dans le cadre du pré-vote en ligne. Si vous décidez de participer au vote en direct, vous pouvez vous réinscrire. Dans ce cas, votre vote précédent sera effacé.',
  'VOTE.STATUS_INACTIVE.REGISTER_MODAL.DESCRIPTION': 'A partir du {{date}}, il sera possible de voter ici par voie électronique sur les propositions de "{{title}}".',
  'VOTE.STATUS_INACTIVE.REGISTER_MODAL.BOLD_TEXT': "Recevoir un rappel et un code d'accès par e-mail.",
  'VOTE.STATUS_ACTIVE.REGISTER_MODAL.DESCRIPTION': 'Depuis le {{date}}, il est possible de voter ici par voie électronique sur les propositions de "{{title}}".',
  'VOTE.STATUS_ACTIVE.REGISTER_MODAL.LIVE.BOLD_TEXT': "S'enregistrer maintenant et voter immédiatement.",
  'VOTE.STATUS_ACTIVE.REGISTER_MODAL.ONLINE.BOLD_TEXT': "Recevoir un code d'accès par e-mail.",
  'VOTE.CODE_RANGE_VALIDATION': 'Le code doit être un nombre compris entre 1000 et 9999.',
  'VOTE.CODE_REQUIRED': 'Veuillez saisir votre code.',
  'VOTE.CODE_INVALID': 'Code non valide. Veuillez réessayer.',
  'VOTE.CODE_MODAL.TITLE': 'Saisir le code',
  'VOTE.CODE_MODAL.DESCRIPTION_1': "Veuillez saisir votre code d'accès ici.",
  'VOTE.CODE_MODAL.DESCRIPTION_2_LIVE': "Si vous votez en direct, vous le recevrez directement de l'administration.",
  'VOTE.CODE_MODAL.DESCRIPTION_2_ONLINE': "Vous trouverez le code dans l'e-mail de notification.",
  'VOTE.CODE_MODAL.REQUEST_ACCESS_CODE_AGAIN': "Demander à nouveau le code d'accès",
  'VOTE.CODE_MODAL.REQUEST_ACCESS_CODE_AGAIN.SUCCESS': "Le code d'accès vous a été envoyé à nouveau par e-mail. Veuillez également consulter votre dossier de courrier indésirable.",
  'VOTE.CODE_MODAL.VOTE_NOW': 'Votez maintenant',
  'VOTE.CODE_MODAL.VOTING_PERIOD.VOTE': "Le vote en ligne est disponible jusqu'au {{date}}.",
  'VOTE.CODE_MODAL.VOTING_PERIOD.SURVEY': "Le sondage en ligne est disponible jusqu'au {{date}}.",
  'VOTE.REGISTER_MODAL.UNREGISTERED.TITLE': 'S’inscrire',
  'VOTE.REGISTER_MODAL.UNREGISTERED.BUTTON': 'S’inscrire maintenant',
  'VOTE.REGISTER_MODAL.REGISTERED.TITLE': 'Inscription réussite',
  'VOTE.REGISTER_MODAL.START_SOON': 'Plus que quelques minutes',
  'VOTE.REGISTER_MODAL.DAYS_UNTIL_START': {
    none: '{{count}} jours',
    singular: '{{count}} jour',
    plural: '{{count}} jours',
  },
  'VOTE.REGISTER_MODAL.HOURS_UNTIL_START': {
    none: 'et {{count}} heures',
    singular: 'et {{count}} heure',
    plural: 'et {{count}} heures',
  },
  'VOTE.REGISTER_MODAL.PART_2.VOTE': "jusqu'au début du vote.",
  'VOTE.REGISTER_MODAL.PART_2.SURVEY': "jusqu'au début du sondage.",
  'VOTE.WARRANT.LOADING': 'Procuration est en cours de chargement...',
  'VOTE.WARRANT.EMAIL_NAME_PLACEHOLDER': 'Entrez le nom / e-mail...',
  'VOTE.WARRANT.BANNER': "Une procuration vous a été accordée. Votre vote s'applique également au",
  'VOTE.WARRANT.ASSIGNED_TO': 'Procuration accordée à {{receiver}}',
  'VOTE.WARRANT.SHOW': 'Afficher la procuration',
  'VOTE.WARRANT.REVOKE': 'Révoquer la procuration',
  'VOTE.WARRANT.REVOKE_DESCRIPTION': 'Si vous confirmez avec OK, la procuration pour {{receiver}} sera révoquée.',
  'VOTE.WARRANT_MODAL.STEP_1.DESCRIPTION': "Dans le champ ci-dessous, saisissez le nom ou l'adresse e-mail du destinataire du procuration. Le système vérifie ensuite si un propriétaire avec ces données existe dans votre propriété.",
  'VOTE.WARRANT_MODAL.STEP_2.DESCRIPTION': "Le Garant, <b>{{warrantGiverName}}</b>, autorise par la présente <b>{{warrantReceiverName}} ({{warrantReceiverEmail}})</b> à représenter ses intérêts dans <b>{{voteTitle}}</b>.\n\nLe bénéficiaire de la procuration est autorisé à voter en mon nom à l'assemblée susmentionnée. Toutes les démarches entreprises par le destinataire lors de cette rencontre m'engagent juridiquement.",
  'VOTE.WARRANT_MODAL.NO_ENTRIES': 'Aucun propriétaire trouvé.',
  'VOTE.WARRANT_MODAL.SUBMIT_BUTTON': 'Accorder procuration',
  'VOTE.WARRANT_MODAL.SUCCESS_TEXT': 'La procuration a été accordée avec succès. Une copie a été envoyée par e-mail à vous et au destinataire de la procuration.',

  'VOTE.REVIEW.SIGNATURE_NOTE': "Si vous travaillez sur un appareil à écran tactile, vous pouvez utiliser un stylet ou votre doigt pour écrire votre signature dans l'espace ci-dessus. Si votre appareil ne prend pas en charge la saisie tactile, vous pouvez utiliser votre souris pour dessiner votre signature.",
  'VOTE.REVIEW.LEGAL_NOTE.VOTE': 'Vous autorisez {{administration}} à représenter vos intérêts (selon le vote ci-dessus) pour "{{title}}". Veuillez vérifier soigneusement votre choix. Le vote soumis ici est juridiquement irrévocable.',
  'VOTE.REVIEW.LEGAL_NOTE.SURVEY': 'Veuillez vérifier soigneusement votre choix. Le vote émis ici ne peut plus être modifié.',
  'VOTE.REVIEW.PLACEHOLDER_VOTE': 'Vote',
  'VOTE.REVIEW.PLACEHOLDER_ADMINISTRATION': 'Administration',
  'VOTE.SUBMIT.SIGNATURE_MISSING': "Veuillez d'abord entrer votre signature.",
  'VOTE.SUBMIT.SIGNATURE_MISSING_REVIEW': 'Vous pouvez trouver le champ de signature dans l\'écran actuel sous "Votre sélection".',
  'VOTE.SUBMIT.IN_PROGRESS': 'Votre choix sera transmis...',
  'VOTE.SUBMIT.SUCCESS.DEFAULT': 'Votre sélection a été transmise avec succès. Une copie vous sera envoyée par e-mail.',
  'VOTE.SUBMIT.SUCCESS.WARRANT': 'Votre sélection a été transmise avec succès et la procuration créée. Une copie vous sera envoyée par e-mail.',

  'VOTE.LIST.CONFIRM_VOTES.BUTTON': 'Confirmer la sélection',
  'VOTE.LIST.FOOTER.TEXT_LIVE': "Votre choix sera transmis en temps réel (LIVE) à l'administration. Vous pouvez encore modifier votre choix jusqu'à la fin de chaque point de l'ordre du jour.",
  'VOTE.LIST.FOOTER.TEXT_ONLINE': "Veuillez d'abord voter sur tous les points de l'ordre du jour.",
  'VOTE.LIST.MOTIONS.VOTE': 'Points soumis au vote',
  'VOTE.LIST.MOTIONS.SURVEY': 'Points soumis au sondage',
  'VOTE.LIST.NO_MOTIONS_AVAILABLE': 'Aucune motion disponible.',
  'VOTE.LIST.ITEM.INACTIVE': "Point de l'ordre du jour non activé.",
  'VOTE.LIST.ITEM.PAUSED': 'En pause',
  'VOTE.LIST.ITEM.COMPLETED': 'Terminé',

  'VOTE.REVIEW.SIGNATURE': 'Signature',
  'VOTE.REVIEW.YOUR_SELECTION': 'Votre sélection',
  'VOTE.REVIEW.SUBMIT_VOTES': 'Soumettre le vote',
  'VOTE.REVIEW.VIEW_SELECTION_MANUAL': "Votre sélection a été enregistrée par l'administration le {{date}}.",
  'VOTE.REVIEW.VIEW_SELECTION_WARRANT': 'La sélection a été soumise par procuration le {{date}}.',
  'VOTE.REVIEW.VIEW_SELECTION_ONLINE.VOTE': 'Vous avez transmis votre choix le {{date}} lors du vote en ligne.',
  'VOTE.REVIEW.VIEW_SELECTION_ONLINE.SURVEY': "Vous avez transmis votre choix le {{date}} dans l'enquête en ligne.",
  'VOTE.REVIEW.VIEW_SELECTION_LIVE.VOTE': 'Vous avez soumis votre choix le {{date}} lors du vote en direct.',
  'VOTE.REVIEW.VIEW_SELECTION_LIVE.SURVEY': "Vous avez soumis votre choix le {{date}} dans l'enquête en direct.",
  'VOTE.REVIEW.CHANGES_NOT_ALLOWED.VOTE': 'Les modifications ultérieures ne sont pas légalement autorisées.',
  'VOTE.REVIEW.CHANGES_NOT_ALLOWED.SURVEY': 'Les modifications ultérieures ne sont pas autorisées.',

  'DASHBOARD.PROPERTIES.TITLE': 'Propriétés & contacts',
  'DASHBOARD.PROPERTIES.CONTACT': 'Contact',
  'DASHBOARD.PROPERTIES.CONTACTS': 'Contacts',
  'DASHBOARD.PROPERTIES.USEFUL_INFORMATION': 'Infos utiles',
  'DASHBOARD.PROPERTIES.MANDATE.INACTIVE': 'Inactif',

  'DASHBOARD.PROPERTIES.DETAIL.EMAIL': 'E-mail: ',
  'DASHBOARD.PROPERTIES.DETAIL.COMPANY': 'Société: ',
  'DASHBOARD.PROPERTIES.DETAIL.TEL_NR': 'N° de téléphone: ',
  'DASHBOARD.PROPERTIES.DETAIL.MISSING_INFORMATION': 'Les coordonnées sont manquantes',

  'DASHBOARD.OVERVIEW.HERO.BUTTON.CONTACTS.MULTIPLE': 'Propriétés & contacts',
  'DASHBOARD.OVERVIEW.HERO.BUTTON.CONTACTS.SINGLE': 'Contacts importants',
  'DASHBOARD.OVERVIEW.HERO.BUTTON.ENERGY': 'Surveillance énergétique',
  'DASHBOARD.OVERVIEW.REPAIR_REPORT.BUTTON': 'Déclaration de réparation',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.TITLE': 'Actualités en',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.ALL': 'Toutes\nles entrées',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.MINE': 'Mes\nentrées',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.MESSAGES': 'Messages',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.PINBOARD': 'Pinboard',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.REPAIRS': 'Réparations',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.RESERVATIONS': 'Réservations',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.VOTEITEMS': 'Articles à voter',
  'DASHBOARD.OVERVIEW.COMMENT': 'Commentaire',
  'DASHBOARD.OVERVIEW.COMMENTS': 'Commentaires',
  'DASHBOARD.OVERVIEW.ATTACHMENT': 'Annexe',
  'DASHBOARD.OVERVIEW.LOAD_MORE_ENTRIES': "Charger d'autres entrées",

  'DASHBOARD.REPORT.SUBSCRIBE': 'Vous serez informé de toutes les mises à jour et commentaires.',
  'DASHBOARD.REPORT.UNSUBSCRIBE': 'Notifications désactivées.',
  'DASHBOARD.REPORT.YOUR_ADMINISTRATION': 'votre administration',
  'DASHBOARD.REPORT.ADD_STATUS.DESCRIPTION': 'Description',
  'DASHBOARD.REPORT.ADD_STATUS.TEXT': 'Texte',
  'DASHBOARD.REPORT.ADD_STATUS.DESCRIPTION.PLACEHOLDER': 'Entrer une description',
  'DASHBOARD.REPORT.ADD_STATUS.STATUS': 'Statut',
  'DASHBOARD.REPORT.ADD_STATUS.STATUS.PLACEHOLDER': 'Sélectionner un statut',
  'DASHBOARD.REPORT.ADD_STATUS.TITLE': 'Ajouter un statut',
  'DASHBOARD.REPORT.ADD_STATUS.SUCCESS': 'Le statut a été ajouté avec succès au ticket.',
  'DASHBOARD.REPORT.ADD_STATUS.PUSH.DESCRIPTION': 'Faut-il envoyer un message push? Seuls les utilisateurs qui ont activé les notifications push recevront le message.',
  'DASHBOARD.REPORT.ADD_STATUS.EMAIL.DESCRIPTION': "Faut-il envoyer un e-mail? Seuls les utilisateurs qui ont activé l'envoi d'un e-mail recevront un e-mail (Désactivé par défaut)",
  'DASHBOARD.REPORT.ADD_YAROWA.TITLE': 'Créer un processus Yarowa',
  'DASHBOARD.REPORT.ADD_YAROWA.ALERT.MESSAGE': 'Si vous confirmez avec OK, vous serez redirigé vers le portail Yarowa AG et pourrez faire appel à un prestataire de services.',
  'DASHBOARD.REPORT.PENDING_YAROWA.TITLE': 'Le processus est créé...',
  'DASHBOARD.REPORT.PENDING_YAROWA.DESCRIPTION': 'Veuillez terminer la démarche sur le site Yarowa. Une fois le processus créé, cette fenêtre se fermera automatiquement.',
  'DASHBOARD.REPORT.GO_TO_YAROWA.TITLE': 'Ouvrir le processus Yarowa',
  'DASHBOARD.REPORT.REMOVE_YAROWA.TITLE': 'Supprimer le processus Yarowa',
  'DASHBOARD.REPORT.REMOVE_YAROWA.ALERT.MESSAGE': 'Si vous confirmez par OK, la connexion au processus Yarowa sera supprimée. Si vous souhaitez supprimer complètement le processus, veuillez le supprimer de la plateforme Yarowa.',
  'DASHBOARD.REPORT.REMOVE_YAROWA.COMPLETED': 'Le processus Yarowa a été supprimé avec succès.',
  'DASHBOARD.REPORT.RECEIVERS.PUBLIC_EXTERNAL_TICKET': "Ticket externe: visible uniquement par les administrateurs et l'expéditeur.",
  'DASHBOARD.REPORT.RECEIVERS.PRIVATE_EXTERNAL_TICKET': "Ticket externe privé: visible uniquement par les administrateurs et l'expéditeur.",
  'DASHBOARD.REPORT.RECEIVERS.PRIVATE_INTERNAL_TICKET': "Ticket privé: visible uniquement par les concierges, les administrateurs et l'expéditeur.",
  'DASHBOARD.REPORT.RECEIVERS.PRIVATE_INTERNAL_RESERVATION': "Réservation privée: visible uniquement par les concierges, les administrateurs et l'expéditeur.",
  'DASHBOARD.REPORT.COMMENTS.TITLE': 'Commentaires',
  'DASHBOARD.REPORT.COMMENTS.PLACEHOLDER': 'Saisir un nouveau commentaire ici...',
  'DASHBOARD.REPORT.COMMENTS.SUBMIT': 'Envoyer un commentaire',
  'DASHBOARD.REPORT.COMMENTS.DELETE.HEADER': 'Supprimer le commentaire',
  'DASHBOARD.REPORT.COMMENTS.DELETE.CONFIRMATION': 'Voulez-vous supprimer ce commentaire?',
  'DASHBOARD.REPORT.COMMENTS.STATUS.EDITED': 'Édité',
  'DASHBOARD.REPORT.COMMENTS.NA_YET': "Aucun commentaire n'a encore été saisi.",
  'DASHBOARD.REPORT.COMMENTS.STATUS.DELETED': 'Le commentaire a été supprimé.',
  'DASHBOARD.REPORT.COMMENTS.CREATE.PROGRESS': 'Le commentaire est transmis...',
  'DASHBOARD.REPORT.COMMENTS.CREATE.SUCCESS': 'Le commentaire a été créé avec succès.',
  'DASHBOARD.REPORT.COMMENTS.EDIT.SUCCESS': 'Le commentaire a été mis à jour avec succès.',
  'DASHBOARD.REPORT.COMMENTS.DELETE.SUCCESS': 'Le commentaire a été supprimé avec succès.',
  'DASHBOARD.REPORT.COMMENTS.RESTORED.SUCCESS': 'Le commentaire a été restauré avec succès.',
  'DASHBOARD.REPORT.COMMENTS.DISABLED_USER_INFO': 'Commentaires désactivés.',
  'DASHBOARD.REPORT.PERSONAL_MESSAGE': 'Message personnel',
  'DASHBOARD.REPORT.CONTACT_INFOS_FOR_REPORTING_COMMENT': "Signaler ce commentaire à l'administration",
  'DASHBOARD.REPORT.CONTACT_INFOS_ONLY_VISIBLE_FOR_ADMINISTRATION': "Les informations de contact ne sont visibles que pour l'administration.",
  'DASHBOARD.REPORT.ERROR.NOT_AVAILABLE': "L'entrée n'est pas disponible.",
  'DASHBOARD.REPORT.SHARE': 'Partager par e-mail',
  'DASHBOARD.REPORT.SHARE.HEADER': 'Partager par e-mail',
  'DASHBOARD.REPORT.SHARE.MESSAGE': "Qui doit recevoir l'e-mail?",
  'DASHBOARD.REPORT.SHARE.HEADER.EMAIL': 'Adresse e-mail',
  'DASHBOARD.REPORT.SHARE.INVALID_EMAIL': 'Adresse e-mail invalide.',
  'DASHBOARD.REPORT.SHARE.DOMAIN_WARNING': 'Attention: Le domaine <strong>"{{domain}}"</strong> ne figure pas dans notre liste de fournisseurs de messagerie populaires. Veuillez vérifier votre saisie et appuyer à nouveau sur "Envoyer" pour confirmer l\'expédition.',
  'DASHBOARD.REPORT.SHARE.SUCCESS': "L'e-mail a été envoyé avec succès à <strong>{{email}}</strong>.",
  'DASHBOARD.REPORT.SHARE.SEND': 'Envoyer',
  'DASHBOARD.REPORT.SCREENSHOT_TAKE': "Faire une capture d'écran",
  'DASHBOARD.REPORT.SCREENSHOT_CREATING': "La capture d'écran est créée...",

  'LOCATION.ALL_PROPERTIES': 'Tous les biens immobiliers',
  'LOCATION.DISABLED.PINBOARD': "Le tableau d'affichage est désactivé pour cette propriété",

  'DOCUMENTS.TITLE': 'Documents',
  'DOCUMENTS.SEARCH.TITLE': 'Recherche de documents',
  'DOCUMENTS.SEARCH.PLACEHOLDER': 'Rechercher des documents...',
  'DOCUMENTS.SEARCH.TOO_FEW_CHARACTERS': 'Au moins 3 caractères requis.',
  'DOCUMENTS.INACTIVE_ONLY_VISIBLE_TO_ADMINS': 'Propriété inactive - Uniquement visible par les administrateurs',
  'DOCUMENTS.ALL': 'Tous les documents',
  'DOCUMENTS.RECENT': 'Documents récemment ajoutés',
  'DOCUMENTS.NO_ENTRIES': 'Aucun fichier trouvé',
  'DOCUMENTS.MORE_SEARCH_RESULTS': 'Autres résultats de recherche disponibles. Veuillez affiner votre recherche.',
  'DOCUMENTS.PERSONAL_FOLDER': 'Dossier personnel',
  'DOCUMENTS.DOWNLOAD_ALL.NO_FILES': "Il n'y a pas de fichiers dans ce dossier.",
  'DOCUMENTS.DOWNLOAD_ALL.TITLE': 'Tout télécharger',
  'DOCUMENTS.DOWNLOAD_ALL_FILES.MESSAGE': 'Voulez-vous télécharger tous les fichiers de ce dossier?',
  'DOCUMENTS.DOWNLOAD_ALL_ATTACHMENTS.MESSAGE': 'Voulez-vous télécharger toutes les pièces jointes?',

  'PAGE_DENIED.TITLE': 'Accès refusé',
  'PAGE_DENIED.SUBTITLE': 'Aucun accès',
  'PAGE_DENIED.BACK_HOME': 'Retour',
  'PAGE_NOT_FOUND.TITLE': 'Page introuvable',
  'PAGE_NOT_FOUND.SUBTITLE': 'Page introuvable',
  'PAGE_NOT_FOUND.BACK_HOME': 'Retour',
  'PAGE_ERROR.TITLE': 'Erreur inattendue',
  'PAGE_ERROR.SUBTITLE': 'Erreur inattendue - Veuillez vérifier votre connexion Internet.',
  'PAGE_ERROR.BACK_HOME': 'Retour',

  'FILE_LOADER.NEW_PHOTO': 'Prendre une nouvelle photo',
  'FILE_LOADER.PHOTOS': {
    none: 'Choisir des photos',
    singular: 'Choisir une photo',
    plural: 'Choisir des photos',
  },
  'FILE_LOADER.VIDEOS': 'Choisir les vidéos',
  'FILE_LOADER.DOCUMENTS': 'Choisir les documents',
  'FILE_LOADER.NOT_SUPPORTED': 'Votre appareil ou navigateur ne prend pas en charge le téléchargement de fichiers.',
  'FILE_LOADER.SINGLE.DOWNLOAD_ERROR': 'Le fichier ne peut pas être téléchargé.',
  'FILE_LOADER.SINGLE.DOWNLOAD_SUCCESS': 'Le fichier a été téléchargé avec succès.',
  'FILE_LOADER.MULTIPLE.DOWNLOADING': 'Les fichiers sont en cours de téléchargement...',
  'FILE_LOADER.MULTIPLE.DOWNLOAD_ERROR': "Les fichiers n'ont pas pu être téléchargés.",
  'FILE_LOADER.MULTIPLE.DOWNLOAD_SUCCESS': 'Les fichiers ont été téléchargés avec succès.',
  'FILE_LOADER.NO_FILE_SELECTED': 'Aucun fichier sélectionné.',
  'FILE_LOADER.MAX_1_VIDEO': 'Maximum 1 vidéo autorisée.',
  'FILE_LOADER.MAX_MB': 'Le fichier est trop grand. Max: {{size}} MB.',
  'FILE_LOADER.LIMIT_TO_X': {
    none: 'Veuillez ne pas choisir plus de {{count}} fichiers.',
    singular: 'Veuillez ne pas choisir plus de {{count}} fichier.',
    plural: 'Veuillez ne pas choisir plus de {{count}} fichiers.',
  },

  'FILE_VIEWER.ERROR.DOES_NOT_EXIST_ANYMORE': "Le fichier n'existe plus.",
  'FILE_VIEWER.ERROR.SHARE_NOT_SUPPORTED': "La fonctionnalité de partage n'est pas prise en charge.",
  'FILE_VIEWER.ERROR.FILE_CANNOT_BE_SHARED': 'Le fichier ne peut pas être partagé.',
  'FILE_VIEWER.ERROR.FILE_CANNOT_BE_DISPLAYED': 'Le fichier ne peut pas être affiché.',
  'FILE_VIEWER.ERROR.FILE_CANNOT_BE_DISPLAYED_OR_DOWNLOADED': 'Le fichier ne peut pas être affiché ou téléchargé.',
  'FILE_VIEWER.X_RESULTS': {
    none: 'Aucun résultat',
    singular: '{{count}} résultat',
    plural: '{{count}} résultats',
  },
  'FILE_VIEWER.FILE_IS_LOADING': 'Le fichier est en cours de chargement...',

  'IMAGE_ALT.APP.LOGO': "Logo de l'application",
  'IMAGE_ALT.DASHBOARD_HEADER.BACKGROUND': 'Immeuble',
  'IMAGE_ALT.USER': 'Utilisateur connecté',
  'IMAGE_ALT.REPORT.COMMENT.OWNER': "L'expéditeur du commentaire",
  'IMAGE_ALT.PROFILE.USER': "Profil de l'utilisateur",
  'IMAGE_ALT.SIGN_IN': "Page d'accueil",
  'IMAGE_ALT.SIGN_UP': "Page d'inscription",
  'IMAGE_ALT.VOTE.REGISTER_MODAL': "S'inscrire pour voter",

  'INFOCENTER.SEGMENT.TICKET': 'Signaler un souci',
  'INFOCENTER.SEGMENT.FAQS': 'FAQ',
  'INFOCENTER.SEGMENT.DOCUMENTS': 'Documents',
  'INFOCENTER.WEBSITE.TITLE': 'Visitez le site Web {{adminName}}',
  'INFOCENTER.WEBSITE.TEXT': 'Visitez notre site Web pour toutes informations complémentaires sur {{adminName}}.',
  'INFOCENTER.WEBSITE.BUTTON': 'Vers le site',
  'INFOCENTER.LOAD.TITLE': "Restez informé avec l'ImmoApp",
  'INFOCENTER.LOAD.TEXT': "Avec l'ImmoApp, vous serez toujours bien informé de toutes les dernières nouvelles concernant votre propriété. Vous verrez également tous les messages que vous avez soumis avec leur statut actuel.",
  'INFOCENTER.FAQ.SECTION.ADD': 'Nouvelle section',
  'INFOCENTER.FAQ.SECTION.TITLE': 'Nouvelle section',
  'INFOCENTER.FAQ.ITEM.HEAD_TITLE': "Nouveau titre d'article",
  'INFOCENTER.FAQ.ITEM.BODY_TITLE': "Nouveau corps d'article",
  'INFOCENTER.FAQ.SECTION.TRANSLATE_DE': 'Traduire en allemand',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_EN': 'Traduire en anglais',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_FR': 'Traduction en français',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_IT': 'Traduction en italien',
  'INFOCENTER.FAQ.SECTION.DELETED': 'Section de la FAQ supprimée',
  'INFOCENTER.FAQ.DATA_UPDATED': 'FAQs enregistrées.',
  'INFOCENTER.FAQ.DELETE_POPOVER.TITLE': 'Impossible de supprimer',
  'INFOCENTER.FAQ.DELETE_POPOVER.CONTENT': "Supprimez d'abord tous les éléments de cette section",
  'INFOCENTER.DOCUMENTS.NONE': 'Aucun document disponible',
  'INFOCENTER.TRANSLATE_MODAL.TITLE': 'Traduction',
  'INFOCENTER.TRANSLATE_MODAL.SUBTITLE': 'Traduction actuelle',
  'INFOCENTER.TRANSLATE_MODAL.INPUT_PLACEHOLDER': 'Insérer la traduction',
  'INFOCENTER.TRANSLATE_MODAL.DELETE': 'Supprimer la traduction',
  'INFOCENTER.NOT_ACTIVATED_YET': "Le centre d'information n'est pas encore activé pour votre administration.",

  STARTING_BACKEND_KEYS: '---',

  'PUSH.NEW_ASSIGNMENT': 'Nouvelle affectation',
  'PUSH.NEW_COMMENT': 'Nouveau commentaire',
  'PUSH.NEW_MANUAL_REGISTRATION': 'Nouvelle inscription manuelle',
  'PUSH.STATUS': 'Statut',
  'PUSH.MESSAGE': 'Message',
  'PUSH.REPAIR': 'Rapport de réparation',
  'PUSH.VOTEITEM': 'Élément de vote',
  'PUSH.KEYORDER': 'Commande de clé ou de carte',
  'PUSH.NAMEPLATE': 'Ordre des badges',
  'PUSH.DOCUMENT': "Ordre d'un document ou d'un formulaire",
  'PUSH.OTHER': 'Autre message',
  'PUSH.PINBOARD_ENTRY': "Entrée du tableau d'affichage",
  'PUSH.RESERVATION': 'Entrée de la réservation',
  'PUSH.VOTEITEM.NEW': 'Nouveau poste de vote',
  'PUSH.DOCUMENTS.NEW': 'Nouveaux documents',
  'PUSH.DOCUMENTS.NEW_REMAINING_one': '+ {{count}} autre document',
  'PUSH.DOCUMENTS.NEW_REMAINING_other': '+ {{count}} autres documents',
  'PUSH.APPROVAL': 'Approbation',
  'PUSH.VOTE': 'Vote',
  'PUSH.VOTE.NEW': 'Nouveau vote',
  'PUSH.SURVEY': 'Sondage',
  'PUSH.VOTE_SUBMITTED_FROM': 'Vote soumis par',
  'PUSH.VOTE_AVAILABLE_NOW': 'Le vote "{{voteTitle}}" est maintenant disponible. Votez numériquement dans ImmoApp.',

  'EMAIL.HEADER.GREETING': 'Cher Monsieur/Madame {{name}}',
  'EMAIL.HEADER.ACCOUNT_ACTIVATED': 'Votre compte a été activé',
  'EMAIL.HEADER.ACCOUNT_DELETED': 'Suppression de votre compte',
  'EMAIL.HEADER.INVITED_BY_OWNER': 'Votre propriétaire vous a invité',
  'EMAIL.HEADER.IMMOAPP_ACCESS': 'La {{appName}} (accès et présentation)',
  'EMAIL.HEADER.ADMIN_ACCESS': "Accès à la zone d'administration",
  'EMAIL.HEADER.REPAIR.PRIVATE.NEW': 'Nouveau rapport de dommage privé',
  'EMAIL.HEADER.NEW_MESSAGE': 'Nouveau message',
  'EMAIL.HEADER.REPAIR.NEW': 'Nouveau rapport de dommage',
  'EMAIL.HEADER.KEYORDER.NEW': 'Nouvelle commande de clé ou de carte',
  'EMAIL.HEADER.DOCUMENT.NEW': "Nouveau commande d'un document ou d'un formulaire",
  'EMAIL.HEADER.OTHER.NEW': 'Nouvel autre message',
  'EMAIL.HEADER.NAMEPLATE.NEW': 'Nouvelle commande de plaque signalétique',
  'EMAIL.HEADER.PINBOARD.NEW': "Nouvelle entrée dans le tableau d'affichage",
  'EMAIL.HEADER.VOTEITEM.NEW': 'Nouvel élément de vote',
  'EMAIL.HEADER.RESERVATION.NEW': 'Nouvelle réservation',
  'EMAIL.HEADER.RESERVATION.REMINDER': 'Rappel de votre réservation {{title}}',
  'EMAIL.HEADER.FORWARDED_MESSAGE': 'Message transféré',
  'EMAIL.HEADER.REPAIR.FORWARDED': 'Rapport de dommage transféré',
  'EMAIL.HEADER.KEYORDER.FORWARDED': 'Commande de clé ou de carte transférée',
  'EMAIL.HEADER.DOCUMENT.FORWARDED': "Commande d'un document ou d'un formulaire transférée",
  'EMAIL.HEADER.OTHER.FORWARDED': 'Autre message transféré',
  'EMAIL.HEADER.NAMEPLATE.FORWARDED': 'Commande de plaque signalétique transférée',
  'EMAIL.HEADER.PINBOARD.FORWARDED': "Entrée dans le tableau d'affichage transféré",
  'EMAIL.HEADER.VOTEITEM.FORWARDED': 'Élément de vote transféré',
  'EMAIL.HEADER.RESERVATION.FORWARDED': 'Réservation transférée',
  'EMAIL.HEADER.REPAIR.UPDATED': 'Le rapport de dommages "{{entryTitle}}" dans {{locationName}} a été mis à jour en "{{status}}"',
  'EMAIL.HEADER.RESERVATION.UPDATED': 'Réservation {{entryTitle}} dans {{locationName}} a été mis à jour en "{{status}}"',
  'EMAIL.HEADER.KEYORDER.UPDATED': 'La commande de clé ou de carte "{{entryTitle}}" a été mis à jour en "{{status}}"',
  'EMAIL.HEADER.NAMEPLATE.UPDATED': 'L\'ordre des balises de nom "{{entryTitle}}" a été mis à jour vers "{{status}}"',
  'EMAIL.HEADER.DOCUMENT.UPDATED': 'La commande de document ou de formulaire "{{entryTitle}}" a été mise à jour vers "{{status}}"',
  'EMAIL.HEADER.VOTEITEM.UPDATED': 'L\'élément de vote "{{entryTitle}}" a été mis à jour en "{{status}}"',
  'EMAIL.HEADER.OTHER.UPDATED': 'L\'autre message "{{entryTitle}}" a été mis à jour en "{{status}}"',
  'EMAIL.HEADER.NEW_COMMENT_MESSAGE': 'Nouveau commentaire pour le message',
  'EMAIL.HEADER.NEW_COMMENT_REPAIR': 'Nouveau commentaire pour le rapport de dommages',
  'EMAIL.HEADER.NEW_COMMENT_KEYORDER': 'Nouveau commentaire pour la commande de clé ou de carte',
  'EMAIL.HEADER.NEW_COMMENT_NAMEPLATE': "Nouveau commentaire sur l'ordre des badges nominatifs",
  'EMAIL.HEADER.NEW_COMMENT_DOCUMENT': "Nouveau commentaire sur l'ordre d'un document ou d'un formulaire",
  'EMAIL.HEADER.NEW_COMMENT_OTHER': 'Nouveau commentaire sur un autre message',
  'EMAIL.HEADER.NEW_COMMENT_PINBOARD': "Nouveau commentaire pour l'entrée du tableau d'affichage",
  'EMAIL.HEADER.NEW_COMMENT_RESERVATION': 'Nouveau commentaire pour réservation',
  'EMAIL.HEADER.NEW_COMMENT_VOTEITEM': "Nouveau commentaire sur le point de l'ordre du jour",
  'EMAIL.HEADER.ACCESS_CODE_FOR.VOTE': "Votre code d'accès pour le vote",
  'EMAIL.HEADER.ACCESS_CODE_FOR.SURVEY': "Votre code d'accès pour le sondage",
  'EMAIL.HEADER.WARRANT.GIVEN': 'Procuration donnée pour vote',
  'EMAIL.HEADER.WARRANT.RECEIVED': 'A reçu une procuration pour vote',
  'EMAIL.HEADER.VOTE_RESULTS.DEFAULT': 'Résultats de votre vote pour',
  'EMAIL.HEADER.VOTE_RESULTS.WARRANT': 'Votre procuration pour',
  'EMAIL.HEADER.INVITE.FUTURE': 'Mise à jour admin ImmoApp : les utilisateurs seront bientôt invités',
  'EMAIL.HEADER.INVITE.PAST': 'Mise à jour admin ImmoApp : les utilisateurs ont été invités',
  'EMAIL.HEADER.EXTERNAL': "Nouveau message de l'ImmoApp",
  'EMAIL.HEADER.EMAIL_CHANGE': "Avis de sécurité: L'adresse e-mail a été modifiée",
  'EMAIL.HEADER.PASSWORD_CHANGE': 'Avis de sécurité: Le mot de passe a été modifié',

  'EMAIL.CONTENT.ACCOUNT_ACTIVATED': 'Votre compte pour le {{appName}} a été activé.',
  'EMAIL.CONTENT.DIRECT_ACCOUNT_LOGIN': 'Vous pouvez maintenant vous connecter avec votre adresse mail et votre mot de passe.',
  'EMAIL.CONTENT.VERIFY_ACCOUNT_LOGIN_1': 'Veuillez confirmer votre adresse mail en cliquant sur le lien suivant',
  'EMAIL.CONTENT.VERIFY_ACCOUNT_LOGIN_2A': 'Vous pouvez ensuite vous connecter avec votre adresse mail et le mot de passe choisi.',
  'EMAIL.CONTENT.VERIFY_ACCOUNT_LOGIN_2B': 'Vous pouvez ensuite vous connecter avec votre adresse mail et le mot de passe suivant',
  'EMAIL.CONTENT.IMMOAPP_AVAILABILITY': "L'ImmoApp est disponible gratuitement dans les app stores et en tant qu'application web",
  'EMAIL.CONTENT.WEB_BROWSER': 'Web / navigateur',
  'EMAIL.CONTENT.IMMOAPP_INTRODUCTION': "Vous trouverez ici une brève introduction à l'ImmoApp",
  'EMAIL.CONTENT.INVITED_BY_OWNER': 'Votre propriétaire vous a invité à utiliser la {{appName}}.',
  'EMAIL.CONTENT.IMMOAPP_LINKS': "Vous pouvez utiliser l'ImmoApp gratuitement via les liens suivants",
  'EMAIL.CONTENT.IMMOAPP_AVAILABLE': "Dès aujourd'hui, vous pouvez utiliser l'{{appName}} sur votre smartphone, votre tablette et votre ordinateur.",
  'EMAIL.CONTENT.FUNCTIONALITIES_INTRO': "Via l'application, vous pouvez désormais",
  'EMAIL.CONTENT.FUNCTIONALITIES_REPAIRS': 'Envoyer des rapports de dommages',
  'EMAIL.CONTENT.FUNCTIONALITIES_KEYORDER': 'Passer une commande de clé ou de carte',
  'EMAIL.CONTENT.FUNCTIONALITIES_NAMEPLATE': 'Commandez un porte-nom',
  'EMAIL.CONTENT.FUNCTIONALITIES_DOCUMENTORDER': 'Commandez documents et formulaires',
  'EMAIL.CONTENT.FUNCTIONALITIES_NEWS': 'Recevoir des informations sur votre propriété',
  'EMAIL.CONTENT.FUNCTIONALITIES_PINBOARD': "Rédiger un message sur le tableau d'affichage pour vos voisins",
  'EMAIL.CONTENT.FUNCTIONALITIES_DOCUMENTS': 'Consulter en ligne les documents de la propriété',
  'EMAIL.CONTENT.FUNCTIONALITIES_VOTES': 'Participer aux réunions des propriétaires',
  'EMAIL.CONTENT.FUNCTIONALITIES_TENANTS': "Ajouter et supprimer des locataires de manière indépendante sur l'ImmoApp. Cela permet aux locataires d'avoir un accès limité aux nouvelles, aux rapports de dommages, aux entrées du tableau d'affichage et à certains documents.",
  'EMAIL.CONTENT.ACCOUNT_ALREADY_CREATED': 'Un compte a déjà été créé pour vous. Vous pouvez vous connecter avec les données de connexion suivantes',
  'EMAIL.CONTENT.RECOMMENDATION_PASSWORD_CHANGE': 'Nous vous recommandons de modifier votre mot de passe lors de votre prochaine connexion dans le menu "Profil".',
  'EMAIL.CONTENT.SEND_EMAIL_TO': 'Veuillez envoyer un e-mail à {{contact}}',
  'EMAIL.CONTENT.INVITED_TO_ADMIN_AREA': "Vous avez été invité à accéder à la zone d'administration de {{appName}}.",
  'EMAIL.CONTENT.ADMIN_LOGIN': "Vous pouvez vous connecter avec les données de connexion suivantes dans la zone d'administration et l'ImmoApp (voir ci-dessous).",
  'EMAIL.CONTENT.ADMIN_SALUTATION': "Bonjour l'administrateur",
  'EMAIL.CONTENT.HOW_TO_CHANGE_PASSWORD_Q': 'Comment puis-je changer mon mot de passe?',
  'EMAIL.CONTENT.HOW_TO_CHANGE_PASSWORD_A': 'Vous pouvez le faire dans l\'application via l\'élément de menu "Profil". Nous vous recommandons de modifier votre mot de passe lors de votre prochaine connexion.',
  'EMAIL.CONTENT.NEW_MESSAGE': 'Un nouveau message a été créé',
  'EMAIL.CONTENT.REPAIR.NEW': 'Un nouveau rapport de dommages a été créé.',
  'EMAIL.CONTENT.KEYORDER.NEW': 'Une nouvelle commande de clé ou de carte a été créée.',
  'EMAIL.CONTENT.NAMEPLATE.NEW': 'Une nouvelle commande de plaque signalétique a été soumise.',
  'EMAIL.CONTENT.DOCUMENT.NEW': 'Un nouveau document ou formulaire de commande a été soumis.',
  'EMAIL.CONTENT.OTHER.NEW': 'Un nouveau message divers a été enregistré.',
  'EMAIL.CONTENT.PINBOARD.NEW': "Une nouvelle entrée dans le tableau d'affichage a été créée.",
  'EMAIL.CONTENT.VOTEITEM.NEW': "Un nouvel élément de vote a été créé. Vous pouvez facilement l'ajouter à un vote existant ou à un nouveau vote dans l'application d'administration.",
  'EMAIL.CONTENT.RESERVATION.NEW': 'Une nouvelle réservation a été créée.',
  'EMAIL.CONTENT.RESERVATION.REMINDER': 'Ceci est un rappel automatique concernant votre réservation {{title}}.',
  'EMAIL.CONTENT.EXTERNAL.SUBMITTED': 'La candidature a été soumise en utilisant le formulaire de contact accessible au public.',
  'EMAIL.CONTENT.EXTERNAL.VISIBLE': "Visible uniquement par les administrateurs jusqu'à ce qu'une propriété soit attribuée.",
  'EMAIL.CONTENT.FORWARDED_MESSAGE': "Un message de l'ImmoApp vous a été transmis.",
  'EMAIL.CONTENT.REPAIR.FORWARDED': "Un rapport de dommages de l'ImmoApp vous a été transmis.",
  'EMAIL.CONTENT.KEYORDER.FORWARDED': "Une commande de clé ou de carte de l'ImmoApp vous a été transmise.",
  'EMAIL.CONTENT.NAMEPLATE.FORWARDED': "Une commande de badge nominatif de l'ImmoApp vous a été transmise.",
  'EMAIL.CONTENT.DOCUMENT.FORWARDED': "Un commande d'un document ou d'un formulaire de l'ImmoApp vous a été transmis.",
  'EMAIL.CONTENT.OTHER.FORWARDED': "Un message divers de l'ImmoApp vous a été transmis.",
  'EMAIL.CONTENT.PINBOARD.FORWARDED': "Un entrée dans le tableau d'affichage de l'ImmoApp vous a été transmis.",
  'EMAIL.CONTENT.VOTEITEM.FORWARDED': "Un point à l'ordre du jour de l'ImmoApp vous a été transmis.",
  'EMAIL.CONTENT.RESERVATION.FORWARDED': "Une réservation de l'ImmoApp vous a été transmise.",
  'EMAIL.CONTENT.REPAIR.UPDATED': 'Le rapport de dommages "{{entryTitle}}" a été mis à jour.',
  'EMAIL.CONTENT.RESERVATION.UPDATED': 'Le réservation {{entryTitle}} a été mis à jour.',
  'EMAIL.CONTENT.KEYORDER.UPDATED': 'La commande de clé ou de carte "{{entryTitle}}" a été mis à jour.',
  'EMAIL.CONTENT.NAMEPLATE.UPDATED': 'L\'ordre des balises de nom "{{entryTitle}}" a été mis à jour.',
  'EMAIL.CONTENT.DOCUMENT.UPDATED': 'La commande de document ou de formulaire "{{entryTitle}}" a été mise à jour.',
  'EMAIL.CONTENT.VOTEITEM.UPDATED': 'L\'élément de vote "{{entryTitle}}" a été mis à jour.',
  'EMAIL.CONTENT.OTHER.UPDATED': 'Le message divers "{{entryTitle}}" a été mis à jour.',
  'EMAIL.CONTENT.NEW_STATUS': 'Nouveau statut',
  'EMAIL.CONTENT.NO_DESCRIPTION': 'Sans description',
  'EMAIL.CONTENT.UPDATED_BY': 'Mis à jour par',
  'EMAIL.CONTENT.INITIAL_SENDER': 'Expéditeur initial',
  'EMAIL.CONTENT.NEW_COMMENT_MESSAGE': 'Un nouveau commentaire a été ajouté au message "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_REPAIR': 'Un nouveau commentaire a été ajouté au rapport de dommages "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_KEYORDER': 'Un nouveau commentaire a été ajouté à la commande de clé ou de carte "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_NAMEPLATE': 'Un nouveau commentaire a été ajouté à l\'ordre des balises de nom "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_DOCUMENT': 'Un nouveau commentaire a été saisi pour l\'ordre de document ou de formulaire "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_OTHER': 'Un nouveau commentaire a été ajouté au message divers "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_PINBOARD': 'Un nouveau commentaire a été ajouté au tableau d\'affichage "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_RESERVATION': 'Un nouveau commentaire a été ajouté à la réservation "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_VOTEITEM': 'Un nouveau commentaire a été saisi sur le point de l\'ordre du jour "{{entryTitle}}".',
  'EMAIL.CONTENT.NOW_AVAILABLE.VOTE': 'Le vote "{{title}}" est maintenant disponible.',
  'EMAIL.CONTENT.NOW_AVAILABLE.SURVEY': 'Le sondage "{{title}}" est maintenant disponible.',
  'EMAIL.CONTENT.VOTE_WITH_CODE': "Vous pouvez maintenant voter avec le code d'accès suivant",
  'EMAIL.CONTENT.SUBMIT_THANK_YOU.VOTE': "Merci d'avoir participé au vote",
  'EMAIL.CONTENT.SUBMIT_THANK_YOU.SURVEY': "Merci d'avoir participé au sondage",
  'EMAIL.CONTENT.SUBMIT_RESULTS.DEFAULT': 'Ci-joint une copie des résultats de votre vote.',
  'EMAIL.CONTENT.SUBMIT_RESULTS.WARRANT': 'Ci-joint une copie de votre procuration.',
  'EMAIL.CONTENT.VOTE_RESULT_PDF_TITLE.DEFAULT': 'Résultats du vote',
  'EMAIL.CONTENT.VOTE_RESULT_PDF_TITLE.WARRANT': 'Procuration',
  'EMAIL.CONTENT.KEYORDER.QUANTITY': 'Quantité',
  'EMAIL.CONTENT.KEYORDER.LABEL': 'Étiquette de clé',
  'EMAIL.CONTENT.NAMEPLATE.ROW1': 'Ligne 1',
  'EMAIL.CONTENT.NAMEPLATE.ROW2': 'Ligne 2',
  'EMAIL.CONTENT.ATTACHMENTS_REMOVED': "Les pièces jointes ajoutées par l'expéditeur dépassent la limite de 25 Mo et ont donc été supprimées de cet e-mail. Vous pouvez consulter les pièces jointes dans l'ImmoApp.",
  'EMAIL.CONTENT.WARRANT.GIVEN': 'Vous avez donné procuration à {{warrantPartnerName}} ({{warrantPartnerEmail}}) pour le vote "{{voteTitle}}" ({{voteMandateName}}).',
  'EMAIL.CONTENT.WARRANT.RECEIVED': 'Vous avez reçu une procuration de {{warrantPartnerName}} ({{warrantPartnerEmail}}) pour le vote "{{voteTitle}}" ({{voteMandateName}}).',
  'EMAIL.CONTENT.WARRANT.ATTACHED_COPY': 'Ci-joint une copie de la procuration.',
  'EMAIL.CONTENT.INVITE.FUTURE.1': 'La liste suivante montre tous les {{quantity}} utilisateurs qui seront invités à {{time}} (CET).',
  'EMAIL.CONTENT.INVITE.FUTURE.2': 'Remarque: Si vous ne le souhaitez pas, vous pouvez désactiver l\'envoi automatique des invitations dans la WebApp sous "Gérer la plateforme" > "ImmoApp" > "Connexion ERP" > "Créer un utilisateur automatiquement". Dans ce cas, veuillez également contacter le support technique d\'ImmoDigi AG afin que les ajustements nécessaires puissent être effectués.',
  'EMAIL.CONTENT.INVITE.PAST.1': "La liste suivante affiche tous les {{quantity}} utilisateurs qui viennent d'être créés et invités avec succès.",
  'EMAIL.CONTENT.INVITE.SKIPPED': "({{quantity}} utilisateurs ont été ignorés. Cette limite existe pour éviter d'éventuelles erreurs d'importation. Exécutez à nouveau l'action dans l'application Web afin que les utilisateurs restants reçoivent également une invitation.)",
  'EMAIL.CONTENT.EXTERNAL': "Votre administration a enregistré un nouveau message dans l'ImmoApp.",
  'EMAIL.CONTENT.PREVIEW': "Remarque: Cet e-mail n'est qu'un aperçu et ne contient donc aucune pièce jointe.",
  'EMAIL.CONTENT.TITLE_OF_MESSAGE': 'Titre du message',
  'EMAIL.CONTENT.TICKET_ASSIGNMENT1': 'Une nouvelle tâche vous a été assignée par {{administrationName}}.',
  'EMAIL.CONTENT.TICKET_ASSIGNMENT2': "Cliquez {{link}} pour ouvrir la commande dans l'ImmoApp.",
  'EMAIL.CONTENT.EMAIL_CHANGE_1': 'Nous souhaitons vous informer que votre adresse email de votre compte ImmoApp vient d\'être modifiée en "{{newEmail}}".',
  'EMAIL.CONTENT.PASSWORD_CHANGE_1': "Nous souhaitons vous informer que votre mot de passe de votre compte ImmoApp vient d'être modifié.",
  'EMAIL.CONTENT.EMAIL_PASSWORD_CHANGE_2': "Si vous n'avez pas effectué cette modification, veuillez contacter immédiatement notre équipe d'assistance à {{supportEmail}}.",
  'EMAIL.CONTENT.EMAIL_PASSWORD_CHANGE_3': 'Merci de prêter attention à la sécurité de votre compte ImmoApp.',
  'EMAIL.CONTENT.RESERVATION.DETAILS_TITLE': 'Détails de la réservation',
  'EMAIL.CONTENT.RESERVATION.DETAILS_DESCRIPTION': '{{place}} réservée le {{date}} de {{time_start}} à {{time_end}}',
  'EMAIL.CONTENT.ACCOUNT_DELETED.INTRO': "Comme vous l'avez demandé, nous avons supprimé avec succès votre compte ImmoApp.",
  'EMAIL.CONTENT.ACCOUNT_DELETED.REVERT': "Si la suppression n'était pas intentionnelle, cliquez sur {{link}} pour réactiver votre compte.",
  'EMAIL.CONTENT.ACCOUNT_DELETED.QUESTIONS': 'Si vous avez des questions, veuillez contacter {{email}}.',

  'EMAIL.FOOTER.UNSUBSCRIBE.INTRO': "Cet e-mail est envoyé automatiquement aux utilisateurs d'ImmoApp par {{contact}}.",
  'EMAIL.FOOTER.UNSUBSCRIBE.PUBLIC': 'Si vous ne souhaitez plus recevoir ces emails, écrivez-nous à: {{contact}}.',
  'EMAIL.FOOTER.UNSUBSCRIBE.SETTINGS': "Si vous ne souhaitez plus recevoir ces e-mails, vous pouvez les désactiver dans les paramètres d'{{immoappLink}}.",
  'EMAIL.FOOTER.GREETING': 'Meilleures salutations',
  'EMAIL.FOOTER.LEGAL': 'Logiciel fourni par ImmoDigi AG.',
  'EMAIL.FOOTER.EXTERNAL': "Ouvrez le message directement dans l'ImmoApp. Avec l'ImmoApp vous recevez toutes les dernières actualités de votre bien directement sur votre smartphone. Vous pouvez télécharger l'ImmoApp depuis {{appStoreLink}} et {{playStoreLink}} ou l'ouvrir directement dans le {{browserLink}}. Vous avez déjà reçu vos données d'accès personnelles de votre administration par e-mail. Si vous ne les possédez plus, vous pouvez utiliser la fonction \"Réinitialisation du mot de passe\" dans l'ImmoApp.<br/>Si vous ne souhaitez plus recevoir ces e-mails, veuillez vous connecter à ImmoApp et modifier les paramètres de notification.",

  'INTRO_PDF.TITLE': "Introduction à l'ImmoApp",
  'INTRO_PDF.WELCOME.TITLE': 'Introduction',
  'INTRO_PDF.WELCOME.TEXT': "Bienvenue et merci de l'intérêt que vous portez à l'ImmoApp. Dans le document suivant, vous trouverez beaucoup d'informations et d'explications pour un démarrage réussi avec l'ImmoApp. Si vous avez des questions, n'hésitez pas à nous contacter à tout moment",
  'INTRO_PDF.SEE_VIDEO': "Pour un aperçu très bref, vous pouvez également regarder notre vidéo d'information d'une minute et demie",
  'INTRO_PDF.DISCOVER_IMMOAPP': "Découvrez l'ImmoApp",
  'INTRO_PDF.DOWNLOAD_IMMOAPP': "Télécharger et lancer l'ImmoApp",
  'INTRO_PDF.LINKS.INTRO': "Vous pouvez utiliser l'ImmoApp via les liens suivants",
  'INTRO_PDF.LINKS.IOS': 'Pour iOS',
  'INTRO_PDF.LINKS.ANDROID': 'Pour Android',
  'INTRO_PDF.LINKS.BROWSER': 'Pour le Web / Navigateur',
  'INTRO_PDF.START_LOGIN': "Lancez l'ImmoApp et connectez-vous avec les informations d'identification que nous vous avons envoyées par e-mail.",
  'INTRO_PDF.HOME.TITLE': "Écran d'accueil",
  'INTRO_PDF.HOME.OVERVIEW': "Une fois que vous avez démarré l'ImmoApp, vous verrez l'écran d'accueil avec les sections suivantes",
  'INTRO_PDF.HOME.A.TEXT': "A) La zone d'accueil affiche votre photo de profil à gauche, ainsi qu'un texte d'information et une photo pour l'un de vos biens.",
  'INTRO_PDF.HOME.A.CLICK1': "Un clic sur le bouton rond blanc vous permet d'accéder à une vue d'ensemble de tous vos biens.",
  'INTRO_PDF.HOME.A.CLICK2': 'Un clic sur le bouton "Rapport de dommages" ouvre une nouvelle fenêtre pour la saisie du rapport de dommages.',
  'INTRO_PDF.HOME.B.TITLE.WITH_PINBOARD': 'B) Tous les messages, entrées sur le punaiseur et rapports de dommages, triés par mois.',
  'INTRO_PDF.HOME.B.TITLE.NO_PINBOARD': 'B) Tous les messages et rapports de dommages, triés par mois.',
  'INTRO_PDF.HOME.B.CLICK': 'Un clic sur une entrée ouvre une nouvelle vue avec plus de détails.',
  'INTRO_PDF.IMPORTANT_CONTACTS.TITLE': 'Contacts importants',
  'INTRO_PDF.IMPORTANT_CONTACTS.TEXT': "Un clic sur le bouton rond blanc de A) ouvre une vue d'ensemble de tous vos biens. Sélectionnez un bien et vous serez dirigé vers une page détaillée où toutes les coordonnées importantes sont listées.",
  'INTRO_PDF.REPORT.TITLE': "Vue détaillée d'un rapport",
  'INTRO_PDF.REPORT.TEXT_1': "Cliquez maintenant sur une entrée de l'écran d'accueil pour voir la vue détaillée.",
  'INTRO_PDF.REPORT.TEXT_2': "Dans la partie supérieure, vous pouvez voir les images jointes, le cas échéant. En balayant vers la gauche et la droite, vous pouvez voir plus d'images.",
  'INTRO_PDF.REPORT.TEXT_3': "S'il s'agit d'un ticket, toutes les avancées précédentes sont affichées dans un historique clair: de la création à la réussite, les locataires et les propriétaires restent bien informés à tout moment.",
  'INTRO_PDF.REPORT.TEXT_4': 'La zone inférieure contient les pièces jointes et les commentaires ajoutés au message.',
  'INTRO_PDF.REPORT.TEXT_5': 'Vous pouvez modifier ou supprimer un commentaire soumis si nécessaire.',
  'INTRO_PDF.DOCUMENTS.TITLE': 'Documents',
  'INTRO_PDF.DOCUMENTS.TEXT_1': "Vous pouvez passer aux documents via la barre de navigation (en bas de l'ImmoApp).",
  'INTRO_PDF.DOCUMENTS.TEXT_2': "Si vous savez déjà ce que vous cherchez, vous pouvez saisir le nom du fichier dans la barre de recherche de la page d'accueil.",
  'INTRO_PDF.DOCUMENTS.TEXT_3': "Sinon, cliquez sur une propriété pour accéder à son dossier principal. Cliquez dans le système de fichiers jusqu'à ce que vous atteigniez le document que vous recherchez et ouvrez-le d'un clic.",
  'INTRO_PDF.DOCUMENTS.TEXT_4': 'Astuce: Si les documents sont un peu trop petits pour être lus, vous pouvez également les exporter et les afficher sur un écran plus grand.',
  'INTRO_PDF.ADD_REPORT.TITLE': 'Ajouter un message',
  'INTRO_PDF.ADD_REPORT.TEXT_1': "A l'aide du bouton rond coloré situé en bas au centre de l'ImmoApp, vous pouvez accéder à une sélection de rapports que vous pouvez soumettre à l'administration.",
  'INTRO_PDF.ADD_REPORT.TEXT_2': "Sélectionnez l'une des options et une nouvelle fenêtre s'ouvrira dans laquelle vous pourrez saisir des informations telles que le lieu, le titre et la description. En outre, des images, des vidéos et des documents peuvent être ajoutés en option.",
  'INTRO_PDF.ADD_REPORT.TEXT_3': "En cas de déclaration de sinistre, vous pouvez également préciser si le message doit être public, et donc tous les locataires et propriétaires du bien seront automatiquement informés, ou si le message ne sera visible que par vous et l'administration.",
  'INTRO_PDF.VOTES.TITLE': 'Votes',
  'INTRO_PDF.VOTES.A': "A) Vue d'ensemble: Cliquez sur un vote.",
  'INTRO_PDF.VOTES.B': 'B) Vue détaillée: Enregistrez-vous ici.',
  'INTRO_PDF.VOTES.C': "C) Code d'accès: Une fois que le vote est actif, vous pouvez entrer le code.",
  'INTRO_PDF.VOTES.D': 'D) Ordre du jour: Aperçu de tous les points du vote.',
  'INTRO_PDF.VOTES.E': 'E) Vote: Cliquez sur chaque point de vote et sélectionnez une option.',
  'INTRO_PDF.VOTES.F': 'F) Soumettre: Une fois que tout est sélectionné, vous pouvez soumettre le vote.',
  'INTRO_PDF.PROFILE.TITLE': 'Profil',
  'INTRO_PDF.PROFILE.TEXT_1': "La barre de navigation (en bas de l'ImmoApp) vous permet d'accéder à votre profil.",
  'INTRO_PDF.PROFILE.TEXT_2': "En cliquant sur l'image ronde en haut, vous pouvez ajouter une photo de profil à votre compte.",
  'INTRO_PDF.PROFILE.TEXT_3': 'Cette image de profil sera affichée à côté des commentaires que vous créez.',
  'INTRO_PDF.PROFILE.TEXT_4': "Dans l'écran de profil, vous trouverez également de nombreuses informations utiles, telles que la possibilité d'accéder à nouveau à ce guide et les coordonnées de l'administration.",
  'INTRO_PDF.APP_SETTINGS.TITLE': "Paramètres de l'application",
  'INTRO_PDF.APP_SETTINGS.TEXT_1': "Dans l'écran \"Profil -> Paramètres de l'application\", vous pouvez changer la langue de l'ImmoApp et activer ou désactiver les notifications",
  'INTRO_PDF.APP_SETTINGS.TEXT_2': 'Notifications push (activées par défaut).',
  'INTRO_PDF.APP_SETTINGS.TEXT_3': 'Notifications par email (désactivées par défaut).',
  'INTRO_PDF.ADMIN.TITLE': "Découvrir l'Admin-App",
  'INTRO_PDF.ADMIN_INIT.TITLE': "Lancer l'application Admin",
  'INTRO_PDF.ADMIN_INIT.TEXT_1': "Vous pouvez lancer l'Admin-App via le lien suivant:",
  'INTRO_PDF.ADMIN_INIT.TEXT_2': "Appelez-le maintenant et connectez-vous avec vos données d'accès.",
  'INTRO_PDF.ADMIN_START.TITLE': "Page d'accueil",
  'INTRO_PDF.ADMIN_START.TEXT_1': "Sur la page d'accueil, vous voyez un aperçu des biens immobiliers, des contacts importants, de l'état actuel des tickets, ainsi que les dernières nouvelles et les entrées sur le tableau d'affichage.",
  'INTRO_PDF.ADMIN_NEWS.TITLE': 'Nouvelles',
  'INTRO_PDF.ADMIN_NEWS.TEXT_1': 'L\'option de menu "Nouvelles" contient des messages généraux et des nouvelles pertinentes. Le tableau vous indique le titre, le destinataire, la date d\'envoi, le statut et le nombre de commentaires. Sous "Action", vous avez la possibilité',
  'INTRO_PDF.ADMIN_NEWS.TEXT_1.1': "d'éditer, de dupliquer, de supprimer ou de compléter le message par un commentaire.",
  'INTRO_PDF.ADMIN_NEWS.TEXT_1.2': 'ajouter un commentaire au message.',
  'INTRO_PDF.ADMIN_NEWS.TEXT_2': "Pour envoyer un nouveau message, cliquez sur le bouton bleu 'Ajouter un message'. Une fenêtre popup s'ouvre ensuite, dans laquelle vous pouvez saisir toutes les informations.",
  'INTRO_PDF.ADMIN_TICKETS.TITLE': 'Billets',
  'INTRO_PDF.ADMIN_TICKETS.TEXT_1': 'Dans l\'option de menu "Tickets", vous obtenez un aperçu de tous les tickets et de leur statut actuel. Vous avez la possibilité d\'ajouter un nouveau ticket via le grand bouton bleu.',
  'INTRO_PDF.ADMIN_TICKETS.TEXT_2': "Dans les tickets, vous avez également la possibilité de rédiger un commentaire via le bouton d'action sur le bord droit. Votre commentaire sera directement ajouté au ticket et sera visible par vos voisins (si le ticket est public).",
  'INTRO_PDF.ADMIN_PINBOARD.TITLE': "Tableau d'affichage",
  'INTRO_PDF.ADMIN_PINBOARD.TEXT_1': "La barre de navigation vous permet d'accéder au tableau d'affichage. La structure est similaire à celle des tickets, de sorte que nous renonçons à une nouvelle explication détaillée.",
  'INTRO_PDF.ADMIN_DOCUMENTS.TITLE': 'Documents',
  'INTRO_PDF.ADMIN_DOCUMENTS.TEXT_1': "Vous pouvez passer aux documents via la barre de navigation. Vous voyez d'abord une liste de tous les biens immobiliers qui vous sont attribués. Cliquez sur n'importe quel bien immobilier pour naviguer vers son dossier principal. Cliquez à travers le système de fichiers jusqu'à ce que vous arriviez au document souhaité et ouvrez-le en cliquant dessus.",
  'INTRO_PDF.ADMIN_ACCESS.TITLE': "Gérer les droits d'accès",
  'INTRO_PDF.ADMIN_ACCESS.TEXT_1': 'Dans la colonne "Accès", vous voyez tous les groupes d\'utilisateurs et les utilisateurs individuels qui ont accès à ce dossier. Par exemple, dans la capture d\'écran ci-dessus, le groupe d\'utilisateurs "Locataire" n\'a pas accès au premier dossier.',
  'INTRO_PDF.ADMIN_ACCESS.TEXT_2': "Pour ajuster les droits d'accès, cliquez sur l'icône \"crayon\" sous Action et sélectionnez ensuite les rôles d'utilisateurs et les utilisateurs souhaités",
  'INTRO_PDF.ADMIN_VOTES.TITLE': 'Votes',
  'INTRO_PDF.ADMIN_VOTES.TEXT_1': 'Naviguez maintenant vers le point de menu "Votes". Vous voyez ici tous les rapprochements actifs et clôturés de votre immeuble.',
  'INTRO_PDF.ADMIN_VOTES.POINTS.TITLE': 'Points de vote',
  'INTRO_PDF.ADMIN_VOTES.POINTS.TEXT_1': 'En cliquant sur un vote, vous accédez à la vue détaillée. Vous y trouverez également les différents points de vote, y compris les fichiers joints.',
  'INTRO_PDF.ADMIN_MANDATES.TITLE': 'Immobilier',
  'INTRO_PDF.ADMIN_MANDATES.TEXT_1': "Dans l'écran principal de la zone de gestion, vous voyez toutes les propriétés qui vous ont été attribuées, y compris l'adresse, le nombre de locataires/propriétaires et la connexion au système de fichiers",
  'INTRO_PDF.ADMIN_MANDATES_DETAIL.TITLE': "Vue détaillée d'un bien immobilier",
  'INTRO_PDF.ADMIN_MANDATES_DETAIL.TEXT_1': "Cliquez sur une ligne du tableau pour ouvrir la vue détaillée d'un bien immobilier. Cette page contient tous les détails sur les biens immobiliers, les objets, les gestionnaires, les propriétaires et les locataires. L'état de la connexion des documents ainsi que le nombre de dossiers et de fichiers sont également affichés",
  'INTRO_PDF.ADMIN_USERS.TITLE': 'Utilisateur',
  'INTRO_PDF.ADMIN_USERS.TEXT_1': 'Naviguez maintenant vers le dernier point du menu "Utilisateurs". Vous voyez ici tous les utilisateurs actuels de l\'ImmoApp et du domaine de gestion.',
  'INTRO_PDF.ADMIN_USERS.TEXT_2': 'En cliquant sur la flèche ronde dans la colonne "Action", vous pouvez envoyer à un utilisateur un nouvel e-mail d\'invitation contenant de nouvelles données d\'accès et la documentation habituelle sur "l\'introduction à ImmoApp" (adaptée au rôle de l\'utilisateur en question)',
  'INTRO_PDF.CONTACT.TITLE': 'Contact',
  'INTRO_PDF.CONTACT.TEXT_1': 'Nous espérons que vous avez apprécié la "tournée" de l\'ImmoApp et que nous avons réussi à susciter votre intérêt.',
  'INTRO_PDF.CONTACT.TEXT_2': "Si vous avez d'autres questions, n'hésitez pas à nous contacter à tout moment",
  'INTRO_PDF.CONTACT.TEXT_3': 'Cordialement',
  'INTRO_PDF.FOOTER.PAGE': 'Page',

  'ACCOUNT_REACTIVATED.SUCCESS': 'Vous avez réactivé votre compte avec succès.',

  STARTING_ADMINAPP_KEYS: '---',
  STARTING_FORMGUIDE_KEYS: '---',

  'FORM.GO_TO_FORM': 'Aller directement au formulaire',
  'FORM.REPAIR.TITLE.WHERE': 'Où faut-il réparer ?',
  'FORM.REPAIR.TITLE.WHAT': "Qu'est-ce qui est défectueux ?",
  'FORM.REPAIR.DETAILS.TITLE': 'Détermination des dommages',
  'FORM.REPAIR.APARTMENT': 'Appartement',
  'FORM.REPAIR.APARTMENT.DESCRIPTION': 'Cuisine, salle de bain, salon',
  'FORM.REPAIR.COMMONSPACE': 'Les aménagements publics',
  'FORM.REPAIR.COMMONSPACE.DESCRIPTION': 'Ascenseur, garage, cave',
  'FORM.REPAIR.COMMERCIAL': 'Commerce',
  'FORM.REPAIR.COMMERCIAL.DESCRIPTION': 'Bureau, boutiques, restauration',
  'FORM.REPAIR.EXTERIOR': 'Extérieur',
  'FORM.REPAIR.EXTERIOR.DESCRIPTION': 'Espaces verts, façade',

  'FORM.REPAIR.1X': '1',
  'FORM.REPAIR.2X': '2',
  'FORM.REPAIR.3X': '3',
  'FORM.REPAIR.4X': '4',
  'FORM.REPAIR.5X': '5',
  'FORM.REPAIR.6X': '6',
  'FORM.REPAIR.7X': '7',
  'FORM.REPAIR.8X': '8',
  'FORM.REPAIR.ALL': 'Tous les',
  'FORM.REPAIR.ALMACASA': "Locaux d'entreprise Almacasa",
  'FORM.REPAIR.APARTMENT.WHOLE': "Tout l'appartement",
  'FORM.REPAIR.APARTMENTDOOR': "Porte de l'appartement",
  'FORM.REPAIR.ATTIC': 'Chape',
  'FORM.REPAIR.ATTICDOOR': 'Porte de la chape',
  'FORM.REPAIR.ATTICLADDER': 'Trappe, échelle de chape',
  'FORM.REPAIR.ATTICLADDER.BROKEN': 'Cassé',
  'FORM.REPAIR.ATTICLADDER.CEILINGFLAP': 'Trappe de plafond',
  'FORM.REPAIR.ATTICLADDER.CEILINGFLAP.CROOKED': 'Incliné, oblique',
  'FORM.REPAIR.ATTICLADDER.CEILINGFLAP.HINGEDEFECT': 'Charnières défectueuses, arrachées',
  'FORM.REPAIR.ATTICLADDER.FOLD': 'Trappes de grenier, déplier',
  'FORM.REPAIR.ATTICLADDER.FOLD.NOTCLOSES': 'Ferme mal',
  'FORM.REPAIR.ATTICLADDER.FOLD.NOTOPENS': "N'ouvre pas correctement",
  'FORM.REPAIR.ATTICLADDER.FOLD.DIFFICULTY': "S'ouvre, se ferme difficilement",
  'FORM.REPAIR.ATTICLADDER.GRINDS': 'Effleure, se dresse',
  'FORM.REPAIR.ATTICLADDER.HANDRAIL': 'Main courante',
  'FORM.REPAIR.ATTICLADDER.LOCK': 'Serrure, fermeture',
  'FORM.REPAIR.ATTICLADDER.LOCK.NOTCLOSES': 'Ne reste pas fermé',
  'FORM.REPAIR.ATTICLADDER.LOCK.TORN': 'Arraché',
  'FORM.REPAIR.ATTICLADDER.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.ATTICLADDER.STAIRS': 'Échelle, escalier',
  'FORM.REPAIR.ATTICLADDER.STAIRS.ROPETORN': 'Câble métallique rompu',
  'FORM.REPAIR.ATTICLADDER.STAIRS.SPRINGDEFECT': 'Ressort défectueux',
  'FORM.REPAIR.ATTICLADDER.STAIRS.STEPLOOSE': 'Marche détachée, desserrée',
  'FORM.REPAIR.ATTICLADDER.STAIRS.TORN': 'Marche arrachée',
  'FORM.REPAIR.BALCONY': 'Balcon',
  'FORM.REPAIR.BALCONY.BALCONYDOOR': 'Porte de balcon, de terrasse',
  'FORM.REPAIR.BATHROOM': 'Bain, WC',
  'FORM.REPAIR.BATHROOM.FURNITURE': 'Meubles de salle de bains',
  'FORM.REPAIR.BATHROOM.FURNITURE.BROKEN': 'Cassé, fissuré',
  'FORM.REPAIR.BATHROOM.FURNITURE.CISTERN': 'Réservoir de chasse meuble défectueux',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE': 'Bord du lavabo',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE.CRACK': 'Fissure',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE.FRAYED': 'Effiloché',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE.PEELING': 'Revêtement qui se détache',
  'FORM.REPAIR.BATHROOM.FURNITURE.LOOSE': 'Relâché, bancal',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR': 'Miroir',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR.FALLEN': 'Chute',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR.SCRATCHED': 'Rayé',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR.STAINS': 'A des taches, est terni',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET': 'Miroir armoire',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.LAMPCRACKS': 'Lampe qui craque',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.LAMPDEFECT': 'Ampoule défectueuse',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.LIGHTSHIELDDEFECT': 'Diaphragmes lumineux défectueux',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SHELFDEFECT': 'Tablette défectueuse',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SHELFHOLDERDEFECT': 'Support de tablette défectueux',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SHELFMISSING': 'Tablette manquante',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SOCKETDEFECT': 'Prise de courant défectueuse',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR': 'miroir armoire porte',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HANDLEDEFECT': 'Poignée défectueuse',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HANGSDOWN': 'Suspendu',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HINGEDEFECT': 'Charnières défectueuses, arrachées',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HINGEFALLEN': 'Charnières tombées',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.RUBBERMISSING': 'Tampon en caoutchouc manquant',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK': 'Lavabo meuble',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK.CHAFINGPOINT': 'Point de frottement',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK.HOLE': 'Trou',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK.STAINS': 'Taches, décoloration',
  'FORM.REPAIR.BATHROOM.FURNITURE.SWOLLEN': 'Gonflé',
  'FORM.REPAIR.BATHROOM.MEN': 'Vestiaires hommes',
  'FORM.REPAIR.BATHROOM.TOILET': 'WC, toilettes',
  'FORM.REPAIR.BATHROOM.TOILET.BOWL': 'Toilettes cuvette',
  'FORM.REPAIR.BATHROOM.TOILET.BOWL.HANDRAILDEFECT': 'Barre de maintien branlante, arrachée',
  'FORM.REPAIR.BATHROOM.TOILET.BOWL.ROLLHOLDERDEFECT': 'Distributeur de papier WC défectueux',
  'FORM.REPAIR.BATHROOM.TOILET.BROKEN': 'Cassé, fissuré',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN': 'Rinçage, écoulement, etc',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.CLOGGED': 'Bouché',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.DRAINSSLOW': "S'écoule lentement",
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.LITTLEPRESSURE': "Peu de pression d'eau",
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.NOWATER': "Pas ou peu d'eau",
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.OVERFLOW': 'Débordement',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.SEWAGE': 'Eau polluée',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.WATERRINSEDEFECT': "La chasse d'eau fuit",
  'FORM.REPAIR.BATHROOM.TOILET.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.BATHROOM.TOILET.SEAT': 'Siège de toilette',
  'FORM.REPAIR.BATHROOM.SHOWER': 'Baignoire, douche',
  'FORM.REPAIR.BATHROOM.SHOWER.BACKWATER': "Les eaux usées s'échappent de l'évacuation",
  'FORM.REPAIR.BATHROOM.SHOWER.BATHTUB': 'Baignoire',
  'FORM.REPAIR.BATHROOM.SHOWER.CHALKY': 'Calcaire',
  'FORM.REPAIR.BATHROOM.SHOWER.CLOGGED': 'Bouché',
  'FORM.REPAIR.BATHROOM.SHOWER.CRACK': 'Fissure',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN': 'Rideau, cabine de douche',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.RODDEFECT': 'Barre de rideau défectueuse',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.GLIDERDEFECT': 'Patin de rideau défectueux',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.CUBICLEDEFECT': 'Cabine de douche défectueuse',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.SEALDEFECT': 'Joint en caoutchouc de la porte défectueux',
  'FORM.REPAIR.BATHROOM.SHOWER.DIRTY': 'Sale',
  'FORM.REPAIR.BATHROOM.SHOWER.FITTINGS': 'Robinetterie',
  'FORM.REPAIR.BATHROOM.SHOWER.HANDRAIL': 'Barre de maintien',
  'FORM.REPAIR.BATHROOM.SHOWER.HANDRAIL.BROKEN': 'Cassé, fissuré',
  'FORM.REPAIR.BATHROOM.SHOWER.HANDRAIL.TOINSTALL': 'A installer',
  'FORM.REPAIR.BATHROOM.SHOWER.HOLE': 'Trou',
  'FORM.REPAIR.BATHROOM.SHOWER.JOINT': 'Joints',
  'FORM.REPAIR.BATHROOM.SHOWER.JOINT.DISSOLVES': 'Se détache',
  'FORM.REPAIR.BATHROOM.SHOWER.JOINTDEFECT': 'Joint de silicone non étanche',
  'FORM.REPAIR.BATHROOM.SHOWER.LEAKY': 'Fuite',
  'FORM.REPAIR.BATHROOM.SHOWER.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.BATHROOM.SHOWER.MOLDY': 'Moisi',
  'FORM.REPAIR.BATHROOM.SHOWER.NOISE': 'Glouglou / Bruit',
  'FORM.REPAIR.BATHROOM.SHOWER.NOTDRAINING': "S'écoule à peine",
  'FORM.REPAIR.BATHROOM.SHOWER.QUIRK': 'Défaut',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERHEAD': 'Douche, tuyau',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERHEAD.DRIPS': 'Goutte',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERHEAD.SPLASHES': 'Éclaboussures',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERTRAY': 'Bac à douche',
  'FORM.REPAIR.BATHROOM.SHOWER.SMELLS': 'Pue',
  'FORM.REPAIR.BATHROOM.SHOWER.STAINS': 'A des taches, est terni',
  'FORM.REPAIR.BATHROOM.SHOWER.WORN': 'Usé',
  'FORM.REPAIR.BATHROOM.WOMEN': 'Vestiaires Femmes',
  'FORM.REPAIR.BEDROOM': 'Chambre à coucher',
  'FORM.REPAIR.BENCH': 'Banc de parc',
  'FORM.REPAIR.BENCH.LOOSE': 'Décontracté, bancal',
  'FORM.REPAIR.BENCH.DIRTY': 'Taché, usé',
  'FORM.REPAIR.BENCH.FALLINGAPART': "S'écroule",
  'FORM.REPAIR.BIKEROOM': 'Espace pour les vélos',
  'FORM.REPAIR.BIKESTAND': 'Support à vélos',
  'FORM.REPAIR.BIKESTAND.DEFECT': 'Défectueux',
  'FORM.REPAIR.BIKESTAND.LOOSE': 'Relâché, bancal',
  'FORM.REPAIR.BIKESTAND.MISSING': 'Manque',
  'FORM.REPAIR.BIKESHELTER': 'Abri, boîte à vélos',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX': 'Abri pour vélo',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.BROKENROOF': 'Toit défectueux',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.DOORDEFECT': 'Porte défectueuse',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.LOOSE': 'Desserré, branlant',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.NOTDRAINING': "L'eau de pluie ne s'écoule pas",
  'FORM.REPAIR.BOILERROOM': 'Chaufferie',
  'FORM.REPAIR.BUILDINGENTRANCE': 'Entrée de la maison',
  'FORM.REPAIR.CABINETBUILTIN': 'Placard encastré',
  'FORM.REPAIR.CABINETNORMAL': 'Armoire',
  'FORM.REPAIR.CABINET.BASE.BROKEN': 'Cassé, fissuré',
  'FORM.REPAIR.CABINET.BASE.JOINTLEAKS': 'Joint non étanche, se détache',
  'FORM.REPAIR.CABINET.BRACKETDEFECT': 'Support défectueux',
  'FORM.REPAIR.CABINET.BRACKETMISSING': 'Support manquant',
  'FORM.REPAIR.CABINET.DOOR': "Porte d'armoire",
  'FORM.REPAIR.CABINET.DOOR.EDGESDEFECT': 'Chants défectueux',
  'FORM.REPAIR.CABINET.DOOR.EDGESMISSING': 'Bord manquant, cassé',
  'FORM.REPAIR.CABINET.DOOR.GRINDS': 'Frôle, se dresse',
  'FORM.REPAIR.CABINET.DOOR.HINGEBROKEN': 'Charnières défectueuses, arrachées',
  'FORM.REPAIR.CABINET.DRAWER': 'Tiroir',
  'FORM.REPAIR.CABINET.DRAWER.NOTOPEN': "Ne s'ouvre pas correctement",
  'FORM.REPAIR.CABINET.DRAWER.REMAINSOPEN': 'Ferme mal',
  'FORM.REPAIR.CABINET.DRAWER.RUSTED': 'Rouillé',
  'FORM.REPAIR.CABINET.DRAWER.WORN': 'Déchiré',
  'FORM.REPAIR.CABINET.HANDLEDEFECT': 'Poignée défectueuse',
  'FORM.REPAIR.CABINET.HANGSDOWN': 'Suspendu',
  'FORM.REPAIR.CABINET.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.CABINET.MISSING': 'Manque',
  'FORM.REPAIR.CABINET.MOLD': 'Moisi',
  'FORM.REPAIR.CABINET.RAIL': 'Barre de penderie',
  'FORM.REPAIR.CABINET.RAIL.DEFECT': 'Défaut',
  'FORM.REPAIR.CABINET.SHELF': 'Tablette',
  'FORM.REPAIR.CABINET.SHELF.CROOKED': 'Courbe',
  'FORM.REPAIR.CABINET.SHELF.PEELING': 'Revêtement qui se détache',
  'FORM.REPAIR.CABINET.SHELF.WORN': 'Usure',
  'FORM.REPAIR.CABINET.SWOLLEN': 'Gonflé',
  'FORM.REPAIR.CABINET.WALLSFLOOR': 'Murs, sol, ...',
  'FORM.REPAIR.CABINET.WALLSFLOOR.REARWALLBROKEN': 'Panneau arrière cassé',
  'FORM.REPAIR.CABINET.WALLSFLOOR.REARWALLBACK': "Paroi arrière poussée vers l'arrière",
  'FORM.REPAIR.CABINET.WALLSFLOOR.WORN': 'Usé',
  'FORM.REPAIR.CANOPY': 'Avant-toit',
  'FORM.REPAIR.CANOPY.GLASSBROKEN': 'Auvent en verre cassé',
  'FORM.REPAIR.CANOPY.NOTDRAINING': "L'eau de pluie ne s'écoule pas",
  'FORM.REPAIR.CANOPY.OVERFLOWS': "L'eau de pluie déborde",
  'FORM.REPAIR.CARESUPPORT': 'Centre de soins',
  'FORM.REPAIR.CARESUPPORT.MEDICINECABINET': 'Armoire médicale',
  'FORM.REPAIR.CARESUPPORT.MEDICINEFRIDGE': 'Armoire à pharmacie',
  'FORM.REPAIR.CEILING': 'Plafond',
  'FORM.REPAIR.CELLAR': 'cave',
  'FORM.REPAIR.CELLAR.COMPARTMENTDOOR': 'Porte de compartiment de cave',
  'FORM.REPAIR.CELLARCOMPARTMENT': 'Compartiment de cave',
  'FORM.REPAIR.CELLARDOOR': 'Porte de cave',
  'FORM.REPAIR.CLEANING': 'Nettoyage',
  'FORM.REPAIR.COLORDISSOLVING': 'La peinture se dissout',
  'FORM.REPAIR.COMMERCIAL.BEVERAGES': 'Entrepôt de boissons',
  'FORM.REPAIR.COMMERCIAL.COSANUM': 'Entrepôt de cosanum',
  'FORM.REPAIR.COMMERCIAL.DISPOSAL': "Salle d'élimination",
  'FORM.REPAIR.COMMERCIAL.DRYSTORAGE': 'Stockage à sec',
  'FORM.REPAIR.COMMERCIAL.FURNITURE': 'Meuble',
  'FORM.REPAIR.COMMERCIAL.MEETINGROOM': 'Salle de réunion',
  'FORM.REPAIR.COMMERCIAL.MULTIPURPOSE': 'Salle polyvalente de Pfungen',
  'FORM.REPAIR.COMMERCIAL.SILENCEROOM': 'Espace de silence',
  'FORM.REPAIR.COMMERCIAL.STAFFROOM': 'Salle de repos des employés',
  'FORM.REPAIR.COMMERCIAL.STORAGE.FOOD': 'Entrepôt alimentaire UG',
  'FORM.REPAIR.COMMERCIAL.STORAGE.FOOD.COLDROOM': 'Chambre froide',
  'FORM.REPAIR.COMMERCIAL.STUDIO': 'Atelier Regensdorf',
  'FORM.REPAIR.COMMERCIAL.UTILITYROOM': 'Buanderie',
  'FORM.REPAIR.COMMONSPACE.OTHER': 'Autre lieu',
  'FORM.REPAIR.CONTAINERROOM': 'Espace conteneur',
  'FORM.REPAIR.CORRIDOR.APARTMENT': "Couloir, entrée de l'appartement",
  'FORM.REPAIR.CORRIDOR.COMMERCIAL': "Couloir, zone d'entrée",
  'FORM.REPAIR.CRACK': 'Fissure',
  'FORM.REPAIR.DAMAGED': 'Endommagé',
  'FORM.REPAIR.DISPLAYDEFECT': 'Écran défectueux',
  'FORM.REPAIR.DRAIN': 'Écoulement de sol garage',
  'FORM.REPAIR.DOOR': 'Porte de chambre',
  'FORM.REPAIR.DOOR.OPENINGCLOSING': 'Ouvrir & fermer',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.BADCLOSING': 'Ferme mal',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.DIFFICULTCLOSING': 'Ouvre, ferme difficilement',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.GRINDS': "effleure, s'arrête",
  'FORM.REPAIR.DOOR.OPENINGCLOSING.REMAINSOPEN': 'Ne reste pas fermé',
  'FORM.REPAIR.DOOR.DOOR': 'Porte',
  'FORM.REPAIR.DOOR.DOOR.BROKEN': 'Forcé',
  'FORM.REPAIR.DOOR.DOOR.GLASSPANELDEFECT': 'Vitre défectueuse',
  'FORM.REPAIR.DOOR.DOOR.HINGEBROKEN': 'Charnières défectueuses, arrachées',
  'FORM.REPAIR.DOOR.DOOR.HOLE': 'Trou',
  'FORM.REPAIR.DOOR.DOOR.LIGHT': 'Influence de la lumière',
  'FORM.REPAIR.DOOR.DOOR.SPLIT': 'fendu',
  'FORM.REPAIR.DOOR.DOORBELL': 'Sonnette de porte',
  'FORM.REPAIR.DOOR.DOORBELL.CHANGENAME': 'Changement de plaque signalétique',
  'FORM.REPAIR.DOOR.DOORBELL.DEFECT': 'Sonnette défectueuse',
  'FORM.REPAIR.DOOR.DOORBELL.MISSING': 'Plaque manquante',
  'FORM.REPAIR.DOOR.DOORSTEP': 'seuil de porte',
  'FORM.REPAIR.DOOR.DOORSTEP.BARBROKEN': 'Profilé de transition cassé',
  'FORM.REPAIR.DOOR.DOORSTEP.BARLOOSE': 'Profilé de transition détaché',
  'FORM.REPAIR.DOOR.DOORSTEP.BARMISSING': 'Profilé de transition manquant',
  'FORM.REPAIR.DOOR.DOORSTEP.SEALINGDEFECT': 'Joint de silicone défectueux',
  'FORM.REPAIR.DOOR.FRAME': 'Cadre de porte',
  'FORM.REPAIR.DOOR.FRAME.BROKEN': 'Brisé',
  'FORM.REPAIR.DOOR.FRAME.HINGEBROKEN': 'Charnières défectueuses, arrachées',
  'FORM.REPAIR.DOOR.FRAME.SEALINGDEFECT': "Joint d'étanchéité défectueux",
  'FORM.REPAIR.DOOR.FRAME.SEALINGMISSING': 'Joint manquant',
  'FORM.REPAIR.DOOR.FRONTDOOR': "Porte d'entrée",
  'FORM.REPAIR.DOOR.KNOB': 'Poignée de porte',
  'FORM.REPAIR.DOOR.KNOB.FALLSAPART': "S'écarte",
  'FORM.REPAIR.DOOR.KNOB.HANGSDOWN': "S'affaisse",
  'FORM.REPAIR.DOOR.KNOB.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.DOOR.KNOB.MISSING': 'Manque',
  'FORM.REPAIR.DOOR.KNOB.WORN': 'Très usé',
  'FORM.REPAIR.DOOR.LOCK': 'Serrure',
  'FORM.REPAIR.DOOR.LOCK.BOLTBLOCKS': 'Pêne bloqué',
  'FORM.REPAIR.DOOR.LOCK.BROKEN': 'Forcé',
  'FORM.REPAIR.DOOR.LOCK.BROKENKEY': 'Clé cassée',
  'FORM.REPAIR.DOOR.LOCK.COVERMISSING': 'Serrures couvertures manquantes',
  'FORM.REPAIR.DOOR.LOCK.LATCHBLOCKS': 'Pêne demi-tour bloqué',
  'FORM.REPAIR.DOOR.LOCK.MISSINGKEY': 'Clé manquante',
  'FORM.REPAIR.DOOR.LOCK.NOTWORKING': 'Ouverture, fermeture défectueuse',
  'FORM.REPAIR.DOOR.LOCK.PLATELOOSE': 'Gâche desserrée',
  'FORM.REPAIR.DOOR.RAMPDEFECT': 'Rampe défectueuse',
  'FORM.REPAIR.DOOR.TRAPPINGDEFECT': 'Sas anti-saleté défectueux',
  'FORM.REPAIR.ELECTRICITY': 'Électricité, lumière',
  'FORM.REPAIR.ELECTRICITY.ENTRANCELIGHT': "Eclairage de l'entrée",
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT': 'Eclairage extérieur',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.DAMAGED': 'Endommagé',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.LIGHTSDURINGDAY': "S'allume pendant la journée",
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.SCREENDEFECT': "Diaphragmes d'éclairage défectueux",
  'FORM.REPAIR.ELECTRICITY.FUSEOUT': 'Le fusible saute',
  'FORM.REPAIR.ELECTRICITY.GARAGELIGHT': 'Éclairage du garage',
  'FORM.REPAIR.ELECTRICITY.GARAGELIGHT.AUTOSWITCHONDEFECT': 'Mise en marche automatique défectueuse',
  'FORM.REPAIR.ELECTRICITY.GARAGELIGHT.AUTOSWITCHOFFDEFECT': 'Arrêt automatique défectueux',
  'FORM.REPAIR.ELECTRICITY.INTERIORLIGHT': 'Eclairage intérieur',
  'FORM.REPAIR.ELECTRICITY.INTERIORLIGHT.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.ELECTRICITY.INTERIORLIGHT.SCREENDEFECT': 'Cache-lumière défectueux',
  'FORM.REPAIR.ELECTRICITY.LIGHT.BURNMARK': 'Tache de brûlure',
  'FORM.REPAIR.ELECTRICITY.LIGHT.DEFECT': 'Ampoule défectueuse',
  'FORM.REPAIR.ELECTRICITY.LIGHT.FLICKERING': 'Scintille',
  'FORM.REPAIR.ELECTRICITY.LIGHT.SMELLS': 'Pue le brûlé',
  'FORM.REPAIR.ELECTRICITY.NOPOWER': 'Pas de courant',
  'FORM.REPAIR.ELECTRICITY.NOPOWER.APARTMENT': 'Appartement entier / surface commerciale',
  'FORM.REPAIR.ELECTRICITY.NOPOWER.BUILDING': 'Bâtiment entier',
  'FORM.REPAIR.ELECTRICITY.NOPOWER.ONLYROOM': 'Une seule pièce ou un seul groupe de courant',
  'FORM.REPAIR.ELECTRICITY.POWER': 'Courant',
  'FORM.REPAIR.ELECTRICITY.SOCKET': 'Prise de courant',
  'FORM.REPAIR.ELECTRICITY.SOCKET.BROKEN': 'Cadre cassé',
  'FORM.REPAIR.ELECTRICITY.SOCKET.DIRTY': 'Sale',
  'FORM.REPAIR.ELECTRICITY.SOCKET.LOOSE': 'Relâché, bancal',
  'FORM.REPAIR.ELECTRICITY.SOCKET.SMELLS': 'Pue le brûlé',
  'FORM.REPAIR.ELECTRICITY.STAIRWELLLIGHT': "Eclairage de l'escalier maison&shy",
  'FORM.REPAIR.ELECTRICITY.SWITCH': "Interrupteur d'éclairage, variateur",
  'FORM.REPAIR.ELECTRICITY.SWITCH.BROKEN': 'Cadre cassé',
  'FORM.REPAIR.ELECTRICITY.SWITCH.MALFUNCTION': 'Défaut',
  'FORM.REPAIR.ELECTRICITY.SWITCH.SMELLS': 'Pue',
  'FORM.REPAIR.ELECTRICITY.WIRE': 'Câble',
  'FORM.REPAIR.ELECTRICITY.WIRE.HANGSDOWN': 'Suspendu',
  'FORM.REPAIR.ELECTRICITY.WIRE.SMELLS': 'Pue',
  'FORM.REPAIR.ERROR': "Message d'erreur",
  'FORM.REPAIR.EXTERIORSHAFT': 'Couvercle de regard (extérieur)',
  'FORM.REPAIR.FACADE': 'Façade, toit',
  'FORM.REPAIR.FACADE.BRICK': 'Tuile',
  'FORM.REPAIR.FACADE.BRICK.BROKEN': 'Cassé',
  'FORM.REPAIR.FACADE.BRICK.MISSING': 'Manquant',
  'FORM.REPAIR.FACADE.BRICK.POSTPONED': 'Déplacé',
  'FORM.REPAIR.FACADE.FACADEWALL': 'Façade',
  'FORM.REPAIR.FACADE.FACADEWALL.MOLD': 'Moisissures sur la façade',
  'FORM.REPAIR.FACADE.FACADEWALL.PEELING': "La façade s'écaille",
  'FORM.REPAIR.FACADE.RAINGUTTER': 'Gouttière',
  'FORM.REPAIR.FACADE.RAINGUTTER.CLOGGED': 'Bouché',
  'FORM.REPAIR.FACADE.RAINGUTTER.DEFECT': 'Défectueux',
  'FORM.REPAIR.FACADE.RAINGUTTER.DOWNPIPECLOGGED': 'Tuyau de descente bouché',
  'FORM.REPAIR.FACADE.RAINGUTTER.DOWNPIPEDEFECT': 'Tuyau de descente défectueux',
  'FORM.REPAIR.FACADE.RAINGUTTER.LICKS': 'Fuite',
  'FORM.REPAIR.FILLINGDRILLHOLE': 'Fermer le trou de forage',
  'FORM.REPAIR.FIREPROTECTION': "protection contre l'incendie",
  'FORM.REPAIR.FIREPROTECTION.SMOKESENSOR': 'Détecteur de fumée',
  'FORM.REPAIR.FLOORDOOR': "Porte d'étage",
  'FORM.REPAIR.FLOOR': 'Sol',
  'FORM.REPAIR.FLOOR.SURFACE': 'Revêtement de sol, surface',
  'FORM.REPAIR.FLOOR.SURFACE.BULGES': 'Bombé vers le haut',
  'FORM.REPAIR.FLOOR.SURFACE.BURNMARK': 'Tache de brûlure',
  'FORM.REPAIR.FLOOR.SURFACE.CHAFEMARK': 'Marque de frottement',
  'FORM.REPAIR.FLOOR.SURFACE.DISSOLVING': 'Revêtement qui se détache',
  'FORM.REPAIR.FLOOR.SURFACE.LIGHTSHADE': 'Ombre de lumière: claire, sombre',
  'FORM.REPAIR.FLOOR.SURFACE.LOOSE': 'Blocs détachés',
  'FORM.REPAIR.FLOOR.SURFACE.QUIRK': 'Bosse',
  'FORM.REPAIR.FLOOR.SURFACE.SCRATCH': 'Rayé',
  'FORM.REPAIR.FLOOR.SURFACE.STAINS': 'Taches, décoloration',
  'FORM.REPAIR.FLOOR.SURFACE.WATERDAMAGE': 'Dégâts des eaux',
  'FORM.REPAIR.FLOOR.SURFACE.WORNOUT': 'Déchirure',
  'FORM.REPAIR.FLOOR.TILES': 'Carreaux',
  'FORM.REPAIR.FLOOR.TILES.BROKEN': 'Cassé',
  'FORM.REPAIR.FLOOR.TILES.JOINTSDEFECT': 'Joint défectueux',
  'FORM.REPAIR.FLOOR.TILES.LOOSE': 'Décontracté, branlant',
  'FORM.REPAIR.FLOOR.TILES.WORNOUT': 'Sale, usé',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS': 'Plinthe',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.GAP': 'Espace au sol',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.LOOSE': 'Relâché, bancal',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.MISSING': 'Manque',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.WORNOUT': 'Déchiré',
  'FORM.REPAIR.FLOOR.JOINTS': 'Joints de sol',
  'FORM.REPAIR.FLOOR.JOINTS.DISSOLVING': 'Se détache',
  'FORM.REPAIR.FLOOR.JOINTS.MISSING': 'Manque',
  'FORM.REPAIR.FLOOR.JOINTS.MOLD': 'Moisi',
  'FORM.REPAIR.FLOOR.DOORSTEP': 'Seuil de porte, talon',
  'FORM.REPAIR.FLOOR.DOORSTEP.BARBROKEN': 'Profilé de transition cassé',
  'FORM.REPAIR.FLOOR.DOORSTEP.BARLOOSE': 'Profilé de transition détaché',
  'FORM.REPAIR.FLOOR.DOORSTEP.BARMISSING': 'Profilé de transition manquant',
  'FORM.REPAIR.FLOOR.DOORSTEP.WORNOUT': 'déchiré',
  'FORM.REPAIR.FLOOR.DRAIN': 'Écoulement du sol (intérieur)',
  'FORM.REPAIR.GARAGE': 'Garage',
  'FORM.REPAIR.GARAGEDOOR': 'Porte de garage',
  'FORM.REPAIR.GARAGEGATE': 'Porte de garage',
  'FORM.REPAIR.GARAGEGATE.DRAINDEFECT': "Écoulement d'eau de pluie défectueux",
  'FORM.REPAIR.GARAGEGATE.ENGINE': 'Moteur',
  'FORM.REPAIR.GARAGEGATE.ENGINE.DEFECT': 'Défectueux',
  'FORM.REPAIR.GARAGEGATE.ENGINE.NOISE': 'Bruit inhabituel',
  'FORM.REPAIR.GARAGEGATE.GATE': 'Porte de garage, suspension',
  'FORM.REPAIR.GARAGEGATE.GATE.BENT': 'Bombé',
  'FORM.REPAIR.GARAGEGATE.GATE.BROKEN': 'Brisé',
  'FORM.REPAIR.GARAGEGATE.GATE.DISENGAGED': 'Décroché',
  'FORM.REPAIR.GARAGEGATE.GATE.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.GARAGEGATE.GATE.NOISE': 'Fait un bruit inhabituel',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.KEYDEFECT': 'Clé ouverture défectueuse',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.KEYPOSTDEFECT': 'Clé ouverture poteau défectueuse',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.NOOPENS': "N'ouvre plus",
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.NOTCLOSES': 'Ne se ferme pas',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.REMOTEDEFECT': "L'ouverture à distance ne fonctionne pas",
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.STUCK': 'Coincé',
  'FORM.REPAIR.GARAGEGATE.RUSTEDDOORSTEP': 'Seuil rouillé',
  'FORM.REPAIR.GARBAGE': 'Niveau de poubelle',
  'FORM.REPAIR.GARBAGE.BROKEN': 'Benne à ordures, conteneur défectueux',
  'FORM.REPAIR.GARDEN': 'Espaces verts, jardin du locataire',
  'FORM.REPAIR.GREENAREA': 'Végétalisation',
  'FORM.REPAIR.GREENAREA.BRANCHESFELL': 'Branches tombées',
  'FORM.REPAIR.GREENAREA.BRANCHESHANG': 'Branches tombées',
  'FORM.REPAIR.GREENAREA.HEDGESUNCUT': 'Haies non taillées',
  'FORM.REPAIR.GREENAREA.UNMOWED': 'Pelouse non tondue',
  'FORM.REPAIR.GUESTAREA': 'Salle de restaurant',
  'FORM.REPAIR.HEATING': 'Chauffage du bâtiment',
  'FORM.REPAIR.HEATING.DEFECT': 'En panne',
  'FORM.REPAIR.HEATING.INSUFFICIENT': 'Chauffe insuffisamment',
  'FORM.REPAIR.HEATING.NOISE': 'Bruit (par ex. taper)',
  'FORM.REPAIR.HEATING.OILEMPTY': "Réservoir d'huile: presque vide ou vide",
  'FORM.REPAIR.HEATING.PELLETEMPTY': 'Stockage des pellets: presque vide ou vide',
  'FORM.REPAIR.HEATING.WOODCHIPEMPTY': 'Stockage de copeaux de bois: presque vide ou vide',
  'FORM.REPAIR.HEATINGVENTILATION': 'Chauffage, ventilation',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING': 'Chauffage au sol',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.COLD': 'Reste froid',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.CONTROLLERDEFECT': 'Régulateur défectueux',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.NOTWARMINGUP': 'Ne chauffe pas tout le sol',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.WATERDAMAGE': 'Dégâts des eaux',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR': 'Radiateur',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.COLD': 'Reste froid',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.COLORDISSOLVING': "La peinture s'écaille",
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.CONTROLLERDEFECT': 'Régulateur défectueux',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.DRIPPING': 'Goutte',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.GROUNDWATERDAMAGE': 'Dégâts des eaux au sol',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.NOTWARMINGUP': 'Ne chauffe plus correctement',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.NOISE': 'Fait du bruit',
  'FORM.REPAIR.HEATINGVENTILATION.THERMOSTAT': 'Thermostat',
  'FORM.REPAIR.HEATINGVENTILATION.THERMOSTAT.DEFECT': 'Ne fonctionne plus',
  'FORM.REPAIR.HEATINGVENTILATION.THERMOSTAT.HANGSDOWN': 'Suspendu',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION': 'Ventilation',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION.DEFECT': 'Ne fonctionne plus',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION.LOWPERFORMANCE': 'Puissance trop faible',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION.NOISE': 'Fait du bruit',
  'FORM.REPAIR.HOLE': 'Trou',
  'FORM.REPAIR.HOMESTAIRS': "Escalier (dans l'appartement)",
  'FORM.REPAIR.HOTWATER': 'Eau chaude',
  'FORM.REPAIR.HOTWATER.NOHOTWATER': "Pas d'eau chaude",
  'FORM.REPAIR.HOTWATER.NOHOTWATER.ONECONNECTION': 'Un seul raccordement',
  'FORM.REPAIR.HOTWATER.NOHOTWATER.TITLE': "Sur combien de raccordements l'eau chaude n'arrive-t-elle pas ?",
  'FORM.REPAIR.HOTWATER.NOWARMWATER': "L'eau n'est pas bien chaude",
  'FORM.REPAIR.HOTWATER.NOWARMWATER.TITLE': "Sur combien de raccordements n'arrive que de l'eau chaude ?",
  'FORM.REPAIR.HOUSEDOOR': "Porte d'entrée de la maison",
  'FORM.REPAIR.INNERSHAFT': 'Couvercle de regard (intérieur)',
  'FORM.REPAIR.KITCHEN': 'Cuisine',
  'FORM.REPAIR.KITCHEN.APPLIANCES': 'Appareils de cuisine',
  'FORM.REPAIR.KITCHEN.APPLIANCES.BROKENLIGHT': 'Lumière cassée',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COFFEEMACHINE': 'Machine à café',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER': 'Cuisinière',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOB': 'Table de cuisson uniquement',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOBOVEN': 'Table de cuisson & four',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOBOVEN.LOOSE': 'Desserré, branlant',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOBOVEN.SWITCHONDEFECT': 'La mise en marche ne fonctionne pas',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.OVEN': 'Four uniquement',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER': 'Lave-vaisselle',
  'FORM.REPAIR.CUISINE.APPLIANCES.DISHWASHER.BACKWATER': "Les eaux usées s'échappent de l'évier",
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.BASKETDEFECT': 'Panier à couverts défectueux',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.FALLSOFF': 'La façade se détache',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.FOAMS': 'Mousse',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.GRINDS': 'La façade frôle, se dresse',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.LEAKING': 'Fuite: eau',
  'FORM.REPAIR.CUISINE.APPLIANCES.DISHWASHER.LOUD': 'Bourdonnement bruyant',
  'FORM.REPAIR.CUISINE.APPLIANCES.DISHWASHER.NOTCLEAN': 'Ne lave pas proprement',
  'FORM.REPAIR.CUISINE.APPLIANCES.DISHWASHER.NOTDRIES': 'Ne sèche plus',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.WATERINMACHINE': "L'eau stagne dans la machine",
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR': "Hotte d'aspiration",
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.CLOGGED': 'Filtre bouché',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.DRIPS': 'Goutte',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.LOUD': 'Bip / trop fort / fait du bruit',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.POWERDEFECT': 'Ne décolle pas / trop faible',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.REGULATIONDEFECT': 'Ne se laisse pas régler',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.SMELLS': 'Pue',
  'FORM.REPAIR.KITCHEN.APPLIANCES.FREEZER': 'Congélateur armoire',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.BROKEN': 'Cassé, fissuré',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.BROKENFRAME': 'Cadre cassé',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.BURNMARK': 'Marque de brûlure',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.JOINTLEAKS': 'Joint non étanche, se détache',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.LOOSE': 'Desserré, branlant',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.SWITCHDEFECT': 'La mise en marche ne fonctionne pas',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.WORN': 'Usé',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE': 'Micro-ondes',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE.HANDLEDEFECT': 'Poignée défectueuse',
  'FORM.REPAIR.CUISINE.APPLIANCES.MICROWAVE.LOCKDEFECT': 'Sécurité de porte endommagée',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE.TURNTABLEDEFECT': 'Plateau tournant défectueux',
  'FORM.REPAIR.KITCHEN.APPLIANCES.NOELECTRICITY': 'Pas de courant',
  'FORM.REPAIR.KITCHEN.APPLIANCES.NOTHOT': 'Ne chauffe pas',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN': 'Four',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.GLASSDEFECT': 'Vitre défectueuse',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.REPLACEACCESSORIES': 'Remplacer / compléter les accessoires',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.SEALDEFECT': "Joint d'étanchéité poreux, défectueux",
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.SMELL': 'Forte odeur',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR': 'Réfrigérateur',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.CONDENSATION': 'Condensation',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.GLASSDEFECT': 'Tablette en verre, étagère défectueuse',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR': 'Porte',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.BOTTLEHOLDERDEFECT': 'Porte-bouteilles défectueux',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.CRACKS': 'Craque',
  'FORM.REPAIR.CUISINE.APPLIANCES.REFRIGERATOR.PORTE.GRINDS': "frôle, s'arrête",
  'FORM.REPAIR.CUISINE.APPLIANCES.REFRIGERATOR.PORTE.HANGSDOWN': 'La façade pend',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.HANDLEDEFECT': 'Poignée défectueuse',
  'FORM.REPAIR.CUISINE.APPLIANCES.REFRIGERATOR.PORTE.NOTCLOSE': 'Ne reste pas fermée',
  'FORM.REPAIR.CUISINE.APPLIANCES.REFRIGERATOR.DOORCOMPARTMENTSDEFECT': 'Étagères de porte défectueuses',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DRAWERDEFECT': 'Tiroir à légumes, compartiment à légumes défectueux',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.FREEZERDOORDEFECT': 'Porte du congélateur défectueuse',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.FREEZES': 'Givre fréquent',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.LOUD': 'Bourdonne bruyamment',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.NOCOOLS': 'Ne refroidit plus',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.SEALDEFECT': 'Le joint ne tient pas',
  'FORM.REPAIR.KITCHEN.APPLIANCES.STEAMER': 'Steamer',
  'FORM.REPAIR.KITCHEN.APPLIANCES.STEAMER.LEAKING': 'Fuite: eau',
  'FORM.REPAIR.KITCHEN.APPLIANCES.STEAMER.TANKDEFECT': "Réservoir d'eau défectueux",
  'FORM.REPAIR.KITCHEN.APPLIANCES.SWITCHDEFECT': 'Interrupteur défectueux',
  'FORM.REPAIR.KITCHEN.HOB': 'Table de cuisson',
  'FORM.REPAIR.KITCHEN.WORKTOP': 'Plan de travail',
  'FORM.REPAIR.KITCHEN.WORKTOP.EDGE': 'Chant',
  'FORM.REPAIR.KITCHEN.WORKTOP.EDGE.CRACK': 'Fissure',
  'FORM.REPAIR.KITCHEN.WORKTOP.EDGE.FRAYS': 'Franchissement de',
  'FORM.REPAIR.KITCHEN.WORKTOP.JOINT': 'Joint',
  'FORM.REPAIR.KITCHEN.WORKTOP.JOINT.DISSOLVES': 'Se détache',
  'FORM.REPAIR.KITCHEN.WORKTOP.JOINT.MOLDY': 'Moisi',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE': 'Surface',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.BROKEN': 'Cassé',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.BURNMARK': 'Tache de brûlure',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.CHAFING': 'Zone de frottement',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.HOLE': 'Trou',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.WATERSTAINS': "Taches d'eau",
  'FORM.REPAIR.KITCHEN.WORKTOP.SWOLLEN': 'Gonflé',
  'FORM.REPAIR.KITCHEN.FURNITURE': 'Meubles de cuisine',
  'FORM.REPAIR.KITCHEN.FURNITURE.APOTHECARYCABINET': 'Armoire à pharmacie',
  'FORM.REPAIR.KITCHEN.FURNITURE.AUTOFEEDDEFECT': 'Alimentation automatique défectueuse',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE': 'Socle',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE.BROKEN': 'Cassé',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE.DEPRESSED': 'Enfoncé',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE.JOINTDEFECT': 'Joint défectueux',
  'FORM.REPAIR.KITCHEN.FURNITURE.DRAWER': 'Tiroir',
  'FORM.REPAIR.KITCHEN.FURNITURE.DRAWER.INSERTMISSING': 'Rangement à couverts manquant',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS': 'Portes, façades',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.CUSHIONINGDEFECT': 'Amortissement défectueux',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.EDGEDEFECT': 'Chants défectueux',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.EDGEMISSING': 'Chant manquant',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.GRINDS': 'Rayure, dépassement',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.HINGEDEFECT': 'Charnière défectueuse',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.SNAPPERDEFECT': 'Loquet défectueux',
  'FORM.REPAIR.KITCHEN.FURNITURE.HANDLEDEFECT': 'Poignée défectueuse',
  'FORM.REPAIR.KITCHEN.FURNITURE.HANGSDOWN': 'Suspendu',
  'FORM.REPAIR.KITCHEN.FURNITURE.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.KITCHEN.FURNITURE.MOLDY': 'Moisi',
  'FORM.REPAIR.KITCHEN.FURNITURE.NOTOPENS': "Ne s'ouvre pas correctement",
  'FORM.REPAIR.KITCHEN.FURNITURE.NOTCLOSES': 'Ne se ferme pas correctement',
  'FORM.REPAIR.KITCHEN.FURNITURE.PANSPULLOUT': 'Casseroles, bouteilles extraites',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF': 'Tablette',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.BRACKETDEFECT': 'Support défectueux',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.BRACKETMISSING': 'Support manquant',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.CROOKED': 'Courbé',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.MISSING': 'Manquant',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.SURFACEDEFECT': 'Surface défectueuse',
  'FORM.REPAIR.KITCHEN.FURNITURE.SWOLLEN': 'Gonflé',
  'FORM.REPAIR.KITCHEN.FURNITURE.WALLS': 'Murs, sol, ...',
  'FORM.REPAIR.KITCHEN.FURNITURE.WALLS.BROKEN': 'Paroi arrière cassée',
  'FORM.REPAIR.KITCHEN.FURNITURE.WALLS.WALLPRESSED': 'Panneau arrière enfoncé',
  'FORM.REPAIR.KITCHEN.FURNITURE.WORN': 'Usé',
  'FORM.REPAIR.KITCHEN.WASTE': 'Système de poubelles',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN': 'Poubelle',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.BRACKETTORN': 'Support de fond arraché',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.CONNECTIONDEFECT': 'Raccord de porte défectueux',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.LIDHOLDERDEFECT': 'Support de couvercle défectueux',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.LIDTORN': 'Couvercle, support incliné déchiré',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.MISSING': 'Manquant',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.WORN': 'Usé',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR': 'Seau à déchets de fond de meuble',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.EDGEDEFECT': 'Bords défectueux',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.MOLDY': 'Moisi',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.SWOLLEN': 'Gonflé',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.WORN': 'Déchiré',
  'FORM.REPAIR.KITCHEN.WASTE.SUSPENSION': 'Suspension',
  'FORM.REPAIR.KITCHEN.WASTE.SUSPENSION.BROKEN': 'Cassé, fissuré',
  'FORM.REPAIR.KITCHEN.WASTE.SUSPENSION.LOOSE': 'Relâché, bancal',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR': 'Extrait ou porte',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.AUTOFEEDDEFECT': 'Alimentation automatique défectueuse',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.GRINDS': "Raye, s'arrête",
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.HANDLEDEFECT': 'Poignée défectueuse',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.NOTCLOSES': 'Ferme mal',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.NOTOPENS': "N'ouvre pas correctement",
  'FORM.REPAIR.KITCHEN.SINK': 'Évier',
  'FORM.REPAIR.KITCHEN.SINK.BACKWATER': "Les eaux usées s'échappent de l'évier",
  'FORM.REPAIR.KITCHEN.SINK.CLOGGED': 'Bouché',
  'FORM.REPAIR.KITCHEN.SINK.DRAIN': 'Évacuation',
  'FORM.REPAIR.KITCHEN.SINK.DRAIN.DRAINSSLOW': "S'écoule lentement",
  'FORM.REPAIR.KITCHEN.SINK.DRAIN.NOISE': 'Glouglou / Bruit',
  'FORM.REPAIR.KITCHEN.SINK.DRIPS': 'Goutte',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS': 'Armatures',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.CHALKY': 'calcaire',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.LITTLEPRESSURE': "Peu d'eau pression",
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.SEALDEFECT': "Joint d'étanchéité poreux, défectueux",
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.SPLASHES': 'Éclaboussures',
  'FORM.REPAIR.KITCHEN.SINK.JOINT': 'Joint',
  'FORM.REPAIR.KITCHEN.SINK.JOINT.DIRTY': 'Sale',
  'FORM.REPAIR.KITCHEN.SINK.JOINT.DISSOLVES': 'Se détache',
  'FORM.REPAIR.KITCHEN.SINK.JOINT.MOLDY': 'Moisi',
  'FORM.REPAIR.KITCHEN.SINK.LEAKY': 'Fuite',
  'FORM.REPAIR.KITCHEN.SINK.LOOSE': 'Desserré, branlant',
  'FORM.REPAIR.KITCHEN.SINK.NOPLUG': 'Bouchon manquant',
  'FORM.REPAIR.KITCHEN.SINK.PIPES': "Siphon, tuyaux d'évacuation",
  'FORM.REPAIR.KITCHEN.SINK.PIPES.LOOSE': 'En vrac, non raccordé',
  'FORM.REPAIR.KITCHEN.SINK.SUPPLYLINES': "Conduites d'alimentation",
  'FORM.REPAIR.KITCHEN.SINK.SINKBASIN': 'Évier',
  'FORM.REPAIR.KITCHEN.SINK.SINKBASIN.GRAZES': 'Égouttoir manquant / défectueux',
  'FORM.REPAIR.KITCHEN.SINK.SINKBASIN.WORN': 'Usé',
  'FORM.REPAIR.KITCHEN.SINK.SMELLS': 'Pue',
  'FORM.REPAIR.LAUNDRYROOM': 'Salle de lavage et de séchage',
  'FORM.REPAIR.LIFT': 'Ascenseur',
  'FORM.REPAIR.LIFT.ALARMBUTTON': "Bouton d'appel d'urgence défectueux",
  'FORM.REPAIR.LIFT.BURNINGSMELL': 'odeur de brûlé',
  'FORM.REPAIR.LIFT.CALLBUTTON': "Bouton d'appel (extérieur)",
  'FORM.REPAIR.LIFT.CONTROLPANEL': 'Tableau de cabine',
  'FORM.REPAIR.LIFT.DOOR': 'Porte',
  'FORM.REPAIR.LIFT.DOOR.CLOSESSLOWLY': 'Fermeture trop lente',
  'FORM.REPAIR.LIFT.DOOR.GRINDS': 'Ponçage',
  'FORM.REPAIR.LIFT.DOOR.NOTCLOSING': 'Ne se ferme pas',
  'FORM.REPAIR.LIFT.DOOR.SLAMS': 'claque (claque)',
  'FORM.REPAIR.LIFT.DOOR.SQUEAKS': 'grince',
  'FORM.REPAIR.LIFT.DOOR.STUCK': 'Coince',
  'FORM.REPAIR.LIFT.FAILURE': 'Défaillance du mode de déplacement',
  'FORM.REPAIR.LIFT.FAILURE.COMPLETE': 'Panne complète',
  'FORM.REPAIR.LIFT.FAILURE.JERKING': 'Bousculade',
  'FORM.REPAIR.LIFT.FAILURE.NOISE': 'Fait du bruit',
  'FORM.REPAIR.LIFT.FAILURE.SPORADICALLY': 'Panne sporadique',
  'FORM.REPAIR.LIFT.FAILURE.STOPS': "S'arrête au mauvais étage",
  'FORM.REPAIR.LIFT.INDICATOR': "Indicateur d'étage du vestibule",
  'FORM.REPAIR.LIFT.LIGHT': 'Lumière défectueuse',
  'FORM.REPAIR.LIFT.OBJECTINSHAFT': 'Objet dans la gaine',
  'FORM.REPAIR.LIFT.OUTSIDEPANEL': 'Tableau extérieur',
  'FORM.REPAIR.LIFT.POSITION': 'Position non alignée de la cabine position',
  'FORM.REPAIR.LIFT.WATERDAMAGE': "Infiltration d'eau",
  'FORM.REPAIR.LIVINGAREA': 'Salle de séjour',
  'FORM.REPAIR.LIVINGAREA.DESCRIPTION': 'Cuisine, salon',
  'FORM.REPAIR.LIVINGROOM': 'Salon',
  'FORM.REPAIR.MAILBOX': 'Boîte aux lettres',
  'FORM.REPAIR.MAILBOX.DOORDEFECT': 'Porte du casier à paquets défectueuse',
  'FORM.REPAIR.MAILBOX.FLAPDEFECT': "Trappe d'introduction défectueuse",
  'FORM.REPAIR.MAILBOX.LOCKDEFECT': 'Serrure défectueuse',
  'FORM.REPAIR.MAILBOX.LOOSE': 'Desserré, branlant',
  'FORM.REPAIR.MAILBOX.LOSTKEY': 'Clé perdue',
  'FORM.REPAIR.MAILBOX.TAGCHANGE': 'Changement de badge',
  'FORM.REPAIR.MAILBOX.TAGMISSING': 'Plaque nominative manquante',
  'FORM.REPAIR.MOIST': 'Humide',
  'FORM.REPAIR.MOLD': 'Moisissure, champignon',
  'FORM.REPAIR.MULTIMEDIA': 'Service multimédia (TV, Internet, fibre optique, ...)',
  'FORM.REPAIR.MULTIMEDIA.FIBERFAIL': 'Connexion fibre optique en panne',
  'FORM.REPAIR.MULTIMEDIA.SEALCONNECTION': 'Plombage du raccordement de câble',
  'FORM.REPAIR.MULTIMEDIA.UNSEALCONNECTION': 'Suppression du plombage sur le raccord de câble',
  'FORM.REPAIR.NURSERY': "Chambre d'enfant",
  'FORM.REPAIR.OFFICE': 'Bureau',
  'FORM.REPAIR.OTHER': 'Autre dommage',
  'FORM.REPAIR.OUTSIDEFLOOR': 'Sol extérieur',
  'FORM.REPAIR.OUTSIDEFLOOR.BIGJOINTS': 'Joints trop grands',
  'FORM.REPAIR.OUTSIDEFLOOR.BROKEN': 'Cassé, manquant',
  'FORM.REPAIR.OUTSIDEFLOOR.DIRTY': 'Décoloré, taches, sale',
  'FORM.REPAIR.OUTSIDEFLOOR.LOOSE': 'En vrac',
  'FORM.REPAIR.OUTSIDEFLOOR.UNEVEN': 'Surface inégale',
  'FORM.REPAIR.OUTSIDEFLOOR.WEEDSINJOINTS': 'Mauvaises herbes dans les joints',
  'FORM.REPAIR.PAINTING': 'Peindre',
  'FORM.REPAIR.PAINTING.DURATION.TITLE': 'Quand a-t-on peint pour la dernière fois ?',
  'FORM.REPAIR.PAINTING.MINUSFIVE': 'Moins de 5 ans',
  'FORM.REPAIR.PAINTING.NONSMOKERS': 'Non-fumeur',
  'FORM.REPAIR.PAINTING.PLUSFIVE': 'Plus de 5 ans',
  'FORM.REPAIR.PAINTING.SMOKERS': 'Fumeurs',
  'FORM.REPAIR.PAINTING.SMOKING.TITLE': 'A-t-on fumé ?',
  'FORM.REPAIR.PARKING': 'Parking',
  'FORM.REPAIR.PARKING.TIREBOX': 'Armoire à pneus, boîte à pneus',
  'FORM.REPAIR.PARKING.TIREBOX.LOOSE': 'Desserré, branlant',
  'FORM.REPAIR.PARKING.TIREBOX.LOCKDEFECT': 'Serrure défectueuse',
  'FORM.REPAIR.PARKING.TIREBOX.MISSINGKEY': 'Clé manquante',
  'FORM.REPAIR.PARKING.TIREBOX.BOXDEFECT': 'Coffre défectueux',
  'FORM.REPAIR.PLAYGROUND': 'Aire de jeu',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT': 'Équipement de jeu',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.FRAMEDEFECT': "Structure d'escalade défectueuse",
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.OTHERDEFECT': 'Autre équipement de jeu défectueux',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.SANDPITDEFECT': 'Bac à sable défectueux',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.SLIDEDEFECT': 'Toboggan défectueux',
  'FORM.REPAIR.PESTS': 'Parasites',
  'FORM.REPAIR.PESTS.ANTS': 'Fourmis',
  'FORM.REPAIR.PESTS.BEDBUGS': 'Punaises de lit',
  'FORM.REPAIR.PESTS.BIRDS': 'Pigeons / Moineaux / Oiseaux',
  'FORM.REPAIR.PESTS.COCKROACHES': 'Blattes',
  'FORM.REPAIR.PESTS.MICE': 'Souris / rats',
  'FORM.REPAIR.PESTS.OTHER': 'Autre parasite',
  'FORM.REPAIR.PESTS.SILVERFISH': "Poissons d'argent",
  'FORM.REPAIR.PESTS.WASPS': 'Guêpes',
  'FORM.REPAIR.RAILINGS': 'Garde-corps',
  'FORM.REPAIR.RAILINGS.BROKEN': 'Cassé',
  'FORM.REPAIR.RAILINGS.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.RAILINGS.RUSTY': 'Rouillé',
  'FORM.REPAIR.RAILINGS.STACKSDEFECT': 'Pile défectueuse',
  'FORM.REPAIR.RESIDENTSROOM': 'Chambre des résidents',
  'FORM.REPAIR.SIDEWALK': 'Trottoir',
  'FORM.REPAIR.SINGLEGARAGE': 'Garage individuel',
  'FORM.REPAIR.SINK': 'Bec verseur',
  'FORM.REPAIR.SINK.POTWASHER': 'Lave-pot',
  'FORM.REPAIR.SHADES': 'Ombrage et assombrissement',
  'FORM.REPAIR.SHADES.BLINDS': 'Volets',
  'FORM.REPAIR.SHADES.BLINDS.BRACKETBROKEN': 'Retenue cassée',
  'FORM.REPAIR.SHADES.BLINDS.GRINDS': 'Frôle, se tient debout',
  'FORM.REPAIR.SHADES.BLINDS.HANGSDOWN': 'Suspendu',
  'FORM.REPAIR.SHADES.BLINDS.HINGEDEFECT': 'Charnière défectueuse',
  'FORM.REPAIR.SHADES.BLINDS.HOLDERDEFECT': 'Support défectueux',
  'FORM.REPAIR.SHADES.BLINDS.JAMS': 'Coincé',
  'FORM.REPAIR.SHADES.BLINDS.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.SHADES.BLINDS.REMAINSOPEN': 'Ne se ferme pas',
  'FORM.REPAIR.SHADES.BLINDS.SLATSDEFECT': 'Lamelles défectueuses',
  'FORM.REPAIR.SHADES.BLINDS.WEATHERED': 'Dégradé par le temps',
  'FORM.REPAIR.SHADES.CANOPEN': 'Oui, les fenêtres peuvent être ouvertes',
  'FORM.REPAIR.SHADES.CANTOPEN': "Non, les fenêtres ne s'ouvrent pas",
  'FORM.REPAIR.SHADES.CONTROLLINGQUANTITY.TITLE': "Combien de stores sont commandés simultanément par l'interrupteur ?",
  'FORM.REPAIR.SHADES.CRANK': 'Manivelle',
  'FORM.REPAIR.SHADES.CRANKJOINT.TITLE': "L'articulation de la manivelle est-elle intacte ?",
  'FORM.REPAIR.SHADES.CRANKTURN.TITLE': 'La manivelle tourne-t-elle ?',
  'FORM.REPAIR.SHADES.CROOKED': 'Suspendu de travers',
  'FORM.REPAIR.SHADES.CURTAINRAIL': 'Rail rideau',
  'FORM.REPAIR.SHADES.CURTAINRAIL.MISSING': 'Manque',
  'FORM.REPAIR.SHADES.CURTAINRAIL.RIPPED': 'Arraché',
  'FORM.REPAIR.SHADES.CURTAINRAIL.STUCK': 'Patin, rideau ne coulisse pas',
  'FORM.REPAIR.SHADES.DETACHED': 'Hors du rail de guidage',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE': 'Électrique (télécommande)',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE.BATTERIES.TITLE': 'Avez-vous testé des piles neuves pour la télécommande ?',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE.NEWBATTERIES': 'Nouveau',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE.OLDBATTERIES': 'Ancien',
  'FORM.REPAIR.SHADES.ELECTRICALWALL': 'Électrique (interrupteur mural)',
  'FORM.REPAIR.SHADES.ENGINESOUND': 'Oui, un bruit de moteur',
  'FORM.REPAIR.SHADES.FABRICBLINDS': 'Stores en tissu',
  'FORM.REPAIR.SHADES.FABRICBLINDS.DEFECT': 'Tige de tissu défectueuse',
  'FORM.REPAIR.SHADES.FABRICBLINDS.KIND.TITLE': "De quel type de store en tissu s'agit-il ?",
  'FORM.REPAIR.SHADES.FABRICBLINDS.RIPPED': 'Tissu déchiré ou lâche',
  'FORM.REPAIR.SHADES.FABRICBLINDS.UNROLLS': 'Le tissu se déroule, le store ne se ferme pas',
  'FORM.REPAIR.SHADES.HAILDAMAGE': 'Dommages dus à la grêle',
  'FORM.REPAIR.SHADES.HANDCRANK': 'Manivelle',
  'FORM.REPAIR.SHADES.HANDCRANK.NOTOKAY': 'Pas OK',
  'FORM.REPAIR.SHADES.HANDCRANK.NOTTURN': 'Ne tourne pas',
  'FORM.REPAIR.SHADES.HANDCRANK.OKAY': 'OK',
  'FORM.REPAIR.SHADES.HANDCRANK.TURNSEMPTY': 'Tourne à vide',
  'FORM.REPAIR.SHADES.INSTALLATIONAGE.TITLE': "Quel est l'âge de l'installation ?",
  'FORM.REPAIR.SHADES.KIND.TITLE': "De quel type de store à lamelles s'agit-il ?",
  'FORM.REPAIR.SHADES.LOOSE': 'Rail de guidage desserré',
  'FORM.REPAIR.SHADES.METALBLINDS': 'Tout métal stores',
  'FORM.REPAIR.SHADES.METALBLINDS.KIND.TITLE': "De quel type de store à lamelles s'agit-il?",
  'FORM.REPAIR.SHADES.MINUS2YEARS': 'Moins de 2 ans',
  'FORM.REPAIR.SHADES.MINUS3M': 'Plus étroit que 3m',
  'FORM.REPAIR.SHADES.NOISE': 'Fait du bruit',
  'FORM.REPAIR.SHADES.NOSOUND': 'Non, pas de bruit de moteur',
  'FORM.REPAIR.SHADES.NOTPULLDOWNUP': 'Ne monte plus / ne descend plus',
  'FORM.REPAIR.SHADES.NOTSHUTOFF': 'Ne peut plus être éteint',
  'FORM.REPAIR.SHADES.NOTTURN': 'Ne bascule plus',
  'FORM.REPAIR.SHADES.ONE': '1',
  'FORM.REPAIR.SHADES.PLUS2YEARS': 'Plus vieux que 2 ans',
  'FORM.REPAIR.SHADES.PLUS3M': 'Plus large que 3m',
  'FORM.REPAIR.SHADES.QUANTITY.TITLE': 'Combien de stores sont concernés ?',
  'FORM.REPAIR.SHADES.SEVERAL': 'Plusieurs',
  'FORM.REPAIR.SHADES.SHUTTER': 'Volet roulant',
  'FORM.REPAIR.SHADES.SHUTTER.BELT': 'Sangle de volet roulant',
  'FORM.REPAIR.SHADES.SHUTTER.COVER': 'Couvercle de volet roulant',
  'FORM.REPAIR.SHADES.SHUTTER.FALLSDOWN': 'Ne tient pas, tombe',
  'FORM.REPAIR.SHADES.SHUTTER.NOTFULLYUP': 'Ne remonte pas complètement',
  'FORM.REPAIR.SHADES.SHUTTER.REMAINSOPEN': 'Ne se ferme pas complètement',
  'FORM.REPAIR.SHADES.SHUTTER.STUCK': 'Coincé',
  'FORM.REPAIR.SHADES.SLATBLINDS': 'Lamelles stores',
  'FORM.REPAIR.SHADES.SLATBLINDS.CORDTORN': 'Cordon déchiré',
  'FORM.REPAIR.SHADES.SOUND.TITLE': "Lorsque vous actionnez l'interrupteur, entendez-vous un bruit ?",
  'FORM.REPAIR.SHADES.SQUEAKING': 'Oui, meulage ou grincement',
  'FORM.REPAIR.SHADES.TWO': '2',
  'FORM.REPAIR.SHADES.WIDTHSTOREN.TITLE': 'Quelle est la largeur du store ?',
  'FORM.REPAIR.SHADES.WINDOWSOPEN.TITLE': 'Est-ce que les fenêtres peuvent être ouvertes ?',
  'FORM.REPAIR.SHAFT.BACKWATER': "Les eaux usées s'échappent de l'évacuation",
  'FORM.REPAIR.SHAFT.CLOGGED': 'Bouché',
  'FORM.REPAIR.SHAFT.DIRTY': 'Sali',
  'FORM.REPAIR.SHAFT.LOOSE': 'Brinquebalant',
  'FORM.REPAIR.SHAFT.SMELLS': 'Pue',
  'FORM.REPAIR.SHOWROOM': 'Espace de vente',
  'FORM.REPAIR.SHOWWINDOW': 'Vitrine',
  'FORM.REPAIR.SHOWWINDOW.FRAME': 'Cadre de fenêtre',
  'FORM.REPAIR.SHOWWINDOW.FRAME.JOINTLEAK': 'Joint de verre non étanche, se détache',
  'FORM.REPAIR.SHOWWINDOW.FRAME.LEAK': 'Non étanche',
  'FORM.REPAIR.SHOWWINDOW.GLASS': 'Vitre',
  'FORM.REPAIR.SHOWWINDOW.RAILS': 'Rail de rideau',
  'FORM.REPAIR.SHOWWINDOW.RAILS.MISSING': 'Manquant',
  'FORM.REPAIR.SHOWWINDOW.RAILS.STUCK': 'Patin, le rideau ne coulisse pas',
  'FORM.REPAIR.SHOWWINDOW.RAILS.TORN': 'Arraché',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER': 'Volet roulant, grille à enroulement',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.BELT': 'Sangle de volet roulant',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.CRANK': 'Manivelle de volet roulant',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.CRANKBRACKET': 'Support de manivelle de volet roulant',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.GRILLES': 'Grille à enroulement',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.LID': 'Volet roulant couvercle',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.ROLLER': 'Volet roulant',
  'FORM.REPAIR.SHOWWINDOW.SILL': 'Appui, rebord de fenêtre',
  'FORM.REPAIR.SMARTCONTROL': 'Commande intelligente',
  'FORM.REPAIR.SMARTCONTROL.BLINDS': 'Commande de stores défectueuse',
  'FORM.REPAIR.SMARTCONTROL.DISPLAY': 'Écran défectueux (matériel)',
  'FORM.REPAIR.SMARTCONTROL.HEATINGCOOLING': 'Commande de chauffage / refroidissement défectueuse',
  'FORM.REPAIR.SMARTCONTROL.OTHER': 'Autre problème',
  'FORM.REPAIR.SMARTCONTROL.USERGUIDE': "Question sur l'utilisation",
  'FORM.REPAIR.SMARTCONTROL.VIDEO': "Système d'interphonie vidéo défectueux",
  'FORM.REPAIR.SNOWREMOVAL': 'Déneigement',
  'FORM.REPAIR.STAIRWELL': "Cage d'escalier",
  'FORM.REPAIR.STAIRS': 'Escalier',
  'FORM.REPAIR.STAIRS.STEPS': 'Escalier, marche',
  'FORM.REPAIR.STAIRS.STEPS.BROKEN': 'Cassé',
  'FORM.REPAIR.STAIRS.STEPS.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.STAIRS.STEPS.WORN': 'Surface usée',
  'FORM.REPAIR.STAIRS.HANDRAIL': 'Main courante',
  'FORM.REPAIR.STAIRS.HANDRAIL.COATINGLOOSE': 'Revêtement desserré',
  'FORM.REPAIR.STAIRS.HANDRAIL.LOOSE': 'Relâché, branlant',
  'FORM.REPAIR.STAIRS.HANDRAIL.STACKSDEFECT': 'Empilement défectueux',
  'FORM.REPAIR.STAIRS.HANDRAIL.TORN': 'Arraché',
  'FORM.REPAIR.STORAGESHELVES': 'Étagères',
  'FORM.REPAIR.STORAGE': 'Cellier/Réduit',
  'FORM.REPAIR.STORAGESPACE': 'Espace de rangement, pièce de service',
  'FORM.REPAIR.TECHNICALROOM': 'Salle technique',
  'FORM.REPAIR.TREATMENTROOM': 'Salle de traitement de',
  'FORM.REPAIR.VENTILATOR': "Purgeur d'air",
  'FORM.REPAIR.VENTILATOR.FILTERDEFECT': 'Filtre défectueux',
  'FORM.REPAIR.VENTILATOR.LOWPERFORMANCE': 'Ne tire plus correctement',
  'FORM.REPAIR.VENTILATOR.NOISE': 'Fait du bruit, siffle',
  'FORM.REPAIR.VENTILATOR.NOTSWITCHINGOFFAUTO': "Ne s'arrête pas automatiquement",
  'FORM.REPAIR.VENTILATOR.NOTSWITCHINGON': "Ne s'allume plus",
  'FORM.REPAIR.WALL': 'Mur',
  'FORM.REPAIR.WALL.COMESOFF': 'Colore',
  'FORM.REPAIR.WALL.PLASTER': 'Enduit, plâtre',
  'FORM.REPAIR.WALL.PLASTER.CRUMBLES': "S'effrite",
  'FORM.REPAIR.WALL.STAINS': 'A des taches',
  'FORM.REPAIR.WALL.WALLPAPER': 'Papier peint',
  'FORM.REPAIR.WALL.WALLPAPER.DISSOLVING': "S'écaille",
  'FORM.REPAIR.WALL.YELLOWSPOTS': 'Taches jaunes, mur jaune',
  'FORM.REPAIR.WASHBASIN': 'Lavabo',
  'FORM.REPAIR.WASHBASIN.BASIN': 'Lavabo',
  'FORM.REPAIR.WASHBASIN.BASIN.BACKWATER': "Les eaux usées s'échappent de l'évacuation",
  'FORM.REPAIR.WASHBASIN.BASIN.CRACK': 'Fissure',
  'FORM.REPAIR.WASHBASIN.BASIN.HOLE': 'trou',
  'FORM.REPAIR.WASHBASIN.BASIN.QUIRK': 'imperfection',
  'FORM.REPAIR.WASHBASIN.BASIN.SOAPDISHDEFECT': 'Porte-savon défectueux',
  'FORM.REPAIR.WASHBASIN.BASIN.SOAPDISHHOLDERDEFECT': 'Porte-savon défectueux',
  'FORM.REPAIR.WASHBASIN.BASIN.TOOTHBRUSHCUPDEFECT': 'Gobelet pour brosse à dents défectueux',
  'FORM.REPAIR.WASHBASIN.BASIN.TOOTHBRUSHCUPHOLDERDEFECT': 'Porte-brosse à dents gobelet défectueux',
  'FORM.REPAIR.WASHBASIN.CLOGGED': 'Bouché',
  'FORM.REPAIR.WASHBASIN.DRAIN': 'Écoulement, siphon',
  'FORM.REPAIR.WASHBASIN.DRAIN.DRAINSSLOW': "S'écoule lentement",
  'FORM.REPAIR.WASHBASIN.DRAIN.NOISE': 'Glouglou / bruit',
  'FORM.REPAIR.WASHBASIN.FITTINGS': 'Armatures',
  'FORM.REPAIR.WASHBASIN.FITTINGS.CLAMPS': 'Pince',
  'FORM.REPAIR.WASHBASIN.FITTINGS.DRIPS': 'Goutte',
  'FORM.REPAIR.WASHBASIN.FITTINGS.MOLDY': 'Moisi',
  'FORM.REPAIR.WASHBASIN.FITTINGS.SPLASHES': 'Éclaboussures',
  'FORM.REPAIR.WASHBASIN.FITTINGS.STAINS': 'A des taches, est terni',
  'FORM.REPAIR.WASHBASIN.LEAKY': 'Fuite',
  'FORM.REPAIR.WASHBASIN.LOOSE': 'Desserré, branlant',
  'FORM.REPAIR.WASHBASIN.NOTCONNECTED': 'Mal fixé, non connecté',
  'FORM.REPAIR.WASHBASIN.PIPES': "Tuyaux d'évacuation",
  'FORM.REPAIR.WASHBASIN.SMELLS': 'Pue',
  'FORM.REPAIR.WASHBASIN.SUPPLYLINES': "Conduites d'alimentation",
  'FORM.REPAIR.WASHBASIN.TOWELRAIL': 'Porte-serviettes',
  'FORM.REPAIR.WASHBASIN.TOWELRAIL.BROKEN': 'Cassé',
  'FORM.REPAIR.WASHBASIN.TOWELRAIL.TORN': 'Arraché',
  'FORM.REPAIR.WASHBASIN.TOWELRAILHEATER': 'Chauffe-serviettes de',
  'FORM.REPAIR.WASHBASIN.TOWELRAILHEATER.NOTWARM': 'Ne chauffe plus',
  'FORM.REPAIR.WASHBASIN.TOWELRAILHEATER.PAINTOFF': "La peinture s'écaille",
  'FORM.REPAIR.WASHERDRYER': 'Lave-linge, sèche-linge',
  'FORM.REPAIR.WASHERDRYER.DRYER': 'Séchoir à linge',
  'FORM.REPAIR.WASHERDRYER.DRYER.NOISE': 'Bruits inhabituels',
  'FORM.REPAIR.WASHERDRYER.DRYER.NOTDRIES': 'Ne sèche plus',
  'FORM.REPAIR.WASHERDRYER.HANGER': 'Penderie',
  'FORM.REPAIR.WASHERDRYER.HANGER.LINELOOSE': 'Corde à linge en vrac',
  'FORM.REPAIR.WASHERDRYER.HANGER.LINETORN': 'Corde à linge déchirée',
  'FORM.REPAIR.WASHERDRYER.HANGER.LOOSE': 'Attache vacillante, lâche',
  'FORM.REPAIR.WASHERDRYER.LEAKING': 'Fuite: eau',
  'FORM.REPAIR.WASHERDRYER.NOELECTRICITY': 'Pas de courant',
  'FORM.REPAIR.WASHERDRYER.NOTHEATUP': 'Ne chauffe pas',
  'FORM.REPAIR.WASHERDRYER.SMELLS': 'Pue',
  'FORM.REPAIR.WASHERDRYER.SWITCHDEFECT': 'Interrupteur défectueux',
  'FORM.REPAIR.WASHERDRYER.WASHER': 'Machine à laver',
  'FORM.REPAIR.WASHERDRYER.WASHER.CARDNOTWORKING': 'La carte de lavage ne fonctionne pas',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOTPUMPOUTWATER': "Ne pas pomper l'eau",
  'FORM.REPAIR.WASHERDRYER.WASHER.NOISE': 'Fait des bruits inhabituels',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOTFLINGING': "N'essore plus",
  'FORM.REPAIR.WASHERDRYER.WASHER.NOOPENS': "N'ouvre plus",
  'FORM.REPAIR.WASTE': 'Poubelle',
  'FORM.REPAIR.WASTE.CROWDED': 'Trop plein, pas vidé',
  'FORM.REPAIR.WASTE.DEFECT': 'Poubelle défectueuse',
  'FORM.REPAIR.WASTEDUMP': 'Emplacement de la poubelle',
  'FORM.REPAIR.WATERDAMAGE': 'Dégâts des eaux, moisissures, taches',
  'FORM.REPAIR.WATERDAMAGE.5CHFCOIN': 'Environ la taille de cinq francs pièces',
  'FORM.REPAIR.WATERDAMAGE.BULGES': 'Le sol se soulève',
  'FORM.REPAIR.WATERDAMAGE.CEILING': 'Plafond',
  'FORM.REPAIR.WATERDAMAGE.CEILINGPITCHEDROOF': 'Plafond toit en pente',
  'FORM.REPAIR.WATERDAMAGE.CEILINGWITHSKYLIGHT': "Plafond: Près d'une fenêtre de toit",
  'FORM.REPAIR.WATERDAMAGE.DOUBLEDORMORE': 'Doublé ou encore plus grand',
  'FORM.REPAIR.WATERDAMAGE.DROPPING': "De l'eau dégouline sur le mur ou le plafond",
  'FORM.REPAIR.WATERDAMAGE.DURATION': 'Depuis quand les dégâts sont-ils visibles ?',
  'FORM.REPAIR.WATERDAMAGE.FLOODING': "flaque d'eau ou inondation",
  'FORM.REPAIR.WATERDAMAGE.JOINTSMOLD': 'Joints du sol moisis',
  'FORM.REPAIR.WATERDAMAGE.LARGERNOTDOUBLED': 'Plus grand, mais pas doublé',
  'FORM.REPAIR.WATERDAMAGE.LOWERHALF': 'Mur: moitié inférieure',
  'FORM.REPAIR.WATERDAMAGE.MINUS24HOURS': 'Dans les dernières 24h',
  'FORM.REPAIR.WATERDAMAGE.MINUS5CHFCOIN': 'Inférieur à une pièce de cinq francs',
  'FORM.REPAIR.WATERDAMAGE.MINUS7DAYS': 'Dans les 7 derniers jours',
  'FORM.REPAIR.WATERDAMAGE.MOLD': 'Moisissures sur le mur ou le plafond',
  'FORM.REPAIR.WATERDAMAGE.NODROPPING': 'Non',
  'FORM.REPAIR.WATERDAMAGE.NOSIZECHANGE': 'Pas perceptible',
  'FORM.REPAIR.WATERDAMAGE.PLUS5CHFCOIN': "Nettement plus grand qu'une pièce de cinq francs",
  'FORM.REPAIR.WATERDAMAGE.PLUS7DAYS': 'Depuis plus longtemps',
  'FORM.REPAIR.WATERDAMAGE.SIZE': "Quelle est l'ampleur des dégâts ?",
  'FORM.REPAIR.WATERDAMAGE.SIZECHANGE': 'Comment la taille a-t-elle évolué en 24h ?',
  'FORM.REPAIR.WATERDAMAGE.UPPERHALF': 'Mur: moitié supérieure',
  'FORM.REPAIR.WATERDAMAGE.VISIBILITY': 'Des gouttes sont-elles visibles ?',
  'FORM.REPAIR.WATERDAMAGE.WALLCEILING': 'Mur et plafond',
  'FORM.REPAIR.WATERDAMAGE.WALLCEILINGFLOOR': 'Mur, plafond et sol',
  'FORM.REPAIR.WATERDAMAGE.WHERE': 'Où se situent les dégâts ?',
  'FORM.REPAIR.WATERDAMAGE.WINDOW': "Mur: Près d'une fenêtre",
  'FORM.REPAIR.WATERDAMAGE.WINDOWMOLD': 'Moisissures sur la fenêtre',
  'FORM.REPAIR.WATERDAMAGE.WINDOWSAFFECTED': 'Combien de fenêtres sont concernées ?',
  'FORM.REPAIR.WATERDAMAGE.YELLOWSTAINS': 'Taches jaunes sur le mur ou le plafond',
  'FORM.REPAIR.WATERDAMAGE.YESDROPPING': 'Oui',
  'FORM.REPAIR.WATERSTAINS': "Taches d'eau",
  'FORM.REPAIR.WATERWASTE': "Caniveau d'eaux usées (extérieur)",
  'FORM.REPAIR.WHOLE': 'Surface totale du bâtiment',
  'FORM.REPAIR.WINDOW': 'Fenêtre',
  'FORM.REPAIR.WINDOW.CASEMENT': 'Châssis ouvrant',
  'FORM.REPAIR.WINDOW.FRAME': 'Cadre de fenêtre',
  'FORM.REPAIR.WINDOW.FRAME.BROKEN': 'Brisé',
  'FORM.REPAIR.WINDOW.FRAME.MOLD': 'Moisi',
  'FORM.REPAIR.WINDOW.FRAME.ROTTEN': 'Pourriture / rouille',
  'FORM.REPAIR.WINDOW.GLASS': 'Fenêtre vitre',
  'FORM.REPAIR.WINDOW.GLASS.BLIND': 'Aveugle',
  'FORM.REPAIR.WINDOW.GLASS.BROKEN': 'Brisé',
  'FORM.REPAIR.WINDOW.GLASS.CONDENSATION': 'Condensation sur la vitre',
  'FORM.REPAIR.WINDOW.GLASS.CONDENSATIONBETWEEN': 'Eau de condensation entre les vitres',
  'FORM.REPAIR.WINDOW.GLASS.CATFLAPDEFECT': 'Chatière défectueuse',
  'FORM.REPAIR.WINDOW.GLASS.CATFLAPINSTALL': 'Installer une chatière',
  'FORM.REPAIR.WINDOW.GLASS.CATFLAPREMOVE': 'Démonter la chatière',
  'FORM.REPAIR.WINDOW.GLASS.CRACKED': 'Fissuré',
  'FORM.REPAIR.WINDOW.GLASS.SMASHED': 'Enfoncée',
  'FORM.REPAIR.WINDOW.GLASS.STONECHIP': 'Éclats de pierre',
  'FORM.REPAIR.WINDOW.HANDLE': 'Poignée de fenêtre',
  'FORM.REPAIR.WINDOW.HANDLE.COVERMISSING': 'Couverture de poignée défectueuse, manquante',
  'FORM.REPAIR.WINDOW.HANDLE.FALLEN': 'Chute',
  'FORM.REPAIR.WINDOW.HANDLE.HARDTURN': 'Tourne difficilement',
  'FORM.REPAIR.WINDOW.HANDLE.IDLE': 'Tourne à vide',
  'FORM.REPAIR.WINDOW.HANDLE.JAMS': 'Serre',
  'FORM.REPAIR.WINDOW.HANDLE.KEYBROKEN': 'Clé cassée',
  'FORM.REPAIR.WINDOW.HANDLE.KEYMISSING': 'Clé manquante',
  'FORM.REPAIR.WINDOW.HANDLE.LOOSE': 'Desserré, branlant',
  'FORM.REPAIR.WINDOW.HANDLE.STAINS': 'A des taches, est terni',
  'FORM.REPAIR.WINDOW.HANDLE.VERTICALTURN': 'Ne tourne pas verticalement',
  'FORM.REPAIR.WINDOW.JOINTMOLD': 'Joint de verre moisi',
  'FORM.REPAIR.WINDOW.LEAKING': 'Fuite',
  'FORM.REPAIR.WINDOW.LEAKING.CONDENSATION': 'Condensation',
  'FORM.REPAIR.WINDOW.LEAKING.DRAFTS': "Fuite de courant d'air",
  'FORM.REPAIR.WINDOW.LEAKING.HEAVYRAIN': 'Fuite en cas de fortes pluies',
  'FORM.REPAIR.WINDOW.LEAKING.SEALDEFECT': 'Joint en caoutchouc défectueux',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING': 'Ouvrir & fermer',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.AUTOMATICDEFECT': 'Commande automatique défectueuse',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.BROKEN': 'Forcé',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.GRINDS': 'Effleuré, en butée',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.HUNGOUT': 'Décroché',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.JAMS': 'Coincé',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.LIMITER': "Limiteur d'ouverture",
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.MECHANISMDEFECT': "Mécanique d'ouverture défectueuse",
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.ONLYTILTED': 'Inclinaison uniquement possible',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.REMAINSOPEN': 'Ne se ferme pas',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.SECURITY': 'Sécurité de la fenêtre',
  'FORM.REPAIR.WINDOW.SEAL': 'Joints',
  'FORM.REPAIR.WINDOW.SEAL.DEFECT': 'Joint en caoutchouc défectueux',
  'FORM.REPAIR.WINDOW.SEAL.JOINTLEAK': 'Joint de verre non étanche, se détache',
  'FORM.REPAIR.WINDOW.SEAL.MISSING': 'Joint en caoutchouc manquant',
  'FORM.REPAIR.WINDOW.SEAL.OVERLAPDEFECT': 'Joint de recouvrement défectueux',
  'FORM.REPAIR.WINDOW.SEAL.OVERLAPMOLD': 'Joint de recouvrement moisi',
  'FORM.REPAIR.WINDOW.SILL': 'Tablette de fenêtre, corniche',
  'FORM.REPAIR.WINDOW.SILL.BROKEN': 'Cassé, fissuré',
  'FORM.REPAIR.WINDOW.SILL.EDGEDEFECT': 'Bords défectueux',
  'FORM.REPAIR.WINDOW.SILL.LOOSE': 'Relâché, bancal',
  'FORM.REPAIR.WINDOW.SILL.RELEASING': 'Surfaces qui se décollent',
  'FORM.REPAIR.WINDOW.SILL.WATERSTAINS': "Taches d'eau",

  'FORM.KEYORDER.DETAILS.TITLE': 'Motif de la commande',
  'FORM.KEYORDER.TITLE': 'Quel est le motif de la commande ?',
  'FORM.KEYORDER.LOST': 'Clé perdue',
  'FORM.KEYORDER.KEY.TITLE': 'Clé de',
  'FORM.KEYORDER.APARTMENTDOOR': "Porte de l'appartement",
  'FORM.KEYORDER.BUILDINGENTRANCE': "Entrée de l'immeuble",
  'FORM.KEYORDER.BASEMENT': 'cave',
  'FORM.KEYORDER.MAILBOX': 'Boîte aux lettres',
  'FORM.KEYORDER.OTHER': 'Autre clé',
  'FORM.KEYORDER.ALL': "Pour tous: porte d'appartement, entrée d'immeuble, cave, boîte aux lettres",
  'FORM.KEYORDER.EXTRA': 'Clé supplémentaire',
  'FORM.KEYORDER.REPLACE': 'Remplacer le cylindre et la clé',
  'FORM.KEYORDER.WASHCARD': 'Nouvelle carte de lavage',
  'FORM.KEYORDER.SIPA': 'Nouvelle clé à gorges SIPA',
  'FORM.KEYORDER.SIPA.TITLE': 'Quelle est la couleur de la clé ?',
  'FORM.KEYORDER.SIPA.BLUE': 'Bleu',
  'FORM.KEYORDER.SIPA.GREEN': 'Vert',
  'FORM.KEYORDER.SIPA.RED': 'Rouge',
  'FORM.KEYORDER.SIPA.YELLOW': 'Jaune',
  'FORM.KEYORDER.PARKINGTRADITIONAL': 'Nouvelle carte de stationnement',
  'FORM.KEYORDER.PARKINGWIFI': 'Nouvelle carte de stationnement sans fil',

  'FORM.NAMEPLATE.TITLE': "Où avez-vous besoin d'une nouvelle plaque nominative ?",
  'FORM.NAMEPLATE.DETAILS.TITLE': "Où a-t-on besoin d'une plaque nominative",
  'FORM.NAMEPLATE.DOOROUTSIDE': 'Sonnerie',
  'FORM.NAMEPLATE.DOORINSIDE': "Porte de l'appartement",
  'FORM.NAMEPLATE.MAILBOX': 'Boîte aux lettres',
  'FORM.NAMEPLATE.ALL': "Pour tous: sonnerie, porte d'appartement & boîte aux lettres",

  'FORM.DOCUMENT.TITLE': 'De quel document avez-vous besoin ?',
  'FORM.DOCUMENT.DETAILS.TITLE': 'Document ou formulaire',
  'FORM.DOCUMENT.HANDOVER': 'Copie de la remise du logement',
  'FORM.DOCUMENT.RENTALCONTRACT': 'Copie du contrat de location',
  'FORM.DOCUMENT.UTILITYBILL': 'Copie du décompte des charges',
  'FORM.DOCUMENT.APPLICATIONPET': "Demande de garde d'animaux domestiques",
  'FORM.DOCUMENT.REMODELLING': "Demande d'aménagements côté locataire",
  'FORM.DOCUMENT.SUBLEASING': 'Demande de sous-location',
  'FORM.DOCUMENT.INSURANCE': "Attestation d'assurance du bâtiment",
  'FORM.DOCUMENT.OTHER': 'Autre document',

  'FORM.OTHER.TITLE': 'Quelle est votre demande ?',
  'FORM.OTHER.DETAILS.TITLE': 'Préoccupation',
  'FORM.OTHER.PROPERTY': "Généralités sur l'immeuble",
  'FORM.OTHER.NAMECHANGE': 'Changement de nom, mariage',
  'FORM.OTHER.EMAILCHANGE': "Changement d'adresse électronique",
  'FORM.OTHER.BANKACCOUNTCHANGE': 'changement de coordonnées bancaires',
  'FORM.OTHER.SUBLEASING': 'Sous-location',
  'FORM.OTHER.OTHER': 'Autre chose',
};
