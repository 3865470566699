import spaces from '../spaces.json';
import {
  CKeyOrderReport,
  CNameplateOrderReport,
  CRepairReport,
  CReport,
  CReservationReport,
  CVote,
  CVoteItemReport,
} from './classes';
import {
  ACCOUNT_ROLE,
  DB,
  FOLDER_PATTERN,
  IMAGE_TYPES_ALL,
  STORAGE,
  TAB_TITLE,
} from './constants';
import {
  IDocumentFolderPattern,
  IRepairTimeline,
  TReportAll,
  TReportDbCollection,
  TReportTicket,
  TSentry,
  TSpaceRaw,
} from './types';

export const trimAndRemoveDoubleSpaces = (input: string): string => {
  return input.trim().replace(/\s+/g, ' ');
};

export const getAllValidMailDomains = () => {
  return [
    // Clients (without duplicates and without https://www)
    ...[
      ...new Set(
        Object.values(spaces).map((space: TSpaceRaw) =>
          space.REACT_APP_CLIENT_WEBSITE_URL.replace(
            /http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/i,
            '',
          ),
        ),
      ),
    ],

    /* Default domains included */
    'aol.com',
    'att.net',
    'comcast.net',
    'facebook.com',
    'gmail.com',
    'gmx.com',
    'googlemail.com',
    'google.com',
    'hotmail.com',
    'hotmail.co.uk',
    'mac.com',
    'me.com',
    'mail.com',
    'msn.com',
    'live.com',
    'sbcglobal.net',
    'verizon.net',
    'yahoo.com',
    'yahoo.co.uk',

    /* Other global domains */
    'email.com',
    'fastmail.fm',
    'games.com' /* AOL */,
    'gmx.net',
    'hush.com',
    'hushmail.com',
    'icloud.com',
    'iname.com',
    'inbox.com',
    'lavabit.com',
    'love.com' /* AOL */,
    'outlook.com',
    'pobox.com',
    'protonmail.ch',
    'protonmail.com',
    'tutanota.de',
    'tutanota.com',
    'tutamail.com',
    'tuta.io',
    'keemail.me',
    'rocketmail.com' /* Yahoo */,
    'safe-mail.net',
    'wow.com' /* AOL */,
    'ygm.com' /* AOL */,
    'ymail.com' /* Yahoo */,
    'zoho.com',
    'yandex.com',

    /* United States ISP domains */
    'bellsouth.net',
    'charter.net',
    'cox.net',
    'earthlink.net',
    'juno.com',

    /* British ISP domains */
    'btinternet.com',
    'virginmedia.com',
    'blueyonder.co.uk',
    'freeserve.co.uk',
    'live.co.uk',
    'ntlworld.com',
    'o2.co.uk',
    'orange.net',
    'sky.com',
    'talktalk.co.uk',
    'tiscali.co.uk',
    'virgin.net',
    'wanadoo.co.uk',
    'bt.com',

    /* Domains used in Asia */
    'sina.com',
    'sina.cn',
    'qq.com',
    'naver.com',
    'hanmail.net',
    'daum.net',
    'nate.com',
    'yahoo.co.jp',
    'yahoo.co.kr',
    'yahoo.co.id',
    'yahoo.co.in',
    'yahoo.com.sg',
    'yahoo.com.ph',
    '163.com',
    'yeah.net',
    '126.com',
    '21cn.com',
    'aliyun.com',
    'foxmail.com',

    /* French ISP domains */
    'hotmail.fr',
    'live.fr',
    'laposte.net',
    'yahoo.fr',
    'wanadoo.fr',
    'orange.fr',
    'gmx.fr',
    'sfr.fr',
    'neuf.fr',
    'free.fr',

    /* German ISP domains */
    'gmx.de',
    'hotmail.de',
    'live.de',
    'online.de',
    't-online.de' /* T-Mobile */,
    'web.de',
    'yahoo.de',

    /* Swiss ISP domains */
    'gmx.ch',
    'bluewin.ch',
    'sunrise.ch',
    'ggaweb.ch',
    'greenmail.ch',
    'hispeed.ch',
    'green.ch',
    'datatrans.ch',
    'swissonline.ch',
    'quickline.ch',
    'yallo.ch',
    'mail.ch',
    'ticino.com',
    'citymail.ch',
    'eblcom.ch',
    'hostpoint.ch',
    'netplus.ch',
    'alpine.ch',

    /* Italian ISP domains */
    'libero.it',
    'virgilio.it',
    'hotmail.it',
    'aol.it',
    'tiscali.it',
    'alice.it',
    'live.it',
    'yahoo.it',
    'email.it',
    'tin.it',
    'poste.it',
    'teletu.it',

    /* Russian ISP domains */
    'mail.ru',
    'rambler.ru',
    'yandex.ru',
    'ya.ru',
    'list.ru',

    /* Belgian ISP domains */
    'hotmail.be',
    'live.be',
    'skynet.be',
    'voo.be',
    'tvcablenet.be',
    'telenet.be',

    /* Argentinian ISP domains */
    'hotmail.com.ar',
    'live.com.ar',
    'yahoo.com.ar',
    'fibertel.com.ar',
    'speedy.com.ar',
    'arnet.com.ar',

    /* Domains used in Mexico */
    'yahoo.com.mx',
    'live.com.mx',
    'hotmail.es',
    'hotmail.com.mx',
    'prodigy.net.mx',

    /* Domains used in Canada */
    'yahoo.ca',
    'hotmail.ca',
    'bell.net',
    'shaw.ca',
    'sympatico.ca',
    'rogers.com',

    /* Domains used in Brazil */
    'yahoo.com.br',
    'hotmail.com.br',
    'outlook.com.br',
    'uol.com.br',
    'bol.com.br',
    'terra.com.br',
    'ig.com.br',
    'itelefonica.com.br',
    'r7.com',
    'zipmail.com.br',
    'globo.com',
    'globomail.com',
    'oi.com.br',
  ];
};

export const returnEarly = (): boolean => {
  return Math.random() > 0.00000001;
};

export async function tryMultipleTimes<T>(
  fn: () => Promise<T>,
  tryAmount: number,
  waitTime: number,
): Promise<T | Error> {
  let error: any = null;
  for (let i = 0; i < tryAmount; i++) {
    if (i > 0 && waitTime > 0) {
      await sleep(waitTime);
    }
    try {
      const res = await fn();
      return res;
    } catch (err: unknown) {
      console.info(
        `tryMultipleTimes failed. Try ${
          i + 1
        } of ${tryAmount}. Fn: ${fn.toString()}.`,
      );
      error = err;
    }
  }
  // If this point is reached, the function failed.
  return new Error(error ?? '-');
}

export const voteNumberIdToString = (nrId: string): string => {
  switch (nrId) {
    case '-1':
      return 'Nein';
    case '0':
      return 'Enthaltung';
    case '1':
      return 'Ja';
    default:
      return 'Fehler';
  }
};

export const limitToOneLevel = (obj: any) => {
  const result: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      result[key] = '[Nested Object]';
    } else {
      result[key] = obj[key];
    }
  });

  return result;
};

export const limitToTwoLevels = (obj: any) => {
  const result: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null) {
      // If it's an object or array, process its properties to one additional level
      result[key] = {};

      Object.keys(value).forEach((subKey) => {
        const subValue = value[subKey];
        if (typeof subValue === 'object' && subValue !== null) {
          result[key][subKey] = '[Nested Object]';
        } else {
          result[key][subKey] = subValue;
        }
      });
    } else {
      // If it's not an object, add it as-is
      result[key] = value;
    }
  });

  return result;
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) {
    return true;
  }

  if (!isObject(obj1) || !isObject(obj2)) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    if (!deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const isObject = (value: any): boolean => {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

export const getObjectDifferences = (
  oldObj: any,
  newObj: any,
): Record<string, any[]> => {
  const differences: Record<string, any[]> = {};
  for (const key of new Set([...Object.keys(oldObj), ...Object.keys(newObj)])) {
    const fieldOld = oldObj[key];
    const fieldNew = newObj[key];
    if (
      // DeepEqual checks for object differences (ignoring order of keys)
      !deepEqual(fieldOld, fieldNew) &&
      // Stringify takes care about all other values
      JSON.stringify(fieldOld) !== JSON.stringify(fieldNew)
    ) {
      differences[key] = [fieldOld, fieldNew];
    }
  }
  return differences;
};

export const getMandateCodeOrFirstNumberInString = (str: string): number => {
  const mandateCode = str.match(/#IA[0-9]+#/);
  if (mandateCode != null) {
    return getFirstNumberInString(mandateCode[0]);
  }
  return getFirstNumberInString(str);
};

/**
 * Remove the folder pattern like`#IA-MANDATE-<id>#` from a string.
 *
 * @param {string} identifier any string. Could contain the pattern.
 *
 */
export const removeDocumentFolderPattern = (identifier: string): string => {
  // Define the regular expression pattern to match the type delimiter
  const patternTotal = /#IA-?(MANDATE|PROPERTY|OBJECT|PERSON)?-?[0-9]+#(.*)/;

  // Check if identifier exists
  const matchTotal: RegExpMatchArray | null = identifier.match(patternTotal);
  if (matchTotal == null) {
    return identifier;
  }
  return matchTotal.pop()?.trim() ?? identifier;
};

/**
 * Extracts type ("MANDATE", "PROPERTY" or "OBJECT") and id from an identifier
 * with the form of `#IA-MANDATE-<id>#`, `#IA-PROPERTY-<id>#` or `#IA-OBJECT-<id>#`.
 *
 * @param {string} identifier form of `#IA-XXX-<id>#`
 *
 * @example
 * ```
 * // Returns {type: "MANDATE", id: 1000}
 * extractId("#IA-MANDATE-1000#");
 * ```
 */
export const extractDocumentFolderPattern = (
  identifier: string,
): IDocumentFolderPattern | null => {
  // Define the regular expression pattern to match the type delimiter
  const patternTotal = /#IA-?(MANDATE|PROPERTY|OBJECT|PERSON)?-?[0-9]+#/g;
  const patternType = /(MANDATE|PROPERTY|OBJECT|PERSON)/g;
  const patternId = /([0-9]+)/g;

  // Check if identifier exists
  const matchTotal: RegExpMatchArray | null = identifier.match(patternTotal);
  if (matchTotal == null) {
    return null;
  }

  // Find all matches
  const matchType: RegExpMatchArray = matchTotal[0].match(patternType) ?? [
    FOLDER_PATTERN.MANDATE,
  ];
  const matchId: RegExpMatchArray | null = matchTotal[0].match(patternId);

  // Note: this should never happen
  if (matchType.length > 1) {
    throw Error('Did find more than one type match for "' + identifier + '"');
  }

  // Note: this should never happen
  if (matchId == null) {
    throw Error('Did not find any id match for "' + identifier + '"');
  } else if (matchId.length > 1) {
    throw Error('Did find more than one id match for "' + identifier + '"');
  }

  return {
    type: matchType[0],
    id: Number(matchId[0]),
  };
};

export function extractAndVerifyArray<T extends object>(
  newObjectCreator: new () => T,
  input: unknown,
  reporting: TSentry,
): T[] {
  if (Array.isArray(input)) {
    return input.map((object) =>
      extractAndVerifyObjectData(
        new newObjectCreator(),
        object,
        reporting,
        true,
      ),
    );
  }

  if (typeof input === 'string') {
    throw new Error(
      `Invalid input, of which the first 20 characters are: "${input.substring(
        0,
        20,
      )}"`,
    );
  }

  throw new Error('Invalid input');
}

export function extractAndVerifyObjectData<T extends object, K extends object>(
  newObject: T,
  testObject: K | undefined,
  reporting: TSentry,
  throwIfMissing: boolean = false,
): T {
  // This will just notify us if something is not right
  const objectKeyTypeDifferences = getObjectKeyTypeDifferences(
    newObject,
    testObject,
  );
  if (objectKeyTypeDifferences.length > 0) {
    const msg1 = `Missing data or wrong types from input object with ID ${
      // @ts-ignore
      testObject?.id ?? '[NA]'
    } to comply with mandatory fields of ${newObject.constructor.name}:`;
    if (throwIfMissing) {
      throw new Error(`${msg1} ${objectKeyTypeDifferences}`);
    }
    reporting.Warning(msg1, objectKeyTypeDifferences);
  }

  // Assign and return
  Object.assign(newObject, testObject);

  return newObject;
}

export function verifyObjectData<
  T extends object,
  K extends object | undefined,
>(newObject: T, testObject: K | undefined): boolean {
  return getObjectKeyTypeDifferences(newObject, testObject).length === 0;
}

/**
 * Allows the code to sleep for a specific amount of time.
 *
 * @param {Number} ms milliseconds to sleep
 * @return {Promise} unknown
 */
export const sleep = (ms: number): Promise<unknown> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getThumbPath = (filePath: string): string => {
  const pathSplit = filePath.split('/');
  const fileNameComplete = pathSplit.pop();
  if (fileNameComplete == null) {
    return '';
  }
  return `${pathSplit[0]}_thumb/thumb_${fileNameComplete}`;
};

export const genRandomString = (length: number): string => {
  let result = '';
  while (result.length < length) {
    const newString = Math.random()
      .toString(36)
      .replace(/[.il01]/g, '');
    result += newString;
  }
  return result.slice(-length);
};

export const hasEmptyOrInvalidRelationshipIds = (
  voteData: CVote,
  voteUserRelIds?: number[],
): boolean => {
  return (
    voteUserRelIds == null ||
    voteUserRelIds.length === 0 ||
    !voteUserRelIds.some((relId) =>
      voteData.eligible_participants.find(
        (eliPart) => relId === eliPart.rel_id,
      ),
    )
  );
};

export const reportTypeToDbCollection = (
  reportType: TReportAll,
): TReportDbCollection => {
  switch (reportType) {
    case 'repair':
    case 'reservation':
    case 'keyorder':
    case 'nameplate':
    case 'document':
    case 'voteitem':
    case 'other':
      return DB.ia_repairs;
    case 'message':
      return DB.ia_messages;
    case 'pinboard':
      return DB.ia_pinboard;
    case 'votes':
      return DB.ia_votes;
  }
};

export const getMandateDataUserOverlap = (
  mandateData: Record<string, string>,
  userMandateActiveIds: number[],
): Record<string, string> => {
  return Object.entries(mandateData)
    .filter(([key]) => userMandateActiveIds.includes(Number(key)))
    .reduce(
      (res: Record<string, string>, [newKey, newValue]) => (
        (res[newKey] = newValue), res
      ),
      {},
    );
};

export const getTimelineItemStatusName = (
  item: IRepairTimeline,
  isReservation: boolean,
): string => {
  return isReservation
    ? `RESERVATION.STATUS_${item.repair_status}`
    : `REPAIR.STATUS_${item.repair_status}`;
};

export const dbCollectionToReportType = (
  dbCollection: TReportDbCollection,
  ticketType?: TReportTicket,
): TReportAll => {
  switch (dbCollection) {
    case DB.ia_messages:
      return 'message';
    case DB.ia_repairs:
      return ticketType ?? 'repair';
    case DB.ia_pinboard:
      return 'pinboard';
    case DB.ia_votes:
      return 'votes';
  }
};

export const getReportAsRepairReport = (
  report?: Partial<CReport>,
): CRepairReport | null => {
  return report?.dbCollection === DB.ia_repairs
    ? (report as CRepairReport)
    : null;
};

export const getReportAsKeyOrderReport = (
  report?: Partial<CReport>,
): CKeyOrderReport | null => {
  return report?.dbCollection === DB.ia_repairs &&
    (report as CRepairReport).ticket_type === 'keyorder'
    ? (report as CKeyOrderReport)
    : null;
};

export const getReportAsNameplateReport = (
  report?: Partial<CReport>,
): CNameplateOrderReport | null => {
  return report?.dbCollection === DB.ia_repairs &&
    (report as CRepairReport).ticket_type === 'nameplate'
    ? (report as CNameplateOrderReport)
    : null;
};

export const getReportAsReservationReport = (
  report?: Partial<CReport>,
): CReservationReport | null => {
  return report?.dbCollection === DB.ia_repairs &&
    (report as CRepairReport).ticket_type === 'reservation'
    ? (report as CReservationReport)
    : null;
};

export const getReportAsVoteItemReport = (
  report?: Partial<CReport>,
): CVoteItemReport | null => {
  return report?.dbCollection === DB.ia_repairs &&
    (report as CRepairReport).ticket_type === 'voteitem'
    ? (report as CVoteItemReport)
    : null;
};

/**
 * Typesafe filter an array for null and undefined elements.
 *
 * @return {Promise} boolean
 */
export const notEmpty = <TValue>(
  value: TValue | null | undefined,
): value is TValue => {
  if (value === null || value === undefined) {
    return false;
  }
  return true;
};

/**
 * Sort the same way as Firestore with numbers first, then uppercase, then lowercase.
 *
 * @return number
 */
export const firestoreSorting = (a: string, b: string): number => {
  const startsWithUppercaseOrNumber = (str: string): boolean => {
    return str.substring(0, 1).match(/[0-9A-Z]/) != null;
  };
  if (startsWithUppercaseOrNumber(a) && !startsWithUppercaseOrNumber(b)) {
    return -1;
  } else if (
    startsWithUppercaseOrNumber(b) &&
    !startsWithUppercaseOrNumber(a)
  ) {
    return 1;
  }
  return a.localeCompare(b);
};

export const getShortDocumentFilePath = (filePathOriginal: string): string => {
  const filePathForwardSlashes = filePathOriginal.replace(
    `${STORAGE.document_files}/`,
    '',
  );
  return filePathForwardSlashes.replace(/\//g, '\\');
};

export const chunkArray = (arr: any[], size: number): any[][] => {
  const result: any[] = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }
  return result;
};

/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {IWidth} { width, height }
 */
export const calculateAspectRatioFit = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number,
): {
  width: number;
  height: number;
} => {
  let ratio = 1;
  // Only if any side is larger than max size
  if (srcWidth > maxWidth || srcHeight > maxHeight) {
    ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  }
  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

/**
 * If the report contains user_roles, check if the user is part of that array.
 *
 * @param {number} userRole the userRole to check.
 * @param {number[]} userRoles the userRoles to check
 * @return {boolean}
 */
export const userRoleCanSeeReport = (
  userRole?: number,
  userRoles?: number[],
): boolean => {
  // Filter by user role
  // Note: we do this in the frontend to avoid even more fetching complexity and because most entries are probably sent to all (so the filter will return most entries).
  if (
    userRole == null ||
    (userRole < ACCOUNT_ROLE.admin.value &&
      userRoles != null &&
      userRoles.includes(userRole) === false)
  ) {
    // The report has set user roles AND the current user doesn't contain that role.
    return false;
  }
  return true;
};

/**
 * Partially hide the email address.
 *
 * @param {string} email
 * @return {string}
 */
export const partiallyHideEmail = (email: string): string => {
  const [name, domain] = email.split('@');

  let finalName = '';
  let starsUsed = 0;
  for (let i = 0; i < 3; i++) {
    const exists = name[i] != null;
    if (!exists) {
      starsUsed += 1;
    }
    finalName += name[i] ?? '*';
  }
  for (let i = 0; i < 3 - starsUsed; i++) {
    finalName += '*';
  }
  return `${finalName}@${domain}`;
};

/**
 * Make sure the external URL always has a protocol in front.
 *
 * @param {string} url URL
 */
export const urlProtocolCheck = (url: string): string => {
  if (!url.match(/^https?:\/\//i)) {
    url = 'http://' + url;
  }
  return url;
};

/**
 * Check if two objects are completely equal.
 *
 * @param {any} o1 Object 1
 * @param {any} o2 Object 2
 * @return boolean
 */
export const objectsEqual = (o1: any, o2: any): boolean => {
  return (
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p])
  );
};

/**
 * Transform a time string to hour and minute number values.
 *
 * @param {string} timeString Time String
 */
export const timeToHourAndMinute = (
  timeString: string,
): {
  hour: number;
  minute: number;
} => {
  const timeSplit = timeString.split(':');
  return {
    hour: Number(timeSplit[0]),
    minute: Number(timeSplit[1]),
  };
};

/**
 * Get the time difference of 2 dateStrings in minutes.
 *
 * @param {string} timeStart Time String with format HH:mm
 * @param {string} timeEnd Time String with format HH:mm
 */
export const getTimeDifferenceInMinutes = (
  timeStart: string,
  timeEnd: string,
): number => {
  // Parse times into Date objects
  const [startHour, startMinute] = timeStart.split(':').map(Number);
  const [endHour, endMinute] = timeEnd.split(':').map(Number);

  // Create Date objects
  const startTime = new Date();
  startTime.setHours(startHour, startMinute, 0);

  const endTime = new Date();
  endTime.setHours(endHour, endMinute, 0);

  // Calculate the difference in milliseconds and convert to minutes
  const diffInMs = endTime.getTime() - startTime.getTime();
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

  return diffInMinutes;
};

/**
 * See if an element is within the viewport.
 *
 * @param {any} el Native element
 */
export const isInViewport = (el: HTMLElement): boolean => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    Math.floor(rect.bottom) <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    Math.floor(rect.right) <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isImage = (fileName: string): boolean => {
  const type = fileName.split('.').pop()?.toLowerCase() ?? '';
  return IMAGE_TYPES_ALL.includes(type);
};

/**
 * Check if two arrays of objects are equal.
 *
 * @param {any[]} a1 Array 1
 * @param {any[]} a2 Array 2
 * @param {string[]} k Keys to compare
 * @return boolean
 */
export const arraysOfObjectsEqual = (
  a1: any[] | null | undefined,
  a2: any[] | null | undefined,
  k: string[],
): boolean => {
  if (a1 == null || a2 == null) {
    return false;
  }

  const finalA1 = a1.map((ele) => {
    const o: any = {};
    for (const key of k) {
      o[key] = ele[key];
    }
    return o;
  });
  const finalA2 = a2.map((ele) => {
    const o: any = {};
    for (const key of k) {
      o[key] = ele[key];
    }
    return o;
  });

  return JSON.stringify(finalA1) === JSON.stringify(finalA2);
};

export const compressImage = async (
  base64OrBlob: string | Blob,
  maxSize: number,
): Promise<string> => {
  return new Promise(async (res, rej) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
    const img = new Image();
    const MAX_WIDTH = maxSize;
    const MAX_HEIGHT = maxSize;

    // Transform Blob to string
    if (base64OrBlob instanceof Blob) {
      base64OrBlob = await blobToBase64(base64OrBlob);
    }

    img.onload = (): void => {
      // Determine new ratio based on max size
      const newSize = calculateAspectRatioFit(
        img.width,
        img.height,
        MAX_WIDTH,
        MAX_HEIGHT,
      );
      canvas.width = newSize.width;
      canvas.height = newSize.height;
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, newSize.width, newSize.height);
      ctx.drawImage(img, 0, 0, newSize.width, newSize.height);
      const data = ctx.canvas.toDataURL('image/jpeg', 0.8);
      res(data);
    };
    img.onerror = (error): void => rej(error);
    img.src = base64OrBlob;
  });
};

export const base64ToBlob = async (base64Data: string): Promise<Blob> => {
  return (await fetch(base64Data)).blob();
};

export const urlToBlob = async (url: string): Promise<Blob> => {
  return (await fetch(url)).blob();
};

export const urlToBase64 = async (url: string): Promise<string> => {
  const blob = await urlToBlob(url);
  return await blobToBase64(blob);
};

export const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (): void => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });
};

export const base64Start = (type: string): string => {
  return `data:${type};base64,`;
};

export const arrayBufferToBase64 = (bytes: ArrayBuffer): string => {
  return Buffer.from(bytes).toString('base64');
};

export const bufferToBase64 = (buffer: Buffer): string => {
  return buffer.toString('base64');
};

export const formGuidePathSegmentsToUrlParts = (
  pathSegments: string[],
  untilSegment: string,
): string[] => {
  const res: string[] = [];
  for (const pathSegment of pathSegments) {
    if (pathSegment === untilSegment) {
      break;
    }
    res.push(pathSegment);
  }
  return res;
};

export const getPushLink = (
  tabName: TAB_TITLE | null,
  reportCategory: TReportAll | null,
  entryId: string | null,
  commentId: string | null,
): string => {
  switch (tabName) {
    case TAB_TITLE.home:
      if (reportCategory && entryId) {
        return `/app/dashboard/report/${reportCategory}-${entryId}${
          commentId ? `#${commentId}` : ``
        }`;
      }
      return `/app/dashboard`;
    case TAB_TITLE.documents:
      return `/app/documents`;
    case TAB_TITLE.voting:
      if (entryId) {
        return `/app/votes/${entryId}`;
      }
      return `/app/votes`;
    case TAB_TITLE.profile:
      return `/app/profile`;
    default:
      return `/app/dashboard`;
  }
};

/* ------------------------------------ */
/* ----------- LOCAL HELPER  ---------- */
/* ------------------------------------ */

const getFirstNumberInString = (str: string): number => {
  return parseInt((str.match(/\d+/) ?? '-1').toString());
};

// export const replaceAll = (
//   input: string,
//   replaceThis: string,
//   withThis: string,
// ): string => {
//   return input.replace(new RegExp(replaceThis, 'g'), withThis);
// };

function getObjectKeyTypeDifferences<
  T extends object,
  K extends object | undefined,
>(newObject: T, testObject: K | undefined): string[] {
  return Object.keys(newObject).filter(
    (key) =>
      testObject?.hasOwnProperty(key) === false ||
      // @ts-ignore
      typeof newObject[key] !== typeof testObject?.[key],
  );
}
